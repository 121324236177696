import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { PiColumnsPlusRight, PiTrendUp } from "react-icons/pi";
import { GrHide } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import DatePicker from "react-datepicker";
import "../../styles/projects/Projects.css";
import { MultiSelect } from "react-multi-select-component";
import { useAuth } from "../Authentication/AuthContext";
import { useRolePermissions } from "../Settings/RoleContext";
import { FaChevronDown } from "react-icons/fa";
import api from "../../api/axiosConfig";
import { ProjectContext } from "../../App";
import { Helmet } from "react-helmet";
import Papa from "papaparse";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { colors } from "@mui/material";
import { TfiFilter } from "react-icons/tfi";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { saveAs } from "file-saver";
import { motion } from "framer-motion";

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  console.log("projects", projects);
  const projectRef = useRef(null);
  const { isNavBarOpen } = useContext(ProjectContext);
  const { userDetails } = useAuth();
  const { hasPermission } = useRolePermissions();
  const userID = userDetails.id;
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    projectno: "",
    start_date: "",
    end_date: "",
    actual_hours: "",
    budget_hours: "",
    customer_id: "",
    manager_id: "",
    description: "",
    team_id: "",
    tags: "",
    priority: "",
  });
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [managers, setManagers] = useState([]);
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  useEffect(() => {
    if (userDetails) {
      setSelectedMembers((prev) => {
        // Check if the user is already in the list
        const isAlreadyAdded = prev.some(
          (member) => member.value === userDetails.id
        );
        if (isAlreadyAdded) {
          return prev; // Return the current state without changes
        }
        // Add the new user
        return [...prev, { value: userDetails.id, label: userDetails.name }];
      });
    }
  }, [userDetails]); // Only run when userDetails changes

  useEffect(() => {
    if (newProject.manager_id) {
      const newUser = users.find((u) => u.id === Number(newProject.manager_id));
      if (newUser) {
        setSelectedMembers((prev) => {
          // Check if the user is already in the list
          const isAlreadyAdded = prev.some(
            (member) => member.value === newUser.id
          );
          if (isAlreadyAdded) {
            return prev; // Return the current state without changes
          }
          // Add the new user
          return [...prev, { value: newUser.id, label: newUser.name }];
        });
      }
    }
  }, [newProject, users]); // Only run when newProject or users change

  // const [customersDropdowm, setCustomersDropdown] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [updatedProject, setUpdatedProject] = useState(null);
  const [projectGroup, setProjectGroup] = useState([]);
  const [projectToDelete, setProjecttoDelete] = useState([]);
  const [filter, setFilter] = useState("");
  const tableHeading = [
    "Project No",
    "Project Name",
    "Customer Name",
    "Team",
    "Scheduled",
    "Issued On",
    "Remarks",
    "Phases",
    "VO(UC)",
    "VO BHrs(UC)",
    "VO BHrs",
    "VO AHrs",
    "Budget Hrs",
    "Actual Hrs",
    "Difference",
    "Des BHrs",
    "Des AHrs",
    "Det BHrs",
    "Det AHrs",
    "Tags",
    "Priority",
    "Manager",
    "Start Date",
    "Due Date",
    "Invoice %",
    "Status",
    // "Status",
    // "Description",
  ];
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [activeProjLink, setProjActiveLink] = useState(() => {
    return localStorage.getItem("activeproject") || "activeprojects";
  });
  const [csvFile, setCsvFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const handleProjLinkClick = (link) => {
    console.log("link", link);
    setProjActiveLink(link); // Update the active link
    localStorage.setItem("activeproject", link);
  };
  const sampleCSV = "/SampleProjectFile/projects.csv";
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(() => {
    return Number(localStorage.getItem("projectsPerPage")) || 25;
  });

  useEffect(() => {
    localStorage.setItem("projectsPerPage", projectsPerPage.toString());
  }, [projectsPerPage]);

  let paginationCount;
  switch (projectsPerPage) {
    case 25:
      paginationCount = "1-25";
      break;
    case 50:
      paginationCount = "1-50";
      break;
    case 75:
      paginationCount = "1-75";
      break;
    case 100:
      paginationCount = "1-100";
      break;
    default:
      paginationCount = "1-25";
      break;
  }

  const [visibleColumns, setVisibleColumns] = useState({
    //state for column visibility
    // delete: true,
    "Project No": true,
    "Project Name": true,
    "Customer Name": true,
    Team: true,
    Scheduled: true,
    "Issued On": true,
    Remarks: true,
    Phases: true,
    "VO(UC)": true,
    "VO BHrs(UC)": true,
    "VO BHrs": true,
    "VO AHrs": true,
    "Budget Hrs": true,
    "Actual Hrs": true,
    Difference: true,
    "Det BHrs": true,
    "Det AHrs": true,
    "Des BHrs": true,
    "Des AHrs": true,
    Tags: true,
    Priority: true,
    Manager: true,
    "Start Date": true,
    "Due Date": true,
    "Invoice %": true,
    Status: true,
    // Status: true,
    // Description: true,
  });
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    column: null,
  });
  const [columns, setColumns] = useState(tableHeading);
  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);
  // Function to save user preferences to the backend
  const saveUserPreferences = async (preferences) => {
    const userId = userID; // Replace with the actual logged-in user's ID

    try {
      const response = await api.post("/api/save-column-preferences", {
        userId,
        preferences,
      });
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };

  const fetchUserPreferences = async (userID) => {
    try {
      const response = await api.get(`/api/get-column-preferences/${userID}`);
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error("Error fetching preferences:", error);
      return null;
    }
  };

  const [isFormVisible, setIsFormVisible] = useState(false);
  const toggleFormVisibility = () => {
    setIsFormVisible((prevState) => !prevState);
  };
  //function for column visibility
  const toggleColumnVisibility = (columnKey) => {
    const updatedVisibleColumns = {
      ...visibleColumns,
      [columnKey]: !visibleColumns[columnKey],
    };

    // Update the state with the new visibility
    setVisibleColumns(updatedVisibleColumns);

    // Call the API to save the updated preferences
    saveUserPreferences(updatedVisibleColumns);
  };
  const handleRightClick = (event, column) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      column: column,
    });
  };

  const hideColumn = async () => {
    if (contextMenu.column) {
      const updatedColumns = {
        ...visibleColumns,
        [contextMenu.column]: false,
      };
      setVisibleColumns(updatedColumns);
      setContextMenu({ ...contextMenu, visible: false });
      saveUserPreferences(updatedColumns);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the context menu
      if (projectRef.current && !projectRef.current.contains(event.target)) {
        setContextMenu({ ...contextMenu, visible: false });
      }
    };

    // Attach event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenu]);

  const handleDragStart = (index) => {
    setDraggedColumnIndex(index);
  };

  const handleDrop = (dropIndex) => {
    if (draggedColumnIndex !== null) {
      // Clone columns array and reorder it
      const updatedColumns = [...columns];
      const [draggedColumn] = updatedColumns.splice(draggedColumnIndex, 1);
      updatedColumns.splice(dropIndex, 0, draggedColumn);
      console.log("Updated columns:", updatedColumns);

      // Update the columns in state
      setColumns(updatedColumns);

      // Update visibility order to match the new column order
      const newVisibleColumns = {};
      updatedColumns.forEach((col) => {
        newVisibleColumns[col] = visibleColumns[col];
      });
      setVisibleColumns(newVisibleColumns);
      console.log("New visibility order:", newVisibleColumns);

      // Save preferences to persist updated order and visibility
      saveUserPreferences(newVisibleColumns);

      // Reset draggedColumnIndex
      setDraggedColumnIndex(null);
    }
  };

  // const updateProjectsPerPage = () => {
  //   if (window.innerWidth < 1919) {
  //     setProjectsPerPage(25); // Small screens (e.g., laptop)
  //   } else if (window.innerWidth >= 1920) {
  //     setProjectsPerPage(25); // Medium screens (e.g., desktop)
  //   } else {
  //     setProjectsPerPage(25); // Large screens (e.g., tablet)
  //   }
  // };
  //saved preferences
  useEffect(() => {
    // Fetch the user preferences when the component mounts
    const loadPreferences = async () => {
      const preferences = await fetchUserPreferences(userID);
      if (preferences) {
        setVisibleColumns(preferences);
        setColumns(Object.keys(preferences));
      } else {
        // Set default preferences if no saved preferences found
        const defaultColumns = {
          // delete: true,
          "Project No": true,
          "Project Name": true,
          "Customer Name": true,
          Team: true,
          Scheduled: true,
          "Issued On": true,
          Remarks: true,
          Phases: true,
          "VO(UC)": true,
          "VO BHrs(UC)": true,
          "VO BHrs": true,
          "VO AHrs": true,
          "Budget Hrs": true,
          "Actual Hrs": true,
          Difference: true,
          "Det BHrs": true,
          "Det AHrs": true,
          "Des BHrs": true,
          "Des AHrs": true,
          Tags: true,
          Priority: true,
          Manager: true,
          "Start Date": true,
          "Due Date": true,
          "Invoice %": true,
          Status: true,
          // Status: true,
          // Description: true,
        };
        setVisibleColumns(defaultColumns);
        setColumns(Object.keys(defaultColumns));
      }
    };

    loadPreferences();
  }, [userID]);
  //saved preferences

  // useEffect(() => {
  //   // Set initial projects per page
  //   updateProjectsPerPage();

  //   // Update projects per page when the window is resized
  //   window.addEventListener("resize", updateProjectsPerPage);

  //   // Cleanup event listener on unmount
  //   return () => window.removeEventListener("resize", updateProjectsPerPage);
  // }, []);
  const [columnWidths, setColumnWidths] = useState(
    Array(12).fill(150) // assuming 9 columns in your table
  );

  const startX = useRef(null);
  const startWidth = useRef(null);
  const activeColumnIndex = useRef(null);

  const handleMouseDown = (index, e) => {
    startX.current = e.pageX;
    startWidth.current = columnWidths[index];
    activeColumnIndex.current = index;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (activeColumnIndex.current !== null) {
      let newWidth = startWidth.current + (e.pageX - startX.current);
      newWidth = Math.max(50, Math.min(newWidth, 500)); // Use reasonable limits
      setColumnWidths((prevWidths) => {
        const updatedWidths = [...prevWidths];
        updatedWidths[activeColumnIndex.current] = newWidth;
        return updatedWidths;
      });
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    activeColumnIndex.current = null;
  };

  //update project
  const handleEditClick = (project) => {
    setSelectedProject(project);
    console.log("selected project", project);
    setUpdatedProject({ ...project });
    setOpenEditDialog(true);
  };

  //close delete dial box
  const handleCloseDial = () => {
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
    document.body.classList.remove("no-scroll");
  };
  //add project event handling
  const onHandleAddProject = () => {
    setOpenDialog(true);
    document.body.classList.add("no-scroll");
  };
  //new project dial
  const closeDialog = () => {
    setOpenDialog(false);
    setNewProject({
      name: "",
      projectno: "",
      start_date: "",
      end_date: "",
      actual_hours: "",
      budget_hours: "",
      manager_id: "",
      description: "",
      customer_id: "",
      team_id: "",
      tags: "",
    });
    setSelectedMembers([]);
    document.body.classList.remove("no-scroll");
  };

  //add new project
  const handleAddNewProject = async () => {
    setLoading(true);
    const formatDateForMySQL = (date, daysToAdd = 0) => {
      if (!date) return null; // If no date is provided, return null

      // Convert to Date object if it's a valid date string
      let validDate = new Date(date);
      if (isNaN(validDate)) {
        return null; // Return null if invalid date
      }

      // Add the specified number of days (30 in this case)
      validDate.setDate(validDate.getDate() + daysToAdd);

      // Adjust for timezone and return as 'YYYY-MM-DD'
      const localDate = new Date(
        validDate.getTime() - validDate.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().split("T")[0]; // Convert to 'YYYY-MM-DD'
    };

    let formattedProject;
    if (newProject.start_date && newProject.end_date) {
      formattedProject = {
        ...newProject,
        start_date: formatDateForMySQL(newProject.start_date),
        end_date: formatDateForMySQL(newProject.end_date),
      };
    } else {
      formattedProject = {
        ...newProject,
        start_date: formatDateForMySQL(newProject.start_date),
        end_date: formatDateForMySQL(newProject.start_date, 30), // Add 30 days to the start_date
      };
    }
    console.log("formatted project", formattedProject);
    if (
      !formattedProject.projectno ||
      !formattedProject.name ||
      !formattedProject.start_date ||
      !formattedProject.customer_id ||
      !formattedProject.manager_id
    ) {
      setLoading(false);
      toast.error("Check the input fields", {
        position: "bottom-center",
        autoClose: 2000,
      });
    } else {
      try {
        const response = await api.post(`/api/projects`, formattedProject);
        if (response.status === 201) {
          const newProject = response.data; // Newly created project
          const currentProjectId = newProject.id; // Get project ID

          setProjects([...projects, newProject]);
          toast.success("Project added successfully!", {
            position: "bottom-center",
            autoClose: 2000,
          });
          window.location.reload();

          // After project is added, associate selected members with the new project
          if (selectedMembers.length > 0) {
            const userIds = selectedMembers.map((member) => member.value); // Extract 'value' instead of 'id'
            await handleAssociateUsersToProject(currentProjectId, userIds);
          }

          setNewProject({
            name: "",
            projectno: "",
            start_date: "",
            end_date: "",
            actual_hours: "",
            budget_hours: "",
            manager_id: "",
            description: "",
            customer_id: "",
            team_id: "",
            tags: "",
            priority: "",
          });
          closeDialog();
        }
      } catch (error) {
        console.error("Error adding project:", error);
        toast.error("Failed to add project", {
          position: "bottom-center",
          autoClose: 2000,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  //add users to an project
  const handleAssociateUsersToProject = async (currentProjectId, userIds) => {
    setLoading(true);
    if (userIds.length === 0 || userIds.includes(undefined)) {
      toast.error("Error: Invalid user IDs", userIds, {
        position: "bottom-center",
        autoClose: 2000,
      });
      toast.error("Error: Invalid user selection", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }
    try {
      const response = await api.post(
        `/api/projects/${currentProjectId}/users`,
        {
          userIds: userIds,
        }
      );
      const data = response.data;
      toast.error("Users associated with the project successfully.", {
        position: "bottom-center",
        autoClose: 2000,
      });

      setSelectedMembers([]);
    } catch (error) {
      console.error(
        "Error associating users with project:",
        error.response?.data || error.message
      );
      toast.error("Error associating users with project. Please try again.", {
        position: "bottom-center",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  //edit dial box
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target; // `name` will be "managerId", `value` will be the selected manager's id
    setUpdatedProject((prev) => ({
      ...prev,
      [name]: value, // This will dynamically update the corresponding field in the updatedProject state
    }));
  };

  const handleDateChange = (date, field) => {
    setUpdatedProject((prev) => ({
      ...prev,
      [field]: date.toISOString().split("T")[0],
    }));
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and profile
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) //&&
      // profileRef.current &&  !profileRef.current.contains(event.target)
    ) {
      setShowDropdown(false); // Close the dropdown
    }
  };

  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (newProject.customer_id) {
      const customer = customers.find(
        (c) => c.id === Number(newProject.customer_id)
      );
      const name = `${customer.customerno}-${customer.name}`;
      setSelectedCustomerName(name);
    }
  }, [newProject, customers]);
  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(e.target.value);
    setSelectedCustomerName("");

    const filtered = customers.filter((customer) => {
      const name = customer.name || ""; // Fallback to an empty string
      const no = customer.customerno || ""; // Fallback to an empty string
      const displayName = customer.displayName || ""; // Fallback to an empty string

      return (
        name.toLowerCase().includes(searchValue) ||
        no.toLowerCase().includes(searchValue) ||
        displayName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredCustomers(filtered);
    setShowDropdown(true); // Show the dropdown when the user types
  };

  const handleFocus = () => {
    setSearchTerm(""); // Reset the search term to show all projects
    setFilteredCustomers(customers); // Show all projects when focusing on the input
    setShowDropdown(true); // Show the dropdown
  };

  const handleUpdateProject = async () => {
    setLoading(true);
    const { start_date, end_date, manager_id, ...updateData } = updatedProject;

    // Validate and format start and end dates
    const validStartDate =
      start_date && !isNaN(Date.parse(start_date))
        ? new Date(start_date).toISOString().split("T")[0]
        : selectedProject.start_date;

    const validEndDate =
      end_date && !isNaN(Date.parse(end_date))
        ? new Date(end_date).toISOString().split("T")[0]
        : selectedProject.end_date;

    // Add the validated/formatted dates to updateData
    updateData.start_date = validStartDate;
    updateData.end_date = validEndDate;

    // Include manager_id in updateData for the API call
    updateData.manager_id = manager_id;

    // Log statements for debugging

    try {
      const response = await api.put(
        `/api/projects/${selectedProject.id}`,
        updateData
      );

      if (response.status === 200) {
        // Find updated manager's name if the manager_id has changed
        let updatedManagerName = selectedProject.manager_name; // Default to current name
        if (manager_id !== selectedProject.manager_id) {
          const updatedManager = managers.find(
            (manager) => manager.id === manager_id
          );
          updatedManagerName = updatedManager
            ? updatedManager.name
            : updatedManagerName; // Set new manager name if exists
        }
        toast.success("Project updated successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });
        setTimeout(() => {
          fetchInitialData();
        }, 1000);
        // Update the projects state with new values
        setProjects((prev) =>
          prev.map((proj) =>
            proj.id === selectedProject.id
              ? {
                  ...proj, // Retain other existing fields
                  ...updateData, // Spread updateData to retain updated fields
                  project_manager_name: updatedManagerName, // Update manager name
                }
              : proj
          )
        );
        // window.location.reload();
        handleCloseEditDialog();
      } else {
        toast.error("Failed to update project", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Failed to update project", {
        position: "bottom-center",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  //fetching admin users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/users`);
        const activeUsers = res.data.filter(
          (member) => member.status !== "Inactive"
        );
        setUsers(activeUsers);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //fetching all users and customers
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/api/users`);
        const cusres = await api.get(`/api/customers`);
        const groupres = await api.get(`/api/groups`);
        const formattedMembers = res.data
          .filter((member) => member.status !== "Inactive")
          .map((member) => ({
            value: member.id,
            label: member.name,
          }))
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          });
        setMembers(formattedMembers);
        setCustomers(cusres.data);
        setProjectGroup(groupres.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false); // Always stop loading after attempting to fetch
      }
    };

    fetchData();
  }, []);

  // fetching project details

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const endpoint =
        userDetails.role_name === "Administrator"
          ? `/api/projects1`
          : `/api/aprojects/${userID}`;

      const res = await api.get(endpoint);
      console.log("project data", res.data);
      const managerResult = await api.get("/api/users/admin");
      setManagers(managerResult.data);
      let projectFilterByStatus = res.data;
      if (activeProjLink === "activeprojects") {
        projectFilterByStatus = res.data.filter(
          (contact) => contact.activestatus === 1
        );
        setCurrentPage(1);
      } else {
        projectFilterByStatus = res.data.filter(
          (contact) => contact.activestatus === 0
        );
        setCurrentPage(1);
      }
      console.log("filter project", projectFilterByStatus);
      setProjects(projectFilterByStatus);
    } catch (error) {
      setError(error);
      console.error("Error fetching project data:", error.message || error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [userDetails.role, userID, activeProjLink]);

  //delete a project
  const handleDeleteConfirm = async () => {
    setLoading(true);
    const idsToDelete = projectToDelete.map((project) => project.id);

    setDeleteDialogOpen(false); // Close the delete confirmation dialog

    try {
      const response = await api.delete("/api/projects", {
        data: { ids: idsToDelete }, // Send the IDs in the request body
      });

      // Check for successful deletion
      if (response.status === 204) {
        const updatedProjects = projects.filter(
          (project) => !idsToDelete.includes(project.id)
        );
        setProjects(updatedProjects); // Update the projects state
        setLoading(false);
      } else {
        console.error("Failed to delete projects");
        toast.error("Failed to delete projects. Please try again.", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("There was an error deleting the projects:", error);
      toast.error(
        "An error occurred while deleting projects. Please try again.",
        {
          position: "bottom-center",
          autoClose: 2000,
        }
      );
    } finally {
      setProjecttoDelete([]); // Clear the projects to delete
      handleCloseDial(); // Close any dialog
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]); // Set the selected CSV file
  };

  const handleParse = () => {
    if (!csvFile) return;
    toast.error("Please upload a CSV file", {
      position: "bottom-center",
      autoClose: 2000,
    });
    // Use PapaParse to parse the CSV file
    Papa.parse(csvFile, {
      complete: function (result) {
        setCsvData(result.data); // Parsed CSV data
      },
      header: true, // Treat the first row as headers
    });
  };

  const handleUpload = async () => {
    if (csvData.length === 0) return;
    toast.error("No data to upload", {
      position: "bottom-center",
      autoClose: 2000,
    });

    try {
      // Send CSV data (array of objects) to the backend
      const response = await axios.post("/api/projects/bulk", csvData);
      if (response.status === 200) {
        toast.error("Projects uploaded successfully", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error uploading projects:", error);
      toast.error("Failed to upload projects", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }

  const mapKeyToProjectProperty = (heading) => {
    switch (
      heading.trim() // Use trim() to remove any accidental spaces
    ) {
      case "Project No":
        return "projectno";
      case "Project Name":
        return "name";
      case "Customer Name":
        return "customer_name";
      case "team":
        return "associated_groups";
      case "Budget Hours":
        return "total_planned_hours";
      case "Actual Hours":
        return "actual_hours";
      case "Tags":
        return "tags";
      case "Manager":
        return "project_manager_name";
      case "Start Date":
        return "start_date";
      case "Due Date":
        return "end_date";
      case "Invoice %":
        return "average_claimpercent";
      case "Phases":
        return "total_phases";
      // case "Status":
      //   return "status_name";
      // case "Description":
      //   return "description";
      case "VO(UC)":
        return "unclaimed_variation_count";
      case "Actual Hrs":
        return "overall_actual_hours";
      case "Des AHrs":
        return "design_actual_hours";
      case "Des BHrs":
        return "design_budget_hours";
      case "Det AHrs":
        return "detailing_actual_hours";
      case "Det BHrs":
        return "detailing_budget_hours";
      case "VO BHrs(UC)":
        return "unclaimed_variation_hours";
      case "VO AHrs":
        return "total_variation_actual_hours";
      case "VO BHrs":
        return "total_variation_hours";
      case "S.No":
        return "index";
      default:
        return "";
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const tagOrder = [
    "Yet to Start",
    "On Progress",
    "On Hold",
    "Completed",
    "Waiting",
    "Cancelled",
  ];

  const sortedProjects = [...projects].sort((a, b) => {
    const key = sortConfig.key || "tags"; // Default sorting by "tags"
    const direction = sortConfig.direction === "asc" ? 1 : -1;

    if (key === "tags") {
      // Get index from custom order array
      const aIndex = tagOrder.indexOf(a.tags);
      const bIndex = tagOrder.indexOf(b.tags);

      if (aIndex !== bIndex) {
        return (aIndex - bIndex) * direction; // Sort by tag order
      }

      return b.id - a.id; // If same tag, sort by id in descending order
    }

    // Handle other sorting cases
    let aValue = a[key];
    let bValue = b[key];

    switch (key) {
      case "start_date":
      case "end_date":
        aValue = new Date(aValue);
        bValue = new Date(bValue);
        break;

      case "project_manager_name":
      case "customer_name":
        aValue = a[key]?.toLowerCase() || "";
        bValue = b[key]?.toLowerCase() || "";
        break;

      default:
        if (typeof aValue === "string") aValue = aValue.toLowerCase();
        if (typeof bValue === "string") bValue = bValue.toLowerCase();
        break;
    }

    if (aValue < bValue) return -1 * direction;
    if (aValue > bValue) return 1 * direction;
    return 0;
  });

  const filteredProjects = sortedProjects.filter((project) => {
    return (
      project.name?.toLowerCase().includes(filter) ||
      project.projectno?.toLowerCase().includes(filter) ||
      project.customer_name?.toLowerCase().includes(filter) ||
      project.tags?.toLowerCase().includes(filter) ||
      project.project_manager_name?.toLowerCase().includes(filter)
    );
  });

  const handleSearch = (e) => {
    setFilter(e.target.value.toLowerCase());
  };
  // Calculate total number of pages
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  // Get current projects to display
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  const handleDeleteProject = (e, selectedProject) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setProjecttoDelete((prevSelected) => [...prevSelected, selectedProject]);
    } else {
      setProjecttoDelete((prevSelected) =>
        prevSelected.filter((project) => project.id !== selectedProject.id)
      );
    }
  };

  // Toggle visibility of all columns
  const toggleAllColumns = () => {
    setVisibleColumns((prevColumns) => {
      const updatedColumns = {};
      Object.keys(prevColumns).forEach((key) => {
        // Keep "Project No" and "Project Name" always visible
        if (key === "Project No" || key === "Project Name") {
          updatedColumns[key] = true;
        } else {
          updatedColumns[key] = !prevColumns[key]; // Toggle the current column visibility
        }
      });
      saveUserPreferences(updatedColumns);
      return updatedColumns;
    });
  };

  // Check if all columns are selected (visible)
  const isAllSelected = () => {
    return Object.values(visibleColumns).every((isVisible) => isVisible);
  };
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes; // Convert to total minutes
  };

  const formatTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60); // Calculate hours
    const minutes = totalMinutes % 60; // Calculate minutes

    // Format hours and minutes as 2-digit numbers
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };
  const handleProjectStatusChange = async (projectID, value, field) => {
    console.log("Updating:", { projectID, value, field });

    try {
      await api.put(`/api/project-status/${projectID}`, { value, field });

      toast.success("Project updated successfully!", {
        position: "bottom-center",
        autoClose: 1000,
      });

      setTimeout(() => {
        fetchInitialData(); // Refresh data after update
      }, 1000);
    } catch (error) {
      toast.error("Failed to update project", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const getColorValue = (value) => {
    let color;
    if (value === "Yet to Start" || value === "On Progress") {
      color = "#fafafa";
    } else if (value === "On Hold") {
      color = "#f1ebd4";
    } else if (value === "Completed") {
      color = "#e6fce6";
    } else if (value === "Cancelled") {
      color = "#fce6e6";
    }

    return color;
  };

  const handleProjectUpdate = async (field, value, projectId) => {
    console.log("id", field, value, projectId);

    const formattedValue =
      (field === "scheduledate" || field === "issued_on") && value
        ? format(value, "yyyy-MM-dd") // Format valid dates
        : value || null;
    console.log("formated", formattedValue);
    try {
      const response = await api.put(`/api/project-update/${projectId}`, {
        field,
        formattedValue,
      });
      if (value.trim() !== "") {
        toast.success("Project updated successfully!", {
          position: "bottom-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error("Failed to update project", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const exportToExcel = (currentProjects) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Projects");

    // Define the columns based on your row data
    const columns = [
      { header: "Project No", key: "projectNo", width: 20 },
      { header: "Project Name", key: "projectName", width: 30 },
      { header: "Customer Name", key: "customerName", width: 20 },
      { header: "VO(UC)", key: "unclaimed_variation_count", width: 15 },
      { header: "VO BHrs(UC)", key: "unclaimed_variation_hours", width: 15 },
      { header: "VO AHrs", key: "total_variation_actual_hours", width: 15 },
      { header: "Budget Hrs", key: "budgetHours", width: 15 },
      { header: "Actual Hrs", key: "actualHours", width: 15 },
      { header: "Difference", key: "Difference", width: 15 },
      { header: "Des BHrs", key: "designBudgetHours", width: 15 },
      { header: "Des AHrs", key: "designActualHours", width: 15 },
      { header: "Det BHrs", key: "detailingBudgetHours", width: 15 },
      { header: "Det AHrs", key: "detailingActualHours", width: 15 },
      { header: "Tags", key: "tags", width: 20 },
      { header: "Priority", key: "priority", width: 15 },
      { header: "Manager", key: "projectManager", width: 20 },
      { header: "Start Date", key: "startDate", width: 12 },
      { header: "Due Date", key: "dueDate", width: 12 },
      { header: "Invoice %", key: "invoiceStatus", width: 20 },
      { header: "Status", key: "projectStatus", width: 15 },
      { header: "Description", key: "description", width: 30 },
      { header: "Scheduled", key: "scheduledate", width: 12 },
      { header: "Issued On", key: "issuedOn", width: 12 },
      { header: "Remarks", key: "remarks", width: 30 },
    ];

    worksheet.columns = columns;

    // Add rows dynamically from the currentProjects data
    currentProjects.forEach((project) => {
      const row = {
        projectNo: project.projectno,
        projectName: project.name,
        customerName: project.customer_name,
        unclaimed_variation_count: project.unclaimed_variation_count,
        unclaimed_variation_hours: project.unclaimed_variation_hours,
        budgetHours:
          project.detailing_budget_hours + project.design_budget_hours,
        actualHours: project.overall_actual_hours,
        designBudgetHours: project.design_budget_hours,
        designActualHours: project.design_actual_hours,
        detailingBudgetHours: project.detailing_budget_hours,
        detailingActualHours: project.detailing_actual_hours,
        tags: project.tags,
        priority: project.priority,
        projectManager: project.project_manager_name,
        startDate: project.start_date
          ? format(new Date(project.start_date), "dd-MM-yyyy")
          : "",
        dueDate: project.end_date
          ? format(new Date(project.end_date), "dd-MM-yyyy")
          : "",
        invoiceStatus: `${project.average_claimpercent}% Claimed`,
        projectStatus: project.status_name,
        description: project.description,
        scheduledate: project.scheduledate
          ? format(new Date(project.scheduledate), "dd-MM-yyyy")
          : "",
        issuedOn: project.issued_on
          ? format(new Date(project.issued_on), "dd-MM-yyyy")
          : "",
        remarks: project.remarks,
      };

      worksheet.addRow(row);
    });

    // Apply styles for borders
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    // Save the file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "projects.xlsx";
      link.click();
    });
  };

  const convertToMinutes = (timeString) => {
    if (!timeString) return 0;
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const parseTimeToMinutes = (timeString) => {
    if (!timeString || timeString === "00:00") return 0;
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const renderRowData = (project) => {
    return columns.map((columnKey) => {
      if (visibleColumns[columnKey]) {
        switch (columnKey) {
          case "Project No":
            return (
              <td key="projectNo">
                <div className="project-table-con">
                  <Link
                    style={{ backgroundColor: "transparent", color: "black" }}
                    to={`/project/${project.id}/dashboard`}
                    className="select-projectlist"
                    onClick={() =>
                      localStorage.setItem("activePLink", "dashboard")
                    }
                  >
                    {project.projectno}
                  </Link>
                  {hasPermission("projects", "update") && (
                    <span
                      className="edit-icon-wrapper"
                      style={{
                        backgroundColor: "transparent",
                        color: "blue",
                      }}
                      title="Edit project"
                    >
                      <EditIcon
                        onClick={() => handleEditClick(project)}
                        style={{
                          backgroundColor: "transparent",
                          color: "blue",
                        }}
                      />
                    </span>
                  )}
                </div>
              </td>
            );
          case "Project Name":
            return <td key="projectName">{project.name}</td>;
          case "Customer Name":
            return (
              <td key="customerName" title={project.customer_name}>
                {project.customer_name.substring(0, 20)}
              </td>
            );
          case "Team":
            return <td key="team">{project.associated_groups}</td>;
          case "VO(UC)":
            return (
              <td
                key="unclaimed_variation_count"
                style={{ textAlign: "center" }}
              >
                {project.unclaimed_variation_count === 0
                  ? ""
                  : project.unclaimed_variation_count}
              </td>
            );
          case "VO BHrs(UC)":
            return (
              <td
                key="unclaimed_variation_hours"
                style={{ textAlign: "center" }}
              >
                {project.unclaimed_variation_hours === "00:00"
                  ? ""
                  : project.unclaimed_variation_hours}
              </td>
            );
          case "VO BHrs":
            return (
              <td key="total_variation_hours" style={{ textAlign: "center" }}>
                {project.total_variation_hours &&
                project.total_variation_hours !== "00:00"
                  ? project.total_variation_hours
                  : ""}
              </td>
            );
          case "VO AHrs":
            return (
              <td key="total_variation_hours" style={{ textAlign: "center" }}>
                {project.total_variation_actual_hours &&
                project.total_variation_actual_hours !== "00:00"
                  ? project.total_variation_actual_hours
                  : ""}
              </td>
            );

          case "Budget Hrs":
            return (
              <td key="overall_budget_hours" style={{ textAlign: "center" }}>
                {/* {formatTime(
                  timeToMinutes(project.detailing_budget_hours || "") +
                    timeToMinutes(project.design_budget_hours || "")
                ) === "00:00"
                  ? ""
                  : formatTime(
                      timeToMinutes(project.detailing_budget_hours || "") +
                        timeToMinutes(project.design_budget_hours || "")
                    )} */}
                {project.overall_budget_hours === "00:00"
                  ? ""
                  : project.overall_budget_hours}
              </td>
            );
          case "Actual Hrs":
            const budgetHours = parseTimeToMinutes(
              project.overall_budget_hours
            );
            const actualHours = parseTimeToMinutes(
              project.overall_actual_hours
            );

            let textColor = "black"; // Default color

            if (budgetHours > 0) {
              const percentage = (actualHours / budgetHours) * 100;
              if (percentage === 100) {
                textColor = "green";
              } else if (percentage > 99) {
                textColor = "red";
              } else if (percentage >= 50) {
                textColor = "#ffa500";
              }
            }
            return (
              <td
                key="actualHours"
                style={{
                  textAlign: "center",
                  color: textColor,
                }}
              >
                {project.overall_actual_hours === "00:00"
                  ? ""
                  : project.overall_actual_hours}
              </td>
            );
          case "Difference":
            return (
              <td
                key={"difference_hours"}
                style={{
                  textAlign: "center",
                  color:
                    convertToMinutes(project.overall_actual_hours) >
                    convertToMinutes(project.overall_budget_hours)
                      ? "red"
                      : "green",
                }}
              >
                {/* {formatTime(
                  timeToMinutes(project.overall_budget_hours) -
                    timeToMinutes(project.overall_actual_hours)
                ) === "00:00"
                  ? ""
                  : formatTime(
                      timeToMinutes(project.overall_budget_hours) -
                        timeToMinutes(project.overall_actual_hours)
                    )} */}
                {project.difference_hours !== "00:00"
                  ? project.difference_hours
                  : ""}
              </td>
            );
          case "Des BHrs":
            return (
              <td key="designBudgetHours" style={{ textAlign: "center" }}>
                {project.design_budget_hours === "00:00"
                  ? ""
                  : project.design_budget_hours}
              </td>
            );
          case "Des AHrs":
            return (
              <td key="designActualHours" style={{ textAlign: "center" }}>
                {project.design_actual_hours === "00:00"
                  ? ""
                  : project.design_actual_hours}
              </td>
            );
          case "Det BHrs":
            return (
              <td key="detailingBudgetHours" style={{ textAlign: "center" }}>
                {project.detailing_budget_hours === "00:00"
                  ? ""
                  : project.detailing_budget_hours}
              </td>
            );
          case "Det AHrs":
            return (
              <td key="detailingActualHours" style={{ textAlign: "center" }}>
                {project.detailing_actual_hours === "00:00"
                  ? ""
                  : project.detailing_actual_hours}
              </td>
            );
          case "Tags":
            return (
              <td key="tags" style={{ padding: "0px" }}>
                <select
                  className="project-tags-dropdown"
                  value={project.tags}
                  onChange={(e) =>
                    handleProjectStatusChange(
                      project.id,
                      e.target.value,
                      "tags"
                    )
                  }
                  disabled={!hasPermission("projects", "update")}
                >
                  <option value="" disabled>
                    Select Tags
                  </option>
                  <option value="Yet to Start">Yet to Start</option>
                  <option value="On Progress">On Progress</option>
                  <option value="On Hold">On Hold</option>
                  <option value="Completed">Completed</option>
                  <option value="Waiting">Waiting</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </td>
            );
          case "Priority":
            return (
              <td key="priority" style={{ padding: "0px" }}>
                <select
                  className="project-priority-dropdown"
                  value={project.priority}
                  onChange={(e) =>
                    handleProjectStatusChange(
                      project.id,
                      e.target.value,
                      "priority"
                    )
                  }
                  disabled={!hasPermission("projects", "update")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </td>
            );
          case "Manager":
            return <td key="projectManager">{project.project_manager_name}</td>;
          case "Phases":
            return (
              <td
                key="phases"
                style={{
                  textAlign: "center",
                  position: "relative",
                  cursor: "pointer",
                }}
                title={project.phase_statuses}
              >
                <Link
                  to={`/project/phases/${project.id}`}
                  className="select-projectlist"
                >
                  {project.total_phases !== 0 ? project.total_phases : ""}
                </Link>
              </td>
            );
          case "Start Date":
            return (
              <td key="startDate">
                {format(project.start_date, "dd-MM-yyyy")}
              </td>
            );
          case "Due Date":
            return (
              <td key="dueDate">{format(project.end_date, "dd-MM-yyyy")}</td>
            );
          case "Invoice %":
            const claimedPercentage = project.total_phases
              ? (project.total_invoice_claimed_phases / project.total_phases) *
                100
              : 0;
            return (
              <td key="invoiceStatus" style={{ textAlign: "center" }}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#e0e0df",
                    borderRadius: "8px",
                  }}
                ></div>
                <span
                  style={{
                    backgroundColor: "transparent",
                    textAlign: "center",
                  }}
                >
                  {project.average_claimpercent === 0
                    ? ""
                    : `${project.average_claimpercent} %`}
                </span>
              </td>
            );
          // case "Status":
          //   return <td key="Status">{project.status_name}</td>;
          case "Status":
            return (
              <td key="projectStatus" style={{ padding: "0px" }}>
                <select
                  value={project.activestatus}
                  onChange={(e) =>
                    handleProjectStatusChange(
                      project.id,
                      e.target.value,
                      "activestatus"
                    )
                  }
                  className="project-active-status"
                  disabled={!hasPermission("projects", "update")}
                >
                  <option disabled value={""}>
                    Select
                  </option>
                  <option value={"1"}>Active</option>
                  <option value={"0"}>Archived</option>
                </select>
              </td>
            );
          // case "Description":
          //   return <td key="description">{project.description}</td>;
          case "Scheduled":
            return (
              <td
                key="ScheduleDate"
                style={{ padding: "0px", maxWidth: "120px" }}
              >
                <DatePicker
                  className="scheduled-date"
                  dateFormat="dd-MM-yyyy"
                  selected={
                    project.scheduledate
                      ? typeof project.scheduledate === "string"
                        ? parseISO(project.scheduledate) // Convert only if it's a string
                        : project.scheduledate // Use directly if it's already a Date object
                      : null // Set to null if undefined
                  }
                  onChange={(date) => {
                    handleProjectUpdate("scheduledate", date, project.id);
                    setProjects((prevProjects) =>
                      prevProjects.map((p) =>
                        p.id === project.id ? { ...p, scheduledate: date } : p
                      )
                    );
                  }}
                  disabled={!hasPermission("projects", "update")}
                />
              </td>
            );
          case "Issued On":
            return (
              <td key="IssuedOn" style={{ padding: "0px", maxWidth: "120px" }}>
                <DatePicker
                  className="issue-date"
                  dateFormat="dd-MM-yyyy"
                  selected={
                    project.issued_on
                      ? typeof project.issued_on === "string"
                        ? parseISO(project.issued_on) // Parse only if it's a string
                        : project.issued_on // Use directly if it's already a Date object
                      : null // Return null instead of an empty string
                  }
                  onChange={(date) => {
                    handleProjectUpdate("issued_on", date, project.id);
                    setProjects((prevProjects) =>
                      prevProjects.map((p) =>
                        p.id === project.id ? { ...p, issued_on: date } : p
                      )
                    );
                  }}
                  disabled={!hasPermission("projects", "update")}
                />
              </td>
            );
          case "Remarks":
            return (
              <td key="Remarks" style={{ padding: "0px" }}>
                <input
                  disabled={!hasPermission("projects", "update")}
                  type="text"
                  title={project.remarks}
                  className="project-remarks"
                  maxLength={200}
                  value={project.remarks || ""}
                  onChange={(e) =>
                    setProjects((prevProjects) =>
                      prevProjects.map((p) =>
                        p.id === project.id
                          ? { ...p, remarks: e.target.value }
                          : p
                      )
                    )
                  }
                  onBlur={(e) => {
                    handleProjectUpdate("remarks", e.target.value, project.id);
                  }}
                />
              </td>
            );
          default:
            return null;
        }
      }
      return null;
    });
  };

  return (
    <div
      className={`${
        userDetails.role_name === "Administrator" ||
        userDetails.role_name === "Project_Manager"
          ? "project-admin-con"
          : "project-user-con"
      } ${isNavBarOpen ? "navbar-open" : "navbar-closed"}`}
    >
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <ToastContainer />
      <div
        className={
          isNavBarOpen
            ? "projectlist-nav-bar-details"
            : "projectlist-nav-bar-details-close"
        }
      >
        {/* <h3>Projects</h3> */}
        <div className="project-tab-details">
          <Link
            className={`proj-links ${
              activeProjLink === "activeprojects" ? "active" : ""
            }`}
            onClick={() => handleProjLinkClick("activeprojects")}
          >
            Active Projects
          </Link>
          {hasPermission("projects", "update") && (
            <Link
              className={`proj-links ${
                activeProjLink === "archivedprojects" ? "active" : ""
              }`}
              onClick={() => handleProjLinkClick("archivedprojects")}
            >
              Archived Projects
            </Link>
          )}
        </div>
        <hr className="proj-line" />
      </div>

      {hasPermission("projects", "create") && (
        <>
          <button
            onClick={() => exportToExcel(filteredProjects)}
            // style={{ left: isNavBarOpen ? "84.7%" : "84.7%" }}
            className={
              isNavBarOpen ? "add-exp-heading" : "add-exp-heading-close"
            }
          >
            Export to Excel
          </button>
          <button
            // style={{ left: isNavBarOpen ? "91.7%" : "96.85%" }}
            className={
              isNavBarOpen ? "add-proj-heading" : "add-proj-heading-close"
            }
            onClick={onHandleAddProject}
            title="New project"
          >
            New Project
          </button>
        </>
      )}
      {hasPermission("projects", "delete") && projectToDelete.length > 0 && (
        <button
          onClick={() => setDeleteDialogOpen(true)}
          className={
            isNavBarOpen ? "delete-proj-heading" : "delete-proj-heading-close"
          }
          style={{
            backgroundColor: "red",
            // left: isNavBarOpen ? "88%" : "87%",
          }}
          title="Delete project"
        >
          Delete
        </button>
      )}
      {hasPermission("projects", "create") && (
        <div className="upload-project-container">
          <input type="file" accept=".csv" onChange={handleFileChange} />
          <button onClick={handleParse}>Parse CSV</button>
          <a href={sampleCSV} download="sample_projects.csv">
            <button>Download Sample CSV</button>
          </a>
          {csvData.length > 0 && (
            <div>
              <h3>Parsed Projects</h3>
              <pre>{JSON.stringify(csvData, null, 2)}</pre>
              <button onClick={handleUpload}>Upload Projects</button>
            </div>
          )}
        </div>
      )}
      <input
        type="search"
        className={isNavBarOpen ? "project-search" : "project-search-close"}
        placeholder="Filter by project name or number or customer name"
        value={filter}
        onChange={handleSearch}
        title="Search project details"
      />
      <div
        className={
          isNavBarOpen ? "project-container" : "project-container-close"
        }
      >
        <div>
          {isFormVisible && (
            <form className="project-column-hide">
              <div className="project-column-hide-container">
                <div className="project-col-swap-header">
                  <h3
                    className="add-project-user-heading"
                    style={{ marginLeft: "14px" }}
                  >
                    Add, Remove, or Swap Columns
                  </h3>
                </div>
                <IoMdClose
                  onClick={toggleFormVisibility}
                  className="close-column-icon"
                />
                <div
                  style={{ marginTop: "45px" }}
                  className="project-col-header-container"
                >
                  <label className="project-column-swap">
                    <input
                      type="checkbox"
                      onClick={() => toggleAllColumns((prev) => !prev)}
                      checked={isAllSelected()}
                      style={{ marginRight: "20px", cursor: "pointer" }}
                    />
                    Select All
                  </label>
                </div>
                {Object.keys(visibleColumns).map((heading, index) => (
                  <div key={heading} className="project-col-header-container">
                    <label
                      className="project-column-swap"
                      draggable={
                        heading !== "Project No" && heading !== "Project Name"
                      }
                      onDragStart={
                        heading !== "Project No" && heading !== "Project Name"
                          ? () => handleDragStart(index)
                          : undefined
                      }
                      onDrop={
                        heading !== "Project No" && heading !== "Project Name"
                          ? () => handleDrop(index)
                          : undefined
                      }
                      onDragOver={
                        heading !== "Project No" && heading !== "Project Name"
                          ? (e) => e.preventDefault()
                          : undefined
                      }
                    >
                      <input
                        type="checkbox"
                        checked={visibleColumns[heading]}
                        onChange={() => toggleColumnVisibility(heading)}
                        style={{ marginRight: "20px", cursor: "pointer" }}
                        disabled={
                          heading === "Project No" || heading === "Project Name"
                        }
                      />
                      {heading}
                    </label>
                  </div>
                ))}
              </div>
            </form>
          )}
        </div>
        <div className="table-wrapper">
          <table
            className={
              isNavBarOpen ? "project-list-table" : "project-list-table-close"
            }
            style={{ top: "-13px" }}
          >
            <thead
              className="sticky-header"
              style={{ position: "sticky", top: "-1px", zIndex: "1" }}
            >
              <tr>
                {hasPermission("projects", "delete") && (
                  <th style={{ width: "2px", textAlign: "center" }}>
                    <FaChevronDown />
                  </th>
                )}
                {Object.keys(visibleColumns).map((heading, index) =>
                  visibleColumns[heading] ? (
                    <th
                      key={heading}
                      style={{
                        width:
                          heading === "Project No"
                            ? "246px"
                            : columnWidths[index],
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleSort(mapKeyToProjectProperty(heading))
                      }
                      onContextMenu={
                        heading !== "Project No" && heading !== "Project Name"
                          ? (e) => handleRightClick(e, heading)
                          : undefined
                      }
                    >
                      <div
                        className="resize-handle"
                        onMouseDown={(e) => handleMouseDown(index, e)}
                      />
                      {heading}
                      {sortConfig.key === mapKeyToProjectProperty(heading) && (
                        <span>
                          {sortConfig.direction === "asc" ? " ↑" : " ↓"}
                        </span>
                      )}
                    </th>
                  ) : null
                )}
                <th
                  style={{
                    position: "sticky",
                    right: 0,
                    backgroundColor: "white",
                    borderCollapse: "collapse",
                    textAlign: "center",
                    padding: "5px",
                    zIndex: 2, // Ensure it appears on top
                    width: "20px",
                  }}
                >
                  <PiColumnsPlusRight
                    className="column-icon"
                    onClick={toggleFormVisibility}
                    title="Add Column"
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      color: "#808080",
                    }} // Optional styling for icon
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentProjects.map((project, index) => {
                return (
                  <tr
                    key={project.id}
                    style={{
                      color: "black",
                      fontWeight: "400",
                      backgroundColor: getColorValue(project.tags),
                    }}
                  >
                    {hasPermission("projects", "delete") && (
                      <td>
                        <input
                          style={{ cursor: "pointer" }}
                          type="checkbox"
                          name={project.name}
                          checked={projectToDelete.some(
                            (p) => p.id === project.id
                          )}
                          onChange={(e) => handleDeleteProject(e, project)}
                        />
                      </td>
                    )}

                    {renderRowData(project)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {contextMenu.visible && (
          <div
            style={{
              position: "absolute",
              top: "67px",
              left: contextMenu.x,
              backgroundColor: "white",
              boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
              padding: "8px",
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",
              width: "130px",
            }}
            ref={projectRef}
          >
            <span className="hide-column-span" title="hide-column">
              <GrHide className="hide-icon" />
              <button onClick={hideColumn} className="hide-column">
                Hide Column
              </button>
            </span>
          </div>
        )}
      </div>
      {!isFormVisible && (
        <div
          className={
            userDetails.role_name === "Administrator" ||
            userDetails.role_name === "Project_Manager"
              ? "pagination-controls"
              : "pagination-controls-user"
          }
        >
          <h3 style={{ fontWeight: "normal" }}>
            Total : {filteredProjects.length}
          </h3>
          <select
            className="project-list-row-count"
            value={projectsPerPage}
            onChange={(e) => {
              setProjectsPerPage(Number(e.target.value));
            }}
          >
            <option value="25">25 Records Per Page</option>
            <option value="50">50 Records Per Page</option>
            <option value="75">75 Records Per Page</option>
            <option value="100">100 Records Per Page</option>
          </select>
          <button
            onClick={handleFirstPage}
            disabled={currentPage === 1}
            className="arrow-project-btn"
          >
            <MdKeyboardDoubleArrowLeft title="First Page" />
          </button>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="arrow-project-btn"
          >
            {/* */}
            <MdKeyboardArrowLeft title="Previous Page" />
          </button>
          <span
            style={{
              marginTop: "16px",
              textWrap: "nowrap",
            }}
          >
            {/* Page {currentPage} of {totalPages} */}
            {paginationCount}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="arrow-project-btn"
          >
            <MdKeyboardArrowRight title="Next Page" />
          </button>
          <button
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
            className="arrow-project-btn"
          >
            <MdKeyboardDoubleArrowRight title="Last Page" />
          </button>
        </div>
      )}
      {openEditDialog && (
        <div className="edit-proj-dial">
          <div class="dialog-edit-content">
            <div className="edit-project-user-header1">
              <h3 class="dialog-title">Edit Project</h3>
            </div>
            <h3 className="edit-proj-label">Project Name</h3>
            <input
              type="text"
              name="name"
              placeholder="Project Name"
              value={updatedProject?.name || ""}
              onChange={handleInputChange}
              className="edit-input"
            />
            <h3 className="edit-proj-label">Project No</h3>
            <input
              type="text"
              name="projectno"
              placeholder="Project No"
              value={updatedProject?.projectno || ""}
              onChange={handleInputChange}
              className="edit-input"
            />
            <h3 className="edit-proj-label">Project Manager</h3>
            <select
              className="edit-input"
              name="manager_id" // Add name attribute for handleInputChange
              value={updatedProject?.manager_id || ""} // Ensure the selected manager is reflected
              onChange={handleInputChange} // onChange will now correctly update manager_id
            >
              <option disabled value="">
                Select Manager
              </option>
              {managers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((manager) => (
                  <option key={manager.id} value={manager.id}>
                    {manager.name}
                  </option>
                ))}
            </select>

            <h3 className="edit-proj-label">Customer Name</h3>
            <select
              name="customer_id"
              className="edit-input"
              value={updatedProject.customer_id}
              onChange={handleInputChange}
            >
              <option>Select Customer</option>
              {customers
                .sort((a, b) => {
                  const aCustomerno = a.customerno || "";
                  const bCustomerno = b.customerno || "";
                  return aCustomerno.localeCompare(bCustomerno);
                })
                .map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.customerno}-{customer.name}
                  </option>
                ))}
            </select>
            <h3 className="edit-proj-label">Tags</h3>
            <select
              className="edit-input"
              value={updatedProject?.tags || ""}
              name="tags"
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select Tags
              </option>
              <option value="Yet to Start">Yet to Start</option>
              <option value="On Progress">On Progress</option>
              <option value="On Hold">On Hold</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
            <h3 className="edit-proj-label">Start Date</h3>
            <input
              type="date"
              value={
                updatedProject.start_date
                  ? new Date(
                      new Date(updatedProject.start_date).getTime() -
                        new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .split("T")[0]
                  : ""
              }
              onChange={(event) =>
                handleDateChange(new Date(event.target.value), "start_date")
              }
              className="edit-input"
            />
            <h3 className="edit-proj-label">End Date</h3>
            <input
              type="date"
              value={
                updatedProject.end_date
                  ? new Date(
                      new Date(updatedProject.end_date).getTime() -
                        new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .split("T")[0]
                  : ""
              }
              onChange={(event) =>
                handleDateChange(new Date(event.target.value), "end_date")
              }
              className="edit-input"
            />
            <h3 className="edit-proj-label">Description</h3>
            <textarea
              className="edit-description"
              name="description"
              placeholder="Description"
              value={updatedProject?.description || ""}
              onChange={handleInputChange}
            />
          </div>
          <div class="edit-proj-btn-con">
            <button
              onClick={handleUpdateProject}
              style={{ marginLeft: "23px" }}
              className="add-btn"
            >
              Save
            </button>
            <button
              onClick={() => setOpenEditDialog(false)}
              className="cancel-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {openDialog && (
        <>
          <div className="add-project-dial">
            <div className="add-new-project-content">
              <div className="add-project-user-header">
                <h3
                  className="add-project-user-heading"
                  style={{ marginLeft: "14px" }}
                >
                  New Project
                </h3>
              </div>
              <div className="project-name-number-container">
                <div className="name-container">
                  <h3 className="box-heading">Project No</h3>
                  <input
                    className="box-input"
                    placeholder="Enter Project No"
                    maxLength={20}
                    value={newProject.projectno}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        projectno: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="name-container" style={{ marginLeft: "14px" }}>
                  <h3 className="box-heading">Project Name</h3>
                  <input
                    className="box-input"
                    placeholder="Enter Project Name"
                    value={newProject.name}
                    maxLength={50}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="start-date-due-date-container">
                <div className="start-dat-container">
                  <h3 className="box-heading">Start date</h3>
                  <DatePicker
                    placeholderText="dd-mm-yyyy"
                    selected={newProject.start_date}
                    onChange={(date) =>
                      setNewProject((prev) => ({
                        ...prev,
                        start_date: date,
                      }))
                    }
                    dateFormat="dd-MM-yyyy"
                    className="box-input"
                    maxDate={newProject.end_date}
                  />
                </div>
                <div className="due-date-container">
                  <h3 className="box-heading">Due date</h3>
                  <DatePicker
                    placeholderText="dd-mm-yyyy"
                    selected={newProject.end_date}
                    onChange={(date) =>
                      setNewProject((prev) => ({
                        ...prev,
                        end_date: date,
                      }))
                    }
                    dateFormat="dd-MM-yyyy"
                    className="box-input"
                    minDate={newProject.start_date}
                  />
                </div>
              </div>
              <div className="project-owner-budget-container">
                <div className="owner-container">
                  <h3 className="box-heading">Owner</h3>
                  <select
                    className="select-d-input"
                    value={newProject.manager_id}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        manager_id: e.target.value,
                      }))
                    }
                  >
                    <option value="" disabled>
                      Select Owner
                    </option>
                    {users
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="name-container" style={{ marginLeft: "14px" }}>
                  <h3 className="box-heading">Tags</h3>
                  <select
                    value={newProject.tags}
                    className="select-d-input"
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        tags: e.target.value,
                      }))
                    }
                  >
                    <option value="" disabled>
                      Select Tags
                    </option>
                    <option value="Yet to Start">Yet to Start</option>
                    <option value="On Progress">On Progress</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
              <div className="add-description-container ">
                <div style={{ backgroundColor: "transparent" }}>
                  <h3 className="box-heading">Add Description</h3>
                  <textarea
                    className="box-des-input"
                    placeholder="Enter Description"
                    value={newProject.description}
                    maxLength={100}
                    onChange={(e) =>
                      setNewProject((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                  <div className="priority-container">
                    <h3 className="box-heading">Priority</h3>
                    <select
                      value={newProject.priority}
                      className="select-d-input"
                      onChange={(e) =>
                        setNewProject((prev) => ({
                          ...prev,
                          priority: e.target.value,
                        }))
                      }
                    >
                      <option value="" disabled>
                        Select Priority
                      </option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "transparent" }}>
                    <h3 className="box-heading">Select Customer</h3>
                    <input
                      className="box-input"
                      placeholder="Search Customer"
                      ref={dropdownRef}
                      value={searchTerm || selectedCustomerName}
                      onChange={handleSearchChange}
                      onFocus={handleFocus}
                      onMouseDown={() => handleClickOutside}
                    />
                    {showDropdown &&
                      (showDropdown && filteredCustomers?.length > 0 ? (
                        <ul
                          style={{ zIndex: "50" }}
                          ref={dropdownRef}
                          className="project-p-display-box"
                        >
                          {filteredCustomers.map((customer) => (
                            <li
                              key={customer.id}
                              value={customer.id}
                              onClick={() => {
                                setNewProject((prev) => ({
                                  ...prev,
                                  customer_id: customer.id,
                                }));
                                setShowDropdown(false);
                                setSearchTerm("");
                              }}
                              onMouseDown={() => handleClickOutside}
                              className="project-dropdown-item"
                            >
                              {customer.customerno}-{customer.name}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No Customers available</p>
                      ))}
                  </div>
                  <div className="tags-container">
                    <h3 className="box-heading">Project Group</h3>
                    <select
                      className="select-d-input"
                      style={{ marginTop: "0px", backgroundColor: "white" }}
                      value={newProject.team_id}
                      onChange={(e) => {
                        const selectedGroupId = e.target.value;
                        setNewProject((prev) => ({
                          ...prev,
                          team_id: selectedGroupId,
                        }));
                      }}
                    >
                      <option value="" disabled>
                        Select Group
                      </option>
                      {projectGroup
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.code}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="project-select-container">
                <div className="project-new-project-container "></div>
              </div>
              <div className="project-details-container">
                <h3 className="box-heading">Project Members</h3>
                <MultiSelect
                  options={members}
                  value={selectedMembers}
                  onChange={setSelectedMembers}
                  className="member-select-option"
                />
              </div>
            </div>
            <div className="add-new-project-button-container">
              <motion.button
                className="add-btn"
                onClick={handleAddNewProject}
                // whileHover={{
                //   scale: 1.2,
                //   transition: { duration: 1 },
                // }}
                whileTap={{ scale: 0.9 }}
              >
                Add Project
              </motion.button>

              <button onClick={closeDialog} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {deleteDialogOpen && (
        <div className="delete-phase-dial">
          <div className="delete-dial">
            <div className="delete-header">
              <h3
                style={{ backgroundColor: "transparent", marginLeft: "15px" }}
              >
                Delete Project
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p style={{ backgroundColor: "white" }}>
                Are you sure you want to delete this Project? This action cannot
                be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                id="delete-phase"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked((prev) => !prev)}
              />
              <label
                htmlFor="delete-phase"
                style={{ backgroundColor: "white", cursor: "pointer" }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-dial-btn-con">
              <button
                onClick={handleDeleteConfirm}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                className="add-btn"
                style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
              >
                Delete
              </button>

              <button onClick={handleCloseDial} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectList;
