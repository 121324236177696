import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useRolePermissions } from "../Settings/RoleContext";
import api from "../../api/axiosConfig";
import AddUser from "../DialBox/AddUser";
import "../../styles/Settings/Settings.css";
import { ProjectContext } from "../../App";
import { useAuth } from "../Authentication/AuthContext";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";

export const UserSettings = () => {
  const { isNavBarOpen } = useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [filterByActive, setFilterByActive] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState({});
  const { hasPermission } = useRolePermissions();
  const { openDialog, setOpenDialog } = useContext(ProjectContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userDetails } = useAuth();
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const userRole = userDetails.role;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const [activeUserLink, setUserActiveLink] = useState("activeusers");
  const fetchData = async (url, setData, setError, setLoading) => {
    try {
      const response = await api.get(url);
      setData(response.data);
      console.log("data", response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    }
  };

  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(6); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(9); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);
  const updateStatus = async (newStatus, userId) => {
    try {
      await api.put(`/api/settings/users/${userId}/status`, {
        status: newStatus,
      });
      // Refresh data
      fetchAllData();
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  const handleRoleChange = async (userId, e) => {
    console.log("userid", userId);
    const newRole = e.target.value;
    setUserRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: newRole,
    }));
    console.log("new role", newRole);
    try {
      const response = await api.put("/api/settings/role", {
        user_id: userId,
        role_name: newRole,
      });
      console.log("response", response);
      if (response.status === 200) {
        console.log("Role updated successfully");
      } else {
        console.error("Error updating role:", response.data.error);
      }
    } catch (error) {
      console.error("Request failed:", error.response?.data || error.message);
    }
  };

  const fetchAllData = async () => {
    const endpoint = `/api/settings/users`;
    fetchData(endpoint, setUsers, setError, setLoading);
    const roleEndpoint = "/api/roles";
    fetchData(roleEndpoint, setRoles, setError, setLoading);
  };
  useEffect(() => {
    fetchAllData();
  }, [userRole, userDetails.id, activeUserLink]);

  useEffect(() => {
    if (Array.isArray(users)) {
      const roles = users.reduce((acc, user) => {
        acc[user.id] = user.role_name; // Assuming role_name is available
        return acc;
      }, {});
      setUserRoles(roles);
    } else if (users.role_name) {
      setUserRoles({ [users.id]: users.role_name }); // Single user
    }
  }, [users]);

  const mapKeyToUserProperty = (heading) => {
    switch (heading) {
      case "User Name":
        return "name";
      case "Email ID":
        return "email";
      case "Role":
        return "role_name";
      case "User ID":
        return "employeecode";
      case "Active Status":
        return "status";
      case "Associated Groups":
        return "groups";
      case "Associated Projects":
        return "projects";
      case "Hour Rate":
        return "hourrate";
      default:
        return "";
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (activeUserLink === "activeusers") {
      setFilterByActive(users.filter((user) => user.status === "Active"));
    } else if (activeUserLink === "inactiveusers") {
      setFilterByActive(users.filter((user) => user.status === "Inactive"));
    } else {
      setFilterByActive(users);
    }
  }, [activeUserLink, users]);
  console.log("filtered users", filterByActive);
  const sortedUsers = [...filterByActive].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    const key = sortConfig.key || "name";
    const direction = sortConfig.direction === "desc" ? -1 : 1;

    // Sorting for "Associated Groups" and "Associated Projects" by length
    if (key === "groups" || key === "projects") {
      return (a[key].length - b[key].length) * direction;
    }

    // Sorting for "employeecode" (extracting numeric part)
    if (key === "employeecode") {
      const numA = parseInt(a[key].replace(/\D/g, ""), 10) || 0;
      const numB = parseInt(b[key].replace(/\D/g, ""), 10) || 0;
      return (numA - numB) * direction;
    }

    // Default string sorting for other columns
    if (a[key] < b[key]) return -1 * direction;
    if (a[key] > b[key]) return 1 * direction;
    return 0;
  });

  // const finalUsers = sortedUsers;
  const totalPages = Math.ceil(filterByActive.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  const currentUsers = sortedUsers.slice(indexOfFirstUsers, indexOfLastUsers);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  const handleHourRateChange = (userId, newHourRate) => {
    // Update the state for the user hourrate
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, hourrate: newHourRate } : user
      )
    );
  };

  const updateHourRate = async (userId, hourrate) => {
    try {
      // API call to update hourrate in the backend
      await api.put(`/api/users/${userId}/hourrate`, { hourrate });
      console.log("Hourrate updated successfully");
    } catch (error) {
      console.error("Failed to update hourrate", error);
    }
  };

  const handleUserLinkClick = (link) => {
    setUserActiveLink(link); // Update the active link
    setCurrentPage(1);
  };

  const handleUpdatePassword = async (userID) => {
    try {
      const response = await api.put(`/api/users/${userID}/password`, {
        isAdminReset: true, // Admin-triggered reset
      });

      if (response.status === 200) {
        alert(
          "Password reset successfully. Inform the user to change it after logging in."
        );
      } else {
        throw new Error(response.data.message || "Error resetting password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("Failed to reset the password. Please try again.");
    }
  };

  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error) return;
  <div>Error loading data! {error}</div>;
  return (
    <div className="settings-container">
      {hasPermission("users", "create") && (
        <button
          onClick={() => setOpenDialog((prev) => !prev)}
          className="add-user-settings-btn add-btn"
          title="Add User"
        >
          Add User
        </button>
      )}
      {openDialog && <AddUser />}
      <div
        className={
          isNavBarOpen
            ? "user-settings-tab-details"
            : "user-settings-tab-details-close"
        }
      >
        <Link
          style={{ marginLeft: "15px" }}
          className={`proj-links ${
            activeUserLink === "activeusers" ? "active" : ""
          }`}
          onClick={() => handleUserLinkClick("activeusers")}
        >
          Active Users
        </Link>
        <Link
          className={`proj-links ${
            activeUserLink === "inactiveusers" ? "active" : ""
          }`}
          onClick={() => handleUserLinkClick("inactiveusers")}
        >
          Inactive Users
        </Link>
        <Link
          className={`proj-links ${
            activeUserLink === "overallusers" ? "active" : ""
          }`}
          onClick={() => handleUserLinkClick("overallusers")}
        >
          Overall Users
        </Link>
      </div>
      <table
        className={
          isNavBarOpen
            ? "settings-content-table"
            : "settings-content-table-close"
        }
        style={{ width: "99%" }}
      >
        <thead style={{ position: "sticky", top: "-15px" }}>
          <tr>
            {[
              "User ID",
              "User Name",
              "Email ID",
              "Active Status",
              "Associated Groups",
              "Associated Projects",
              "Role",
              "Hour Rate",
              "Action",
              "",
            ].map((heading, index) => (
              <th
                key={index}
                onClick={
                  heading !== "Action" && heading !== ""
                    ? () => handleSort(mapKeyToUserProperty(heading))
                    : null
                }
                style={{
                  maxWidth: heading === "S.No" ? "10px" : "auto",
                  textAlign:
                    heading === "Associated Groups" &&
                    heading === "Associated Projects"
                      ? "center"
                      : "",
                }}
              >
                {heading}
                {heading !== "Associated Groups" &&
                  heading !== "Associated Projects" &&
                  heading !== "Action" &&
                  heading !== "" &&
                  sortConfig.key === mapKeyToUserProperty(heading) && (
                    <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                  )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.employeecode}</td>
              <td>
                <Link
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "black",
                  }}
                  to={`/user-details/${user.id}`}
                >
                  {user.picture && user.picture !== "null" ? (
                    <img
                      className="user-dp"
                      src={
                        user.picture
                          ? `${api.defaults.baseURL}${user.picture}`
                          : "/default-avatar.png"
                      }
                      alt="profile"
                    />
                  ) : (
                    <span className="user-initial">
                      {user.name.charAt(user.name.length - 1).toUpperCase() +
                        user.name.charAt(0).toUpperCase()}
                    </span>
                  )}
                  <p className="users-name"> {user.name}</p>
                </Link>
              </td>
              <td>{user.email}</td>
              <td
                style={{
                  color: "white",
                  width: "140px",
                  minWidth: "140px",
                }}
              >
                <select
                  className="status-dropdown-user"
                  value={user.status}
                  onChange={(e) => updateStatus(e.target.value, user.id)}
                  style={{
                    border: "none",
                    backgroundColor:
                      user.status === "Active"
                        ? "rgb(122, 206, 76)"
                        : "#d2b48c",
                    color: "white",
                    paddingLeft: "38px",
                    paddingRight: "28px",
                    cursor: "pointer",
                  }}
                  disabled={
                    !hasPermission("users", "update") ||
                    user.name === "Administrator"
                  }
                >
                  <option
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                    value="Inactive"
                  >
                    Inactive
                  </option>
                  <option
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                    value="Active"
                  >
                    Active
                  </option>
                </select>
              </td>
              <td style={{ width: "5px", textAlign: "center" }}>
                {user.groups.length}
              </td>
              <td style={{ width: "5px", textAlign: "center" }}>
                {user.projects.length}
              </td>
              {/* <td>{user.role_name}</td> */}
              <td>
                <select
                  value={userRoles[user.id] || user.role_name} // Use role for each user
                  onChange={(e) => handleRoleChange(user.id, e)}
                  disabled={
                    !hasPermission("users", "update") ||
                    user.name === "Administrator"
                  }
                  className="role-input"
                  style={{ marginBottom: "-8px" }}
                >
                  {roles.map((role) => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  className="invoice-element"
                  placeholder="Enter hour rate"
                  value={user.hourrate}
                  onChange={(e) =>
                    handleHourRateChange(user.id, e.target.value)
                  } // Handle input changes
                  onBlur={() => updateHourRate(user.id, user.hourrate)} // Optional: Save on blur
                  disabled={
                    !hasPermission("users", "update") ||
                    user.name === "Administrator"
                  }
                  style={{ width: "58px", textAlign: "center" }}
                />
              </td>
              <td>
                <button
                  className="reset-btn"
                  onClick={() => handleUpdatePassword(user.id)} // Corrected invocation
                >
                  Reset Password
                </button>
              </td>
              <td style={{ width: "360px" }}></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={isNavBarOpen ? "user-pagination" : "user-pagination-close"}
      >
        <h3 style={{ backgroundColor: "transparent" }}>
          Total : {filterByActive.length}
        </h3>
        <button
          onClick={handleFirstPage}
          disabled={currentPage === 1}
          className="arrow-project-btn"
        >
          <MdKeyboardDoubleArrowLeft title="First Page" />
        </button>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="arrow-project-btn"
        >
          <MdKeyboardArrowLeft title="Previous Page" />
        </button>
        <span
          style={{
            marginTop: "16px",
            textWrap: "nowrap",
            backgroundColor: "transparent",
          }}
        >
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="arrow-project-btn"
        >
          <MdKeyboardArrowRight title="Next Page" />
        </button>
        <button
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
          className="arrow-project-btn"
        >
          <MdKeyboardDoubleArrowRight title="Last Page" />
        </button>
      </div>
    </div>
  );
};
