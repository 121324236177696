import React, { useState, useEffect } from "react";
import api from "../../api/axiosConfig";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

function TimesheetList() {
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // Default to today
  const [sortedTimesheets, setSortedTimesheets] = useState([...timesheets]);

  useEffect(() => {
    fetchTimesheets(selectedDate);
  }, [selectedDate]);

  const fetchTimesheets = async (date) => {
    setLoading(true);
    setError("");
    try {
      const response = await api.get(`/api/userstimesheets/${date}`);
      setTimesheets(response.data);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
      console.error("Error fetching timesheets:", err);
    } finally {
      setLoading(false);
    }
  };

  // Calculate the total hours worked for the selected date
  const getTotalHours = () => {
    const totalMinutes = timesheets.reduce((total, entry) => {
      // Split the duration into hours and minutes
      const [hours, minutes] = (entry.total_duration_for_day || "0:00")
        .split(":")
        .map(Number);
      return total + hours * 60 + minutes; // Convert hours to minutes and add minutes
    }, 0);

    // Convert total minutes back into hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Return in HH:mm format
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  };

  useEffect(() => {
    // Initialize sortedTimesheets with data sorted by name (ascending order)
    const initialSortedData = [...timesheets].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setSortedTimesheets(initialSortedData);
  }, [timesheets]);

  const handleSort = () => {
    const sortedData = [...sortedTimesheets].sort((a, b) => {
      const getMinutes = (time) => {
        if (!time) return 0;
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      };

      return (
        getMinutes(b.total_duration_for_day) -
        getMinutes(a.total_duration_for_day)
      );
    });

    setSortedTimesheets(sortedData);
  };

  return (
    <div className="user-table-overview">
      <div className="user-timesheet-header-con">
        <h3 className="user-timesheet-header">Timesheets</h3>
      </div>
      {/* Date Picker */}
      <label>Select Date:</label>
      <DatePicker
        className="user-timesheet-datepicker"
        selected={new Date(selectedDate) || " "} // Use selected prop with Date object
        dateFormat="dd-MM-yyyy"
        onChange={(date) => setSelectedDate(format(date, "yyyy-MM-dd"))} // Handle selected date directly
      />

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      <table className="user-timesheet-table">
        <thead>
          <tr>
            <th>Name</th>
            {/* <th>Date</th> */}
            <th onClick={handleSort} style={{ cursor: "pointer" }}>
              Total Hours
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedTimesheets.length > 0 ? (
            sortedTimesheets.map((entry) => {
              return (
                <tr key={entry.user_id}>
                  <td>{entry.name}</td>
                  <td
                    style={{
                      textAlign: "center",
                      color:
                        entry.total_duration_for_day === "08:00"
                          ? "green"
                          : "red",
                    }}
                  >
                    {entry.total_duration_for_day || " "}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="3">No timesheet data found.</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Display Total Hours for the selected date */}
      {timesheets.length > 0 && (
        <div className="user-timesheet-total-hours">
          <h3>Total Hours: {getTotalHours()}</h3>
        </div>
      )}
    </div>
  );
}

export default TimesheetList;
