import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../App";
import { SiConvertio } from "react-icons/si";
import "../../styles/projects/Estimation.css";
import { useAuth } from "../Authentication/AuthContext";
import { useRolePermissions } from "../Settings/RoleContext";
import api from "../../api/axiosConfig";
import { GrHide } from "react-icons/gr";
import UpdatePhases from "./UpdatePhases";
import { IoMdClose } from "react-icons/io";
import { PiColumnsPlusRight } from "react-icons/pi";
import ConvertToProject from "./ConvertToProject";
import { FaChevronDown } from "react-icons/fa";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";

import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";

export const Estimation = () => {
  const { hasPermission } = useRolePermissions();
  const { isNavBarOpen } = useContext(ProjectContext);
  const { userDetails } = useAuth();
  const userID = userDetails.id;
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [estimates, setEstimates] = useState([]); // State to hold fetched estimates
  const [updatedProject, setUpdatedProject] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [estEditable, setEstEditable] = useState(false);
  const [estimateToDelete, setEstimateToDelete] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("");
  const [statuses, setStatuses] = useState({}); // Store status for each estimate by ID

  useEffect(() => {
    if (selectedCustomer) {
      const customer = customers.find((c) => c.id === Number(selectedCustomer));
      const name = `${customer.customerno}-${customer.name}`;
      setSelectedCustomerName(name);
    }
  }, [selectedCustomer, customers]);
  // Initialize statuses when estimates are received
  useEffect(() => {
    const initialStatuses = estimates.reduce((acc, estimate) => {
      acc[estimate.id] = estimate.status; // Set initial status based on estimates
      return acc;
    }, {});
    setStatuses(initialStatuses);
  }, [estimates]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [showUpdatePhases, setShowUpdatePhases] = useState(false);
  const [isConvertDialogOpen, setIsConvertDialogOpen] = useState(false);
  const [selectedEstimateDetails, setSelectedEstimateDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(25);
  let paginationCount;
  switch (projectsPerPage) {
    case "25":
      paginationCount = "1-25";
      break;
    case "50":
      paginationCount = "1-50";
      break;
    case "75":
      paginationCount = "1-75";
      break;
    case "100":
      paginationCount = "1-100";
      break;
    default:
      paginationCount = "1-25";
      break;
  }
  const [isEstDialogOpen, setIsEstDialogOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Form States
  const [estimationno, setEstimationno] = useState("");
  const [edate, setEdate] = useState("");
  const [projectname, setProjectname] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  // Updating Phases for Estimates
  const [selectedEstimateId, setSelectedEstimateId] = useState(null);
  const tableHeading = [
    "Date",
    "Estimate No",
    "MSES No",
    "Project",
    "Customer",
    "Contact",
    "Estimate by (Design)",
    "Estimate by (Detailing)",
    "Design Hours",
    "Detailing Hours",
    "Status",
    "Completed",
    "Convert Status",
    "Remarks",
  ];
  const [visibleColumns, setVisibleColumns] = useState({
    Date: true,
    "Estimate No": true,
    "MSES No": true,
    Project: true,
    Customer: true,
    Contact: true,
    "Estimate by (Design)": true,
    "Estimate by (Detailing)": true,
    "Design Hours": true,
    "Detailing Hours": true,
    Status: true,
    Completed: true,
    "Convert Status": true,
    Remarks: true,
  });

  const [columns, setColumns] = useState(tableHeading);
  const projectRef = useRef(null);
  const [columnWidths, setColumnWidths] = useState(Array(13).fill(100));
  const [filter, setFilter] = useState("");
  const startX = useRef(null);
  const startWidth = useRef(null);
  const activeColumnIndex = useRef(null);
  const handlePhaseUpdate = () => {
    console.log("Phases updated successfully!");
    // Fetch estimates again or show confirmation.
  };
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    column: null,
  });

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and profile
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) //&&
      // profileRef.current &&  !profileRef.current.contains(event.target)
    ) {
      setShowDropdown(false); // Close the dropdown
    }
  };

  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(e.target.value);
    setSelectedCustomerName("");
    // setUpdatedProject({ ...estimate, customer_name: "" });
    const filtered = customers.filter((customer) => {
      const name = customer.name || ""; // Fallback to an empty string
      const no = customer.customerno || ""; // Fallback to an empty string
      const displayName = customer.displayName || ""; // Fallback to an empty string

      return (
        name.toLowerCase().includes(searchValue) ||
        no.toLowerCase().includes(searchValue) ||
        displayName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredCustomers(filtered);
    setShowDropdown(true); // Show the dropdown when the user types
  };

  const handleFocus = () => {
    setSearchTerm(""); // Reset the search term to show all projects
    setFilteredCustomers(customers); // Show all projects when focusing on the input
    setShowDropdown(true); // Show the dropdown
  };

  const toggleFormVisibility = () => {
    setIsFormVisible((prevState) => !prevState);
  };
  const saveUserPreferences = async (preferences) => {
    const userId = userID; // Replace with the actual logged-in user's ID

    try {
      const response = await api.post(
        "/api/estimates/save-column-preferences",
        {
          userId,
          preferences,
        }
      );
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };
  //function for column visibility
  const toggleColumnVisibility = (columnKey) => {
    const updatedVisibleColumns = {
      ...visibleColumns,
      [columnKey]: !visibleColumns[columnKey],
    };

    // Update the state with the new visibility
    setVisibleColumns(updatedVisibleColumns);

    // Call the API to save the updated preferences
    saveUserPreferences(updatedVisibleColumns);
  };
  const handleRightClick = (event, column) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      column: column,
    });
  };
  const handleDragStart = (index) => {
    setDraggedColumnIndex(index);
  };

  const handleDrop = (dropIndex) => {
    if (draggedColumnIndex !== null) {
      // Clone columns array and reorder it
      const updatedColumns = [...columns];
      const [draggedColumn] = updatedColumns.splice(draggedColumnIndex, 1);
      updatedColumns.splice(dropIndex, 0, draggedColumn);

      // Update the columns in state
      setColumns(updatedColumns);

      // Update visibility order to match the new column order
      const newVisibleColumns = {};
      updatedColumns.forEach((col) => {
        newVisibleColumns[col] = visibleColumns[col];
      });
      setVisibleColumns(newVisibleColumns);

      // Save preferences to persist updated order and visibility
      saveUserPreferences(newVisibleColumns);

      // Reset draggedColumnIndex
      setDraggedColumnIndex(null);
    }
  };

  const hideColumn = async () => {
    if (contextMenu.column) {
      const updatedColumns = {
        ...visibleColumns,
        [contextMenu.column]: false,
      };
      setVisibleColumns(updatedColumns);
      setContextMenu({ ...contextMenu, visible: false });
      saveUserPreferences(updatedColumns);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the context menu
      if (projectRef.current && !projectRef.current.contains(event.target)) {
        setContextMenu({ ...contextMenu, visible: false });
      }
    }; // Attach event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenu]);

  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setProjectsPerPage(25); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setProjectsPerPage(25); // Medium screens (e.g., tablet)
    } else {
      setProjectsPerPage(25); // Large screens (e.g., desktop)
    }
  };

  const handleConvert = async (estimateId) => {
    try {
      const response = await api.get(`/api/estimates/${estimateId}/details`);
      const estimateDetails = response.data;
      setSelectedEstimateDetails(estimateDetails);
      setIsConvertDialogOpen(true);
    } catch (error) {
      console.error("Error fetching estimate details:", error);
    }
  };

  const handleCloseDialog = () => {
    setIsConvertDialogOpen(false);
    setSelectedEstimateDetails(null); // Reset details
  };
  // useEffect operations
  const fetchUserPreferences = async (userID) => {
    try {
      const response = await api.get(
        `/api/estimates/get-column-preferences/${userID}`
      );
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error("Error fetching preferences:", error);
      return null;
    }
  };
  useEffect(() => {
    // Fetch the user preferences when the component mounts
    const loadPreferences = async () => {
      const preferences = await fetchUserPreferences(userID);
      if (preferences) {
        setVisibleColumns(preferences);
        setColumns(Object.keys(preferences));
      } else {
        // Set default preferences if no saved preferences found
        const defaultColumns = {
          // delete: true,
          Date: true,
          "Estimate No": true,
          "MSES No": true,
          Project: true,
          Contact: true,
          Customer: true,
          "Design Hours": true,
          "Estimate by (Design)": true,
          "Detailing Hours": true,
          "Estimate by (Detailing)": true,
          Status: true,
          Completed: true,
          "Convert Status": true,
          Remarks: true,
        };
        setVisibleColumns(defaultColumns);
        setColumns(Object.keys(defaultColumns));
      }
    };

    loadPreferences();
  }, [userID]);
  const handleMouseMove = (e) => {
    if (activeColumnIndex.current !== null) {
      let newWidth = startWidth.current + (e.pageX - startX.current);
      newWidth = Math.max(50, Math.min(newWidth, 500)); // Use reasonable limits
      setColumnWidths((prevWidths) => {
        const updatedWidths = [...prevWidths];
        updatedWidths[activeColumnIndex.current] = newWidth;
        return updatedWidths;
      });
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    activeColumnIndex.current = null;
  };
  const handleMouseDown = (index, e) => {
    startX.current = e.pageX;
    startWidth.current = columnWidths[index];
    activeColumnIndex.current = index;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  const fetchInitialData = async () => {
    setLoading(true);
    try {
      // Fetch other data in parallel
      const [customerRes, statusRes, estimatesRes] = await Promise.all([
        api.get("/api/customers"),
        api.get("/api/get-status-values"),
        api.get("/api/estimates"),
      ]);
      setCustomers(customerRes.data);
      setLoading(false);
      setStatus(statusRes.data);
      setEstimates(estimatesRes.data.estimates);
    } catch (error) {
      handleApiError(error);
    }
  };

  const updatePageSettings = () => {
    updateProjectsPerPage();
    window.addEventListener("resize", updateProjectsPerPage);
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  };

  useEffect(() => {
    fetchInitialData();
    updatePageSettings();
  }, [userDetails]);

  // Helper to handle API errors
  const handleApiError = (error) => {
    if (error.response) {
      console.error(
        "Server Error:",
        error.response.data.error || error.response.statusText
      );
      setErrorMessage(
        `Server Error: ${
          error.response.data.error || error.response.statusText
        }`
      );
    } else if (error.request) {
      console.error("No response received:", error.request);
      setErrorMessage("No response received from the server.");
    } else {
      console.error("Request Error:", error.message);
      setErrorMessage(`Request Error: ${error.message}`);
    }
  };

  // Fetch contacts based on selected customer
  const handleCustomerChange = useCallback(async (e) => {
    const selectedCustomerId = e.target.value;
    setSelectedCustomer(selectedCustomerId);
    setShowDropdown(false);
    setSearchTerm("");
    setSelectedContact(""); // Reset contact selection

    try {
      const { data } = await api.get(
        `/api/customers/${selectedCustomerId}/contacts`
      );
      setContacts(data.contacts);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      setContacts([]);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation for estimation number
    if (!estimationno.trim() && !edate) {
      setErrorMessage("Estimation number is required");
      return;
    }
    const formattedDate = new Date(edate).toLocaleDateString("en-CA");

    // Prepare request payload
    const requestData = {
      estimationno,
      // edate: new Date(edate).toISOString().split("T")[0],
      edate,
      projectname,
      customer_id: selectedCustomer,
      contact_id: selectedContact,
      description,
    };

    try {
      const response2 = await api.post("/api/estimates_info", requestData);

      resetForm();
      // Update state with new estimate
      setEstimates((prev) => {
        const updatedEstimates = [...prev, response2.data]; // Add new estimate
        return updatedEstimates;
      });
      // alert("Estimate created successfully");
      // alert(response2.data.message);
      toast.success("Estimate Created successfully!", {
        position: "bottom-center",
        autoClose: 1000,
      });
      fetchInitialData();
      setIsEstDialogOpen(false);
    } catch (error) {
      // Handle error
      console.error("Error creating estimate:", error); // Debugging log
      setErrorMessage(
        error.response?.data?.error || "Failed to create estimation"
      );
    } finally {
      setIsEstDialogOpen(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Validation for estimation number and date
    if (!estimationno.trim() || !edate) {
      setErrorMessage("Estimation number and date are required");
      return;
    }

    // Prepare request payload
    const requestData = {
      estimationno, // Estimation number
      date: edate, // Date
      projectname, // Project name
      customer_id: selectedCustomer, // Customer ID
      contact_id: selectedContact, // Contact ID
    };

    try {
      const response = await api.put(
        `/api/update-estimates_info/${updatedProject.id}`,
        requestData
      );
      setEstEditable(false);

      // Update state with the newly updated estimate
      setEstimates((prevEstimates) => {
        // Find the updated estimate in the array and update it
        const updatedEstimates = prevEstimates.map((estimate) =>
          estimate.estimationno === updatedProject.estimationno
            ? { ...estimate, ...response.data }
            : estimate
        );
        return updatedEstimates;
      });

      // Fetch the latest data (if needed)
      fetchInitialData();

      // Reset the form and close the dialog
      resetForm();
      setIsEstDialogOpen(false);
      toast.success("Estimate updated successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    } catch (error) {
      console.error("Error updating estimate:", error); // Debugging log
      setErrorMessage(
        error.response?.data?.error || "Failed to update estimation"
      );
    } finally {
      setIsEstDialogOpen(false);
    }
  };

  const handleEstOpenDialog = () => {
    setIsEstDialogOpen(true);
  };

  const handleEstCloseDialog = () => {
    setIsEstDialogOpen(false);
    setEstEditable(false);
    resetForm();
  };
  const resetForm = () => {
    setEdate("");
    setSelectedCustomer("");
    setSelectedContact("");
    setEstimationno("");
    setProjectname("");
    setDescription("");
    setErrorMessage("");
    setSelectedCustomerName("");
  };

  const handleDeleteProject = (e, selectedEstimate) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setEstimateToDelete((prevSelected) => [
        ...prevSelected,
        selectedEstimate,
      ]);
    } else {
      setEstimateToDelete((prevSelected) =>
        prevSelected.filter((project) => project.id !== selectedEstimate.id)
      );
    }
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    const idsToDelete = estimateToDelete.map((project) => project.id);

    setDeleteDialogOpen(false); // Close the delete confirmation dialog

    try {
      const response = await api.delete("/api/estimates", {
        data: { ids: idsToDelete }, // Send the IDs in the request body
      });

      // Check for successful deletion
      if (response.status === 204) {
        const updatedEstimate = estimates.filter(
          (project) => !idsToDelete.includes(project.id)
        );
        setEstimates(updatedEstimate); // Update the projects state
        setLoading(false);
      } else {
        console.error("Failed to delete projects");
        toast.error("Failed to delete estimate. Please try again.", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("There was an error deleting the projects:", error);
      toast.error(
        "An error occurred while deleting estimate. Please try again.",
        {
          position: "bottom-center",
          autoClose: 2000,
        }
      );
    } finally {
      setEstimateToDelete([]); // Clear the projects to delete
      handleCloseDial(); // Close any dialog
      setLoading(false);
    }
  };

  const handleCloseDial = () => {
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
  };
  const mapKeyToProjectProperty = (heading) => {
    switch (
      heading.trim() // Use trim() to remove any accidental spaces
    ) {
      case "Estimate No":
        return "estimationno";
      case "MSES No":
        return "msesno"; // Ensure this matches the exact key in your project data
      case "Project":
        return "project_name";
      case "Customer":
        return "customer_name";
      case "Contact":
        return "contact_name";
      case "Date":
        return "edate";
      case "Status":
        return "status";
      case "Estimate by (Design)":
        return "estimated_by_design";
      case "Estimate by (Detailing)":
        return "estimated_by_detailing";
      case "Design Hours":
        return "";
      case "Detailing Hours":
        return "status_name";
      case "Description":
        return "description";
      case "S.No":
        return "index";
      default:
        return "";
    }
  };
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedEstimates = [...estimates].sort((a, b) => {
    if (!sortConfig?.key) {
      const getNumericPart = (value) => {
        if (typeof value !== "string") return 0;
        return parseInt(value.replace(/\D/g, ""), 10) || 0;
      };

      const bValue = getNumericPart(b.estimationno || ""); // Use an empty string if undefined
      const aValue = getNumericPart(a.estimationno || ""); // Use an empty string if undefined

      return bValue - aValue;
    }

    const { key, direction } = sortConfig;
    const sortDirection = direction === "asc" ? 1 : -1;

    let aValue = a[key];
    let bValue = b[key];

    // Handle specific sorting cases
    switch (key) {
      case "estimationno":
      case "msesno":
        const getNumericPart = (value) => {
          if (typeof value !== "string") return 0;
          return parseInt(value.replace(/\D/g, ""), 10) || 0;
        };
        getNumericPart(undefined); // Returns 0 safely
        aValue = getNumericPart(aValue);
        bValue = getNumericPart(bValue);
        break;

      case "date":
        aValue = new Date(aValue);
        bValue = new Date(bValue);
        break;

      case "projectname":
      case "status":
      case "user_name":
      case "customer_name":
      case "contact_name":
      case "description":
        aValue = String(aValue || "").toLowerCase();
        bValue = String(bValue || "").toLowerCase();
        break;

      default:
        if (typeof aValue === "string") aValue = aValue.toLowerCase();
        if (typeof bValue === "string") bValue = bValue.toLowerCase();
        break;
    }

    // Perform sorting
    if (aValue < bValue) return -1 * sortDirection;
    if (aValue > bValue) return 1 * sortDirection;
    return 0;
  });

  const filteredEstimates = sortedEstimates.filter((estimate) => {
    return (
      estimate.msesno?.toLowerCase().includes(filter) ||
      estimate.estimationno?.toLowerCase().includes(filter) ||
      estimate.projectname?.toLowerCase().includes(filter) ||
      estimate.customer_name?.toLowerCase().includes(filter)
    );
  });

  const handleSearch = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  const totalPages = Math.ceil(filteredEstimates.length / projectsPerPage);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredEstimates.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const handleStatusChange = async (e, estimateId) => {
    const newStatus = e.target.value;
    const isConfirmed = window.confirm(
      "Are you sure you want to change the estimate status?"
    );

    if (!isConfirmed) {
      // If the user clicks "Cancel", do nothing and return
      return;
    }
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [estimateId]: newStatus, // Update the status of the selected estimate
    }));

    // Update status in the backend
    try {
      const response = await api.put("/api/update-estimate-status", {
        estimateId,
        status: newStatus, // Send estimateId and new status
      });
      fetchInitialData();
      if (!response.ok) {
        throw new Error("Failed to update status");
      }

      const data = await response.json();
      console.log("Status updated successfully:", data);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };

  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };

  const handleEditClick = async (estimate) => {
    console.log("selected estimate", estimate);
    setSelectedCustomer(estimate.customer_id);
    setSelectedContact(estimate.contact_id);
    setSelectedProject(estimate);
    setUpdatedProject({ ...estimate });
    setEstimationno(estimate.estimationno);
    setEdate(estimate.edate);
    setProjectname(estimate.projectname);
    setEstEditable(true);
    setIsEstDialogOpen(true);
    // setOpenEditDialog(true);
    if (estimate.customer_id) {
      try {
        const { data } = await api.get(
          `/api/customers/${estimate.customer_id}/contacts`
        );
        setContacts(data.contacts);
      } catch (error) {
        console.error("Error fetching contacts:", error);
        setContacts([]);
      }
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }
  const ChangeEstimateDetails = (estimateId, columnToUpdate, newValue) => {
    // Update the specific column value for the estimate
    setEstimates((prevEstimates) => {
      return prevEstimates.map((est) =>
        est.id === estimateId
          ? { ...est, [columnToUpdate]: newValue } // Dynamically update the column
          : est
      );
    });
  };

  const UpdateDetails = async (estimateId, columnToUpdate, newValue) => {
    try {
      const response = await api.put("/api/update-estimate", {
        estimateId,
        columnToUpdate,
        newValue, // Include any necessary data
      });
      if (response.ok) {
        console.log("Details updated successfully");
      } else {
        throw new Error("Failed to update details");
      }
    } catch (error) {
      console.error("Error updating details:", error);
    }
  };

  const renderRowData = (estimate) => {
    return columns.map((columnKey) => {
      if (visibleColumns[columnKey]) {
        switch (columnKey) {
          case "Date":
            return (
              <td style={{ minWidth: "100px" }}>
                {estimate.edate
                  ? format(new Date(estimate.edate), "dd-MM-yyyy")
                  : ""}
              </td>
            );
          case "MSES No":
            return (
              <td style={{ padding: "0px" }}>
                <input
                  className="est-description"
                  maxLength={20}
                  value={estimate.msesno}
                  onChange={(e) =>
                    ChangeEstimateDetails(estimate.id, "msesno", e.target.value)
                  }
                  onBlur={(e) => {
                    UpdateDetails(estimate.id, "msesno", e.target.value);
                  }}
                />
              </td>
            );
          case "Estimate No":
            return (
              <td>
                <div className="estimate-table-con">
                  <Link
                    className="select-project"
                    style={{
                      marginTop: "3px",
                    }}
                    to={`/estimate/phases/${estimate.id}`}
                  >
                    {estimate.estimationno}
                  </Link>
                  {hasPermission("projects", "update") && (
                    <span className="edit-icon-wrapper" title="Edit project">
                      <EditIcon
                        onClick={() => handleEditClick(estimate)}
                        style={{
                          backgroundColor: "transparent",
                          color: "blue",
                        }}
                      />
                    </span>
                  )}
                </div>
              </td>
            );
          case "Project":
            return <td>{estimate.projectname}</td>;
          case "Customer":
            return <td>{estimate.customer_name}</td>;
          case "Contact":
            return <td>{estimate.contact_name}</td>;
          case "Status":
            return (
              <td
                className={`estimate-status-dropdown-con ${
                  statuses[estimate.id] || ""
                }`}
              >
                <select
                  className={`estimate-status-dropdown ${
                    statuses[estimate.id] || ""
                  }`}
                  value={statuses[estimate.id] || ""}
                  onChange={(e) => handleStatusChange(e, estimate.id)}
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  {Object.entries(status).map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </td>
            );
          case "Estimate by (Design)":
            return (
              <td>
                {estimate.estimated_by_design
                  ? estimate.estimated_by_design
                  : ""}
              </td>
            );
          case "Estimate by (Detailing)":
            return (
              <td>
                {estimate.estimated_by_detailing
                  ? estimate.estimated_by_detailing
                  : ""}
              </td>
            );
          case "Design Hours":
            return (
              <td style={{ textAlign: "center" }}>
                {estimate.template_planned_hours
                  .filter((template) => template.template_name === "Design")
                  .map((template) =>
                    template.planned_hours.substring(0, 5) === "00:00"
                      ? ""
                      : template.planned_hours
                  )
                  .join(", ")}
              </td>
            );
          case "Detailing Hours":
            return (
              <td style={{ textAlign: "center" }}>
                {estimate.template_planned_hours
                  .filter((template) => template.template_name === "Detailing")
                  .map((template) =>
                    template.planned_hours.substring(0, 5) === "00:00"
                      ? ""
                      : template.planned_hours
                  )
                  .join(", ")}
              </td>
            );
          case "Completed":
            return <td>{estimate.completed_total}</td>;
          case "Convert Status":
            return (
              <td
                className="convert-column"
                style={{
                  padding: "0px",
                  position: "relative",
                  textAlign: "center",
                }}
              >
                {estimate.converted_by === null
                  ? estimate.template_planned_hours.length === 0 ||
                    estimate.template_planned_hours.some(
                      (template) => template.planned_hours === null
                    )
                    ? "No Tasks available"
                    : statuses[estimate.id] === "Awarded" && (
                        <button
                          className="convert-btn"
                          onClick={() => handleConvert(estimate.id)}
                        >
                          <SiConvertio />
                        </button>
                      )
                  : estimate.converted_by_name}
              </td>
            );
          case "Remarks":
            return (
              <td style={{ padding: "0px" }}>
                <input
                  className="est-description"
                  maxLength={100}
                  onChange={(e) =>
                    ChangeEstimateDetails(
                      estimate.id,
                      "description",
                      e.target.value
                    )
                  }
                  onBlur={(e) =>
                    UpdateDetails(estimate.id, "description", e.target.value)
                  }
                  value={estimate.description}
                />
              </td>
            );
        }
      }
    });
  };

  const handleSelectAll = () => {
    const newVisibility = { ...visibleColumns };

    const areAllSelected = isAllSelected();

    Object.keys(newVisibility).forEach((heading) => {
      if (
        heading !== "Estimate No" &&
        heading !== "MSES No" &&
        heading !== "Date" &&
        heading !== "Project"
      ) {
        newVisibility[heading] = !areAllSelected;
      }
    });
    saveUserPreferences(newVisibility);
    setVisibleColumns(newVisibility); // Update the state with the new column visibility
  };

  const isAllSelected = () => {
    return Object.keys(visibleColumns).every(
      (heading) =>
        heading === "Estimate No" ||
        heading === "MSES No" ||
        heading === "Date" ||
        heading === "Project" ||
        visibleColumns[heading]
    );
  };

  return (
    <div
      className={
        isNavBarOpen ? "estimation-container" : "estimation-container-close"
      }
    >
      <input
        type="search"
        title="Filter by Estimate No or Project or MSES No or Customer"
        placeholder="Filter by Estimate No or Project or MSES No or Customer"
        onChange={handleSearch}
        className={isNavBarOpen ? "est-search-open" : "est-search-close"}
      />
      <ToastContainer />
      {/* <h3>Existing Estimates</h3> */}
      {hasPermission("estimates", "delete") && estimateToDelete.length > 0 && (
        <button
          onClick={() => setDeleteDialogOpen(true)}
          className="delete-est-heading"
          title="Delete project"
        >
          Delete
        </button>
      )}

      <button onClick={handleEstOpenDialog} className="create-estimate-btn">
        Create Estimate
      </button>

      {isFormVisible && (
        <form className="project-column-hide">
          <div className="project-column-hide-container">
            <div className="project-col-swap-header">
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "14px" }}
              >
                Add, Remove, or Swap Columns
              </h3>
            </div>
            <IoMdClose
              onClick={toggleFormVisibility}
              className="close-column-icon"
            />
            <div
              style={{ marginTop: "45px" }}
              className="project-col-header-container"
            >
              <label style={{ cursor: "pointer" }}>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={isAllSelected()}
                  style={{ marginRight: "20px", cursor: "pointer" }}
                />
                Select All
              </label>
            </div>
            {Object.keys(visibleColumns).map((heading, index) => (
              <div key={heading} className="project-col-header-container">
                <label
                  style={{ cursor: "pointer" }}
                  draggable={
                    heading !== "MSES No" &&
                    heading !== "Estimate No" &&
                    heading !== "Date" &&
                    heading !== "Project"
                  }
                  onDragStart={
                    heading !== "MSES No" &&
                    heading !== "Estimate No" &&
                    heading !== "Date" &&
                    heading !== "Project"
                      ? () => handleDragStart(index)
                      : undefined
                  }
                  onDrop={
                    heading !== "MSES No" &&
                    heading !== "Estimate No" &&
                    heading !== "Date" &&
                    heading !== "Project"
                      ? () => handleDrop(index)
                      : undefined
                  }
                  onDragOver={
                    heading !== "MSES No" &&
                    heading !== "Estimate No" &&
                    heading !== "Date" &&
                    heading !== "Project"
                      ? (e) => e.preventDefault()
                      : undefined
                  }
                >
                  <input
                    type="checkbox"
                    checked={visibleColumns[heading]}
                    onChange={() => toggleColumnVisibility(heading)}
                    style={{ marginRight: "20px", cursor: "pointer" }}
                    disabled={
                      heading === "Estimate No" ||
                      heading === "MSES No" ||
                      heading === "Date" ||
                      heading === "Project"
                    }
                  />
                  {heading}
                </label>
              </div>
            ))}
          </div>
        </form>
      )}
      <table
        border="1"
        className={
          isNavBarOpen
            ? "estimation-content-table"
            : "estimation-content-table-close"
        }
      >
        <thead
          className="sticky-header-est"
          style={{ position: "sticky", top: "-1px", zIndex: "1" }}
        >
          <tr>
            <th
              style={{
                textAlign: "center",
                width: "2px",
              }}
            >
              <FaChevronDown />
            </th>
            {Object.keys(visibleColumns).map((heading, index) =>
              visibleColumns[heading] ? (
                <th
                  key={heading}
                  style={{
                    cursor: "pointer",
                    width:
                      heading !== "MSES No" &&
                      heading !== "Estimate No" &&
                      heading !== "Date" &&
                      heading !== "Project"
                        ? columnWidths[index]
                        : "200px",
                  }}
                  onClick={() => handleSort(mapKeyToProjectProperty(heading))}
                  onContextMenu={
                    heading !== "MSES No" &&
                    heading !== "Estimate No" &&
                    heading !== "Date" &&
                    heading !== "Project"
                      ? (e) => handleRightClick(e, heading)
                      : undefined
                  }
                >
                  <div
                    className="resize-handle"
                    onMouseDown={(e) => handleMouseDown(index, e)}
                  />
                  {heading}
                  {sortConfig.key === mapKeyToProjectProperty(heading) && (
                    <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                  )}
                </th>
              ) : null
            )}
            <th
              style={{
                position: "sticky",
                right: 0,
                backgroundColor: "white",
                boxShadow:
                  "2px 0px 5px rgba(0, 0, 0, 0.1), 2px 0px 5px rgba(0, 0, 0, 0.1)", // Left + Right shadow
                borderCollapse: "collapse",
                textAlign: "center",
                padding: "5px",
                zIndex: 2, // Ensure it appears on top
                width: "20px",
              }}
            >
              <PiColumnsPlusRight
                className="estimate-column-icon"
                onClick={toggleFormVisibility}
                title="Add Column"
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  color: "#808080",
                }} // Optional styling for icon
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {estimates.length > 0 ? (
            currentProjects.map((estimate) => (
              <tr key={estimate.id}>
                <td>
                  <input
                    style={{ cursor: "pointer" }}
                    type="checkbox"
                    name={estimate.estimationno}
                    checked={estimateToDelete.some((p) => p.id === estimate.id)}
                    onChange={(e) => handleDeleteProject(e, estimate)}
                  />
                </td>
                {renderRowData(estimate)}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="15" style={{ textAlign: "center" }}>
                No estimates found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {contextMenu.visible && (
        <div
          style={{
            position: "absolute",
            top: "87px",
            left: contextMenu.x,
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
            padding: "8px",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
          }}
          ref={projectRef}
        >
          <span className="hide-column-span" title="hide-column">
            <GrHide className="hide-icon" />
            <button onClick={hideColumn} className="hide-column">
              Hide Column
            </button>
          </span>
        </div>
      )}
      <div className="estimation-pagination">
        <h3>Total : {filteredEstimates.length}</h3>
        <select
          className="est-list-row-count"
          onChange={(e) => {
            setProjectsPerPage(e.target.value);
          }}
        >
          <option value={"25"}>25 Records Per Page</option>
          <option value={"50"}>50 Records Per Page</option>
          <option value={"75"}>75 Records Per Page</option>
          <option value={"100"}>100 Records Per Page</option>
        </select>
        <button
          className="arrow-project-btn"
          onClick={handleFirstPage}
          disabled={currentPage === 1}
        >
          <MdKeyboardDoubleArrowLeft title="First Page" />
        </button>
        <button
          className="arrow-project-btn"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <MdKeyboardArrowLeft title="Previous Page" />
        </button>
        <span
          style={{
            marginTop: "16px",
            textWrap: "nowrap",
          }}
        >
          {paginationCount}
        </span>
        <button
          className="arrow-project-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <MdKeyboardArrowRight title="Next Page" />
        </button>
        <button
          className="arrow-project-btn"
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
        >
          <MdKeyboardDoubleArrowRight title="Last Page" />
        </button>
      </div>
      {/* Button to Open Create Estimation Dialog */}
      {/* Create Estimation Dialog */}
      {isEstDialogOpen && (
        <div className="timesheet-overlay-add-time-log">
          <div className="new-estimation-log">
            <h3 className="add-estimation-heading">
              {estEditable ? "Edit Estimate" : "Create Estimate"}
            </h3>
          </div>
          <div className="scrollable-e-content">
            {errorMessage && <div className="error">{errorMessage}</div>}
            <form
              id="estimation-form"
              onSubmit={estEditable ? handleUpdate : handleSubmit}
            >
              <div>
                <h3 className="user-name">Date</h3>
                <DatePicker
                  placeholderText="Select Date"
                  className="timesheet-d-input"
                  selected={edate}
                  onChange={(date) => setEdate(date)}
                  dateFormat="dd-MM-yyyy"
                  required
                />
              </div>
              <div>
                <h3 className="user-name">Estimate No</h3>
                <input
                  className="timesheet-d-input"
                  placeholder="Enter Estimate No"
                  type="text"
                  maxLength={20}
                  value={estimationno || ""}
                  onChange={(e) => setEstimationno(e.target.value)}
                  required
                />
              </div>
              <div>
                <h3 className="user-name">Project</h3>
                <input
                  className="timesheet-d-input"
                  placeholder="Enter Project Name"
                  type="text"
                  maxLength={50}
                  value={projectname || ""}
                  onChange={(e) => setProjectname(e.target.value)}
                  required
                />
              </div>
              <div></div>
              <div>
                <h3 className="user-name">Customer</h3>
                <input
                  ref={dropdownRef}
                  className="timesheet-d-input"
                  placeholder="Search Customer"
                  value={
                    searchTerm !== null
                      ? searchTerm || selectedCustomerName
                      : ""
                  }
                  onChange={handleSearchChange}
                  onFocus={handleFocus}
                  onMouseDown={() => handleClickOutside}
                />
                {showDropdown &&
                  (showDropdown && filteredCustomers?.length > 0 ? (
                    <ul
                      style={{ zIndex: "50" }}
                      ref={dropdownRef}
                      className="project-display-box"
                    >
                      {filteredCustomers.map((project) => (
                        <li
                          key={project.id}
                          value={project.id}
                          onClick={(e) => handleCustomerChange(e)}
                          onMouseDown={() => handleClickOutside}
                          className="project-dropdown-item"
                        >
                          {project.customerno}-{project.name}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Customers available</p>
                  ))}
              </div>
              <div>
                <h3 className="user-name">Contact</h3>
                <select
                  className="timesheet-input"
                  value={selectedContact}
                  onChange={(e) => setSelectedContact(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Contact
                  </option>
                  {contacts.map((contact) => (
                    <option key={contact.contact_id} value={contact.contact_id}>
                      {contact.contact_name}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>

          <div className="btn-container-add-timesheet">
            <button
              onclick={estEditable ? handleUpdate : handleSubmit}
              form="estimation-form"
              type="submit"
              className="add-btn"
            >
              {estEditable ? "Update" : "Submit"}
            </button>
            <button onClick={handleEstCloseDialog} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      )}
      {deleteDialogOpen && (
        <div className="delete-phase-dial">
          <div className="delete-dial">
            <div className="delete-header">
              <h3
                style={{ backgroundColor: "transparent", marginLeft: "15px" }}
              >
                Delete Estimate
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p style={{ backgroundColor: "white" }}>
                Are you sure you want to delete this Estimate? This action
                cannot be undone.
              </p>
              <input
                type="checkbox"
                style={{ cursor: "pointer" }}
                id="delete-phase"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked((prev) => !prev)}
              />
              <label
                htmlFor="delete-phase"
                style={{ backgroundColor: "white", cursor: "pointer" }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-dial-btn-con">
              <button
                onClick={handleDeleteConfirm}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                className="add-btn"
                style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
              >
                Delete
              </button>

              <button onClick={handleCloseDial} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {isConvertDialogOpen && selectedEstimateDetails && (
        <ConvertToProject
          estimateDetails={selectedEstimateDetails}
          goBack={() => setIsConvertDialogOpen(false)}
        />
      )}

      {/* Render the UpdatePhases component conditionally */}
      {showUpdatePhases ? (
        selectedEstimateId ? (
          <UpdatePhases
            estimateId={selectedEstimateId}
            onPhaseUpdate={handlePhaseUpdate}
            goBack={() => setShowUpdatePhases(false)}
          />
        ) : (
          <p>Please select an estimate to update phases.</p>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
};
