import React, { useEffect, useState, useMemo, useContext, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import "../../styles/projects/ProjectDetails.css";
import { ProjectContext } from "../../App";
import { useAuth } from "../Authentication/AuthContext";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";
import { useRolePermissions } from "../Settings/RoleContext";
import api from "../../api/axiosConfig";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import Modal from "react-modal";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await api.get(url); // Axios automatically adds the baseURL and headers
    setData(response.data); // Axios automatically parses JSON
  } catch (error) {
    console.error("Error fetching data:", error);
    setError(error); // Handle error
  }
};

const addPhase = async (projectId, phase, setError) => {
  try {
    const response = await api.post(`/api/project/${projectId}/phases`, phase);
    toast.success("New Phase added", {
      position: "bottom-center",
      autoClose: 2000,
    });
    // window.location.reload();
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a specific error
      console.error("Server error:", error.response.data);
      setError(error.response.data.message || "Failed to add phase.");
    } else if (error.request) {
      // Request was made, but no response received
      console.error("No response received:", error.request);
      setError("Server did not respond. Please try again later.");
    } else {
      // Other errors (e.g., network issues)
      console.error("Unexpected error:", error.message);
      setError(error.message || "An unexpected error occurred.");
    }
  }
};

const deletePhase = async (phaseIds, setError) => {
  try {
    const url = `/api/phases`; // No phaseId in the URL, we're using the request body
    await api.delete(url, { data: { phaseIds } }); // Send phaseIds in the request body
    toast.success("Selected Phase deleted successfully", {
      position: "bottom-center",
      autoClose: 2000,
    });
  } catch (error) {
    if (error.response) {
      // If the server responded with an error
      const serverError = error.response.data;
      console.error("Server error:", serverError);
      setError(
        serverError.message ||
          "An error occurred while deleting phases. Please try again."
      );
      alert(
        serverError.message ||
          "An error occurred while deleting phases. Please try again."
      );
      window.location.reload();
    } else {
      // Generic error handling
      console.error("Unexpected error:", error);
      setError("Unexpected error occurred. Please try again.");
    }
  }
};

const ProjectDetails = () => {
  const { hasPermission } = useRolePermissions();
  const { projectId } = useParams();
  const { userDetails } = useAuth();
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
  const [showInvoiceTable, setShowInvoiceTable] = useState(false);
  const userId = userDetails.id;
  const [project, setProject] = useState({
    id: null,
    name: "",
    projectno: "",
    customer_name: "",
    phases: [],
  });
  const [loading, setLoading] = useState(true);
  const profileRef = useRef(null);
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const [error, setError] = useState(null);
  const [statusOptions, setStatusOption] = useState([]);
  console.log("statusOptions", statusOptions);
  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    if (invoices?.invoices?.length > 0) {
      const total = invoices.invoices.reduce(
        (total, invoice) => total + invoice.claimpercent,
        0
      );
      setinvoiceTotal(total);
    }
  }, [invoices]);
  const [invoiceTotal, setinvoiceTotal] = useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState([]);
  const [deleteInvoiceDialogOpen, setDeleteInvoiceDialogOpen] = useState(false);
  const [phaseDialogOpen, setPhaseDialogOpen] = useState(false);
  const [phaseEditDialogOpen, setPhaseEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState([]);
  const [phaseId, setPhaseId] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [totalPlannedHours, setTotalPlannedHours] = useState("");
  const [editingPhase, setEditingPhase] = useState(null); // Holds the phase being edited
  const [bulkAction, setBulkAction] = useState(false);
  const [claimStatus, setClaimStatus] = useState("");
  const [formValues, setFormValues] = useState({
    phaseno: "",
    title: "",
    plannedhours: "",
  });
  const [newPhase, setNewPhase] = useState({
    title: "",
    plannedhours: 0,
    phaseno: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const [phaseActualHours, setPhaseActualHours] = useState({});

  const [invoiceNo, setInvoiceNo] = useState("INV-25-");
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [percentage, setPercentage] = useState("");

  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);

  // useEffect that calculates claimPercent
  useEffect(() => {
    // Fetch data and calculate claim percentage
    fetchData(
      `/api/project/${projectId}/phasesplannedhours`,
      (data) => {
        const sanitizedPhases = Array.isArray(data.phases)
          ? data.phases
              .filter(
                (phase) =>
                  phase.phase_id !== null &&
                  phase.title !== null &&
                  phase.task_count !== null
              )
              .map((phase) => {
                return phase;
              })
          : [];

        // Calculate total planned hours in decimal format
        const totalDecimalHours = sanitizedPhases.reduce((total, phase) => {
          // Convert "planned_hours" to hours in decimal format (HH:mm -> hours)
          const [hours, minutes] = phase.planned_hours.split(":").map(Number);
          const decimalHours = hours + minutes / 60;
          return total + decimalHours;
        }, 0);

        // Convert totalDecimalHours back to hh:mm format
        const totalHours = Math.floor(totalDecimalHours); // Get hours
        const totalMinutes = Math.round((totalDecimalHours - totalHours) * 60); // Get minutes

        // Format total planned hours in hh:mm format
        const formattedTotalPlannedHours = `${totalHours
          .toString()
          .padStart(2, "0")}:${totalMinutes.toString().padStart(2, "0")}`;

        // Store formatted total planned hours in the state
        setTotalPlannedHours(formattedTotalPlannedHours);

        // Set the project data with sanitized phases
        setProject({ ...data, phases: sanitizedPhases });
      },
      setError
    ).finally(() => setLoading(false));
    fetchData("/api/status", setStatusOption, setError);
  }, [projectId, showInvoiceDetails, showInvoiceTable]);

  const handleOpenPhaseDialog = () => {
    const defaultPhaseNo = `P${project.phases.length + 1}`;
    const defaultPhasetitle = `Phase${project.phases.length + 1}`;
    setNewPhase({
      project_id: project.id,
      title: defaultPhasetitle,
      plannedhours: 0,
      phaseno: defaultPhaseNo,
    });
    setPhaseDialogOpen(true);
  };

  const handleClosePhaseDialog = () => {
    setPhaseDialogOpen(false);
  };

  const handleAddPhase = async () => {
    const newPhaseData = await addPhase(projectId, newPhase, setError);

    if (newPhaseData) {
      setProject((prev) => {
        // Log the previous phases to ensure it's an array

        const updatedPhases = [
          ...prev.phases,
          {
            phaseno: newPhaseData.phaseno,
            title: newPhaseData.title,
            plannedhours: newPhaseData.plannedhours,
            phase_id: newPhaseData.id,
            status: "Planning",
            invoice_status: "0",
            invoice_count: 0,
          },
        ];

        return {
          ...prev,
          phases: updatedPhases,
        };
      });
    }

    setPhaseDialogOpen(false);
  };

  const handleEditClick = (phase) => {
    setEditingPhase(phase.phase_id);

    setFormValues({
      phaseno: phase.phaseno,
      title: phase.title,
      plannedhours: phase.plannedhours,
    });
    setPhaseEditDialogOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    const { phaseno, title, plannedhours } = formValues;
    const phaseId = editingPhase;
    try {
      const response = await api.put(`/api/phase/${phaseId}`, {
        phaseno,
        title,
        plannedhours,
      });
      // Axios responses don't have .ok, they throw errors automatically
      const updatedPhase = response.data;

      const updatedPhases = project.phases.map((phase) =>
        phase.phase_id === phaseId
          ? {
              ...phase,
              phaseno: updatedPhase.phaseno,
              title: updatedPhase.title,
              plannedhours: updatedPhase.plannedhours,
              id: updatedPhase.phase_id,
            }
          : phase
      );
      setProject((prevProject) => ({
        ...prevProject,
        phases: updatedPhases,
      }));
      setPhaseEditDialogOpen(false);
    } catch (error) {
      // Axios errors are inside error.response
      let errorMessage = "Failed to update phase";
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      }
      console.error(errorMessage);
      console.log(errorMessage);
    }
  };

  const handleUpdatePhaseStatus = async (e, id) => {
    const status = e.target.value;
    const phaseId = id;
    console.log(status, phaseId);
    try {
      const response = await api.put(`/api/phase-stage/${phaseId}`, {
        status,
      });
      // Axios responses don't have .ok, they throw errors automatically
      const updatedPhase = response.data;

      const updatedPhases = project.phases.map((phase) =>
        phase.phase_id === phaseId
          ? {
              ...phase,

              status: updatedPhase.status,
            }
          : phase
      );
      setProject((prevProject) => ({
        ...prevProject,
        phases: updatedPhases,
      }));
      setPhaseEditDialogOpen(false);
    } catch (error) {
      // Axios errors are inside error.response
      let errorMessage = "Failed to update phase";
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      }
      console.error(errorMessage);
      console.log(errorMessage);
    }
  };

  const fetchInvoiceData = (phaseId) => {
    fetchData(
      `/api/phase/invoices/${projectId}/${phaseId}`,
      setInvoices,
      setError
    );
  };

  const handleDeletePhaseClick = (e, phaseId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add phaseId to the selectedPhaseId array
      setSelectedPhaseId((prev) => [...prev, phaseId]);
    } else {
      // Remove phaseId from the selectedPhaseId array
      setSelectedPhaseId((prev) => prev.filter((id) => id !== phaseId));
    }
  };
  //delete phase
  const handleDeleteConfirm = async () => {
    if (isCheckboxChecked) {
      try {
        // Call the deletePhase function with the selected phase IDs
        await deletePhase(selectedPhaseId, setError);
        // Update the state after deletion
        setProject((prev) => {
          // Filter out the phases whose IDs are in the selectedPhaseId array
          const updatedPhases = prev.phases.filter(
            (phase) => !selectedPhaseId.includes(phase.phase_id) // Remove phases whose IDs are in selectedPhaseId
          );

          return {
            ...prev,
            phases: updatedPhases, // Update phases after deletion
          };
        });

        // Close the delete dialog and reset UI states
        setDeleteDialogOpen(false);
        setBulkAction(false);
        setClaimStatus(false);

        setIsCheckboxChecked(false); // Reset the checkbox state
        setSelectedPhaseId([]); // Clear the selected phases after deletion
      } catch (error) {
        console.error("Failed to delete phase:", error); // Log the error for debugging
        setError(error); // Display the error message to the user
      }
    } else {
      toast.error("Please check the checkbox to confirm deletion.", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  //close dial and remove no-scroll
  const handleCloseDial = () => {
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
    setSelectedPhaseId([]);
  };
  const updateInvoice = async (fieldType, ID, newValue) => {
    console.log("Field to update:", fieldType, ID, newValue);

    try {
      const requestData = { fieldType, newValue };

      const response = await api.put(`/api/invoices/${ID}`, requestData);

      if (response.status === 200) {
        console.log("Backend response:", response.data);
        return { success: true, message: response.data.message };
      } else {
        return {
          success: false,
          error: response.statusText || "Unknown error occurred.",
        };
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
      toast.error(
        error.response?.data?.error || error.message || "Something went wrong.",
        {
          position: "bottom-center",
          autoClose: 2000,
        }
      );
      // return {
      //   success: false,
      //   error:
      //     error.response?.data?.error ||
      //     error.message ||
      //     "Something went wrong.",
      // };
    }
  };

  const handleDeleteinvoices = (e, invoice_id) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add phaseId to the selectedPhaseId array
      setSelectedInvoiceId((prev) => [...prev, invoice_id]);
    } else {
      // Remove phaseId from the selectedPhaseId array
      setSelectedInvoiceId((prev) => prev.filter((id) => id !== invoice_id));
    }
  };

  const handleDeleteInvoice = async () => {
    if (isCheckboxChecked) {
      try {
        const url = `/api/invoices`; // Backend expects invoiceIds in the request body
        await api.delete(url, { data: { invoiceIds: selectedInvoiceId } }); // Send array of IDs

        toast.success("Selected invoice(s) deleted successfully", {
          position: "bottom-center",
          autoClose: 2000,
        });

        // Update state by removing deleted invoices
        setInvoices((prev) => {
          if (!prev || !prev.invoices) return prev; // Ensure state exists
          const updatedInvoices = prev.invoices.filter(
            (inv) => !selectedInvoiceId.includes(inv.id) // Remove deleted invoices
          );
          return {
            ...prev,
            invoices: updatedInvoices, // Correct property name
          };
        });

        // Reset UI states
        setDeleteInvoiceDialogOpen(false);
        setIsCheckboxChecked(false);
        setSelectedInvoiceId([]); // Clear selected invoices
      } catch (error) {
        if (error.response) {
          // Handle server response error
          const serverError = error.response.data;
          console.error("Server error:", serverError);
          setError(
            serverError.message ||
              "An error occurred while deleting invoice. Please try again."
          );
          alert(
            serverError.message ||
              "An error occurred while deleting invoice. Please try again."
          );
        } else {
          // Generic error handling
          console.error("Unexpected error:", error);
          setError("Unexpected error occurred. Please try again.");
        }
      }
    } else {
      toast.error("Please check the checkbox to confirm deletion.", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const updateClaimStatus = async (phaseId, newValue, fieldType) => {
    console.log(phaseId, newValue, fieldType, userId);
    try {
      if (fieldType === "status" && newValue === "Unclaimed") {
        const confirmUnclaim = window.confirm(
          "Are you sure you want to change the status to Unclaimed?"
        );
        if (!confirmUnclaim) return;
      }

      let requestData = {};

      if (fieldType === "status") {
        requestData = { status: newValue };
      } else if (fieldType === "date") {
        requestData = { date: newValue ? newValue.toISOString() : null };
      } else if (fieldType === "number") {
        requestData = { invoice_no: newValue || null }; // Allow null for empty
        setProject((prev) => {
          const updatedPhases = prev.phases.map((phase) =>
            phase.phase_id === phaseId
              ? { ...phase, invoice_no: newValue }
              : phase
          );
          return { ...prev, phases: updatedPhases };
        });
      } else {
        console.error("Invalid field type for updateClaimStatus.");
        return;
      }

      const response = await api.put(
        `/api/phase/${phaseId}/${userId}/approval`,
        requestData
      );

      if (response.status === 200) {
        console.log("Backend response:", response.data);
        fetchData(
          `/api/project/${projectId}/phasesplannedhours`,
          (data) => {
            const sanitizedPhases = Array.isArray(data.phases)
              ? data.phases.filter(
                  (phase) =>
                    phase.phase_id !== null &&
                    phase.title !== null &&
                    phase.task_count !== null
                )
              : [];
            setProject({ ...data, phases: sanitizedPhases });
          },
          setError
        ).finally(() => {
          setLoading(false);
          console.log("Loading complete");
        });
      } else {
        console.error(
          "Failed to update phase:",
          response.statusText || "No status text"
        );
      }
    } catch (error) {
      console.error("Error updating phase:", error);
    }
  };

  const updateBulkClaimStatus = async (phaseIds, newStatus) => {
    try {
      // If the new status is "Unclaimed", show confirmation
      if (newStatus === "Unclaimed") {
        const confirmUnclaim = window.confirm(
          "Are you sure you want to change the status of selected phases to Unclaimed?"
        );

        // If user clicks "No", stop the function
        if (!confirmUnclaim) {
          return; // Exit without making changes
        }
      }

      const response = await api.put("/api/phases/batch-update", {
        phaseIds,
        status: newStatus,
      });

      if (response.status === 200) {
        alert(response.data.message); // Show success message
        // Optionally, refresh data
        fetchData(
          `/api/project/${projectId}/phasesplannedhours`,
          (data) => {
            const sanitizedPhases = Array.isArray(data.phases)
              ? data.phases
                  .filter(
                    (phase) =>
                      phase.phase_id !== null &&
                      phase.title !== null &&
                      phase.task_count !== null
                  )
                  //actualhours calculation starts
                  .map((phase) => {
                    return phase;
                  })
              : //actualhours calculation ends
                [];
            setProject({ ...data, phases: sanitizedPhases });
          },
          setError
        );
        setBulkAction(false);
        setSelectedPhaseId([]);
        setClaimStatus(false);
      } else {
        toast.error(response.data.message, {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Failed to update bulk statuses", error);
      alert(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setBulkAction(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  const totalPages = Math.ceil(project.phases.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  const currentProjects = project.phases.slice(
    indexOfFirstUsers,
    indexOfLastUsers
  );

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };

  const allUnclaimed = useMemo(() => {
    return project.phases.some((phase) => phase.invoice_status > 0);
  }, [project.phases]);

  const isPhaseClaimed = useMemo(() => {
    return invoices?.invoices?.length > 0
      ? invoices.invoices.some((phase) => Number(phase.claimpercent) === 100)
      : false;
  }, [invoices?.invoices]);

  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error)
    return (
      <p color="error" className="error-message">
        Error: {error.message}
      </p>
    );
  const handleInvoiceSubmit = async (invoiceDetails, percentage) => {
    console.log("Submitting Invoice", invoiceDetails, percentage);
    try {
      const response = await api.post("/api/invoices", invoiceDetails);
      if (response.status === 201) {
        toast.success("New Invoice added", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
      setPercentage("");
      setInvoiceDate("");
      setInvoiceNo("INV-25-");
      fetchInvoiceData(invoiceDetails.phase_id);
      setShowInvoiceDetails(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Failed to add invoice.";
      toast.error(`Error: ${errorMessage}`, {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const convertToMinutes = (timeString) => {
    if (!timeString) return 0;
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  function validatePercentageSum(phaseId, newPercentage) {
    const totalPercentage = project.phases
      .filter((p) => p.phase_id === phaseId)
      .reduce((sum, p) => sum + (p.invoice_percentage || 0), 0);
    return totalPercentage + newPercentage <= 100;
  }
  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <ToastContainer />
      {!dialBoxopen && (
        <div
          className={
            isNavBarOpen
              ? "project-phase-details"
              : "project-phase-details-close"
          }
        >
          <div style={{ marginLeft: "103px" }}>
            <div>
              {selectedPhaseId.length > 0 && (
                <button
                  onClick={() => setBulkAction((prev) => !prev)}
                  className="add-btn mass-update-phase"
                >
                  Mass Update
                </button>
              )}
              {hasPermission("phases", "create") && (
                <button
                  onClick={handleOpenPhaseDialog}
                  className={
                    selectedPhaseId.length > 0
                      ? "add-phase-btn"
                      : "add-phase-btn-close"
                  }
                  title="Add Phase"
                >
                  New Phase
                </button>
              )}
              {project.phases.length === 0 ? (
                <div className="phase-image-container">
                  <img className="phase-image" src="/AppLayout/Phase.png" />
                  <p className="phase-message">
                    No phases available for this project
                  </p>
                </div>
              ) : (
                <>
                  <div
                    className={
                      isNavBarOpen ? "table-container" : "table-container-close"
                    }
                  >
                    <table
                      className={
                        isNavBarOpen ? "phase-table" : "phase-table-close"
                      }
                    >
                      <thead>
                        <tr>
                          {hasPermission("phases", "delete") && (
                            <th style={{ width: "2px", textAlign: "center" }}>
                              <FaChevronDown />
                            </th>
                          )}
                          <th
                            style={{
                              color: "#616161",
                              color: "black",
                              width: "145px",
                            }}
                          >
                            Phase No
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              color: "black",
                              width: "145px",
                            }}
                          >
                            Phase Title
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              color: "black",
                              width: "120px",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              color: "black",
                              width: "100px",
                            }}
                          >
                            Planned Hrs
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              color: "black",
                              width: "100px",
                            }}
                          >
                            Actual Hrs
                          </th>
                          {hasPermission("projects", "update") && (
                            <th
                              style={{
                                color: "#616161",
                                color: "black",
                                width: "25px",
                                textAlign: "center",
                              }}
                            >
                              %
                            </th>
                          )}
                          {hasPermission("projects", "update") && (
                            <th
                              style={{
                                color: "#616161",
                                color: "black",
                                width: "55px",
                              }}
                            >
                              Invoices
                            </th>
                          )}
                          {/* {allUnclaimed &&
                            hasPermission("projects", "update") && (
                              <>
                                <th style={{ width: "110px" }}>Invoice No</th>
                                <th style={{ width: "110px" }}>Invoice Date</th>
                              </>
                            )} */}
                          <th style={{ width: "20px" }}>No of VO(UC)</th>
                          <th style={{ width: "50px" }}>VO Bhrs(UC)</th>
                          <th style={{ width: "20px" }}>No of VO</th>
                          <th style={{ width: "40px" }}>VO Bhrs</th>
                          <th style={{ width: "40px" }}>VO AHrs</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {currentProjects.map((phase, index) => (
                          <tr key={phase.id}>
                            {hasPermission("phases", "delete") && (
                              <td>
                                <input
                                  style={{ cursor: "pointer" }}
                                  type="checkbox"
                                  name={phase.name}
                                  checked={selectedPhaseId.includes(
                                    phase.phase_id
                                  )}
                                  onChange={(e) =>
                                    handleDeletePhaseClick(e, phase.phase_id)
                                  }
                                />
                              </td>
                            )}
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  whiteSpace: "nowrap",
                                  minwidth: "140px",
                                  maxwidth: "100%",
                                }}
                              >
                                <Link
                                  to={`/project/${projectId}/phase/${phase.phase_id}`}
                                  className="phase-tile"
                                >
                                  {phase.phaseno}
                                </Link>
                                {hasPermission("phases", "update") && (
                                  <button
                                    onClick={() => {
                                      handleEditClick(phase);
                                    }}
                                    className="edit-icon-wrapper"
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                    }}
                                    title="Edit Phase"
                                  >
                                    <EditIcon
                                      style={{
                                        color: "blue",
                                        backgroundColor: "transparent",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </button>
                                )}
                              </div>
                            </td>
                            <td
                              style={{
                                minwidth: "auto",
                                maxwidth: "100%",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {phase.title}
                            </td>
                            <td
                              style={{
                                minwidth: "auto",
                                maxwidth: "100%",
                                whiteSpace: "nowrap",
                                padding: "0px",
                              }}
                            >
                              <select
                                value={phase.status}
                                className="phase-stage-dropdown"
                                style={{
                                  backgroundColor:
                                    statusOptions.find(
                                      (s) => s.name === phase.status
                                    )?.color || "white",
                                  cursor: hasPermission("projects", "update")
                                    ? "pointer"
                                    : "not-allowed",
                                }}
                                onChange={(e) =>
                                  handleUpdatePhaseStatus(e, phase.phase_id)
                                }
                                disabled={!hasPermission("projects", "update")}
                              >
                                <option value={""} disabled>
                                  Select Status
                                </option>
                                {Array.isArray(statusOptions) &&
                                  statusOptions.map((status) => (
                                    <option key={status.id} value={status.name}>
                                      {status.name}
                                    </option>
                                  ))}
                              </select>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Link
                                to={`/project/${projectId}/phase/${phase.phase_id}`}
                                className="phase-tile"
                                onClick={() =>
                                  localStorage.setItem("tabIndex", 0)
                                }
                              >
                                {phase.planned_hours !== "00:00"
                                  ? phase.planned_hours
                                  : ""}
                              </Link>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color:
                                  convertToMinutes(phase.actualhours) <
                                  convertToMinutes(phase.planned_hours)
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {phase.actualhours !== "00:00"
                                ? phase.actualhours
                                : ""}
                            </td>
                            {hasPermission("projects", "update") && (
                              <td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "700",
                                  color:
                                    Number(phase.invoice_status) === 100
                                      ? "#40af48"
                                      : "orange",
                                }}
                              >
                                {phase.invoice_status === 0
                                  ? ""
                                  : phase.invoice_status}
                              </td>
                            )}
                            {hasPermission("projects", "update") && (
                              <td>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    opacity:
                                      Number(phase.invoice_status) === 100
                                        ? 0.5
                                        : 1,
                                  }}
                                  onClick={() => {
                                    if (hasPermission("projects", "update")) {
                                      fetchInvoiceData(phase.phase_id);
                                      setShowInvoiceTable(true);
                                      setPhaseId(phase.phase_id);
                                    }
                                  }}
                                >
                                  {phase.invoice_count === 0 ? (
                                    <LiaFileInvoiceSolid className="invoice-icon" />
                                  ) : (
                                    `Count :${phase.invoice_count || ""}`
                                  )}
                                </span>
                              </td>
                            )}
                            {/* {allUnclaimed &&
                              hasPermission("projects", "update") && (
                                <>
                                  <td>{phase.invoice_no || ""}</td>
                                  <td>
                                    {phase.invoice_date
                                      ? format(
                                          new Date(phase.invoice_date),
                                          "dd-MM-yyyy"
                                        )
                                      : ""}
                                  </td>
                                </>
                              )} */}
                            <td style={{ textAlign: "center" }}>
                              <Link
                                onClick={() =>
                                  localStorage.setItem("tabIndex", 1)
                                }
                                to={`/project/${projectId}/phase/${phase.phase_id}`}
                                className="phase-tile"
                              >
                                {phase.unclaimed_variation_count !== 0 &&
                                  phase.unclaimed_variation_count}
                              </Link>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {phase.unclaimed_variation_planned_hours !==
                                "00:00" &&
                                phase.unclaimed_variation_planned_hours}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {phase.variation_count !== 0 &&
                                phase.variation_count}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {phase.total_variation_planned_hours !==
                                "00:00" && phase.total_variation_planned_hours}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color:
                                  convertToMinutes(
                                    phase.total_variation_actual_hours
                                  ) <
                                  convertToMinutes(
                                    phase.total_variation_planned_hours
                                  )
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {phase.total_variation_actual_hours !== "00:00" &&
                                phase.total_variation_actual_hours}
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className={
                      isNavBarOpen
                        ? "phase-hours-summary"
                        : "phase-hours-summary-close"
                    }
                  >
                    <h3
                      className={
                        isNavBarOpen
                          ? "phase-details-text"
                          : "phase-details-text-close"
                      }
                    >
                      Phase Planned Hours Summary : {totalPlannedHours}
                    </h3>
                    <div
                      className={
                        isNavBarOpen
                          ? "phase-pagination"
                          : "phase-pagination-close"
                      }
                    >
                      <h3 className="pagination-total">
                        Total : {project.phases.length}
                      </h3>
                      <button
                        onClick={handleFirstPage}
                        disabled={currentPage === 1}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardDoubleArrowLeft />
                      </button>
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="phase-arrow-btn"
                      >
                        {/* */}
                        <MdKeyboardArrowLeft />
                      </button>
                      <span
                        style={{
                          marginTop: "-15px",
                          textWrap: "nowrap",
                          backgroundColor: "transparent",
                        }}
                      >
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardArrowRight />
                      </button>
                      <button
                        onClick={handleLastPage}
                        disabled={currentPage === totalPages}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardDoubleArrowRight />
                      </button>
                    </div>
                  </div>
                </>
              )}
              {bulkAction && (
                <div className="action-box" ref={profileRef}>
                  {hasPermission("phases", "delete") && (
                    <button
                      onClick={() => setDeleteDialogOpen(true)}
                      title="Delete Phase"
                      className="add-btn"
                      style={{ backgroundColor: "Red" }}
                    >
                      Delete
                    </button>
                  )}
                  {/* {hasPermission("phases", "update") && (
                    <button
                      onClick={() => setClaimStatus((prev) => !prev)}
                      className="add-btn"
                      style={{ textWrap: "nowrap" }}
                    >
                      Claim Status
                    </button>
                  )}
                  {claimStatus && hasPermission("phases", "update") && (
                    <select
                      onChange={(e) =>
                        updateBulkClaimStatus(selectedPhaseId, e.target.value)
                      }
                      className="bulk-select"
                    >
                      <option value={""}>Select percentage</option>
                      {[...Array(11)].map((_, index) => {
                        const value = index * 10;
                        return (
                          <option
                            key={value}
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              fontWeight: "500",
                            }}
                            value={value}
                          >
                            {value}%
                          </option>
                        );
                      })}
                    </select>
                  )} */}
                </div>
              )}
              {/* Add Phase Dialog */}
              {phaseDialogOpen && (
                <div className="add-variation-dial">
                  <div className="dial-v-header">
                    <h3 className="add-edit-variation">New Phase</h3>
                  </div>

                  <div className="add-phase-content">
                    <h3 className="add-phase-heading">Phase No</h3>
                    <input
                      label="Phase Title"
                      className="phase-input"
                      value={newPhase.phaseno}
                      type="text"
                      onChange={(e) =>
                        setNewPhase({ ...newPhase, phaseno: e.target.value })
                      }
                    />
                    <h3 className="add-phase-heading">Phase Title</h3>
                    <input
                      label="Phase No"
                      className="phase-input"
                      value={newPhase.title}
                      type="text"
                      onChange={(e) =>
                        setNewPhase({ ...newPhase, title: e.target.value })
                      }
                    />
                  </div>
                  <div className="btn-con-second">
                    <button
                      onClick={handleAddPhase}
                      style={{ marginLeft: "26px", width: "50px" }}
                      className="add-phase-dial-btn"
                    >
                      Add
                    </button>
                    <button
                      onClick={handleClosePhaseDialog}
                      className="cancel-btn"
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {deleteDialogOpen && (
        <div className={`delete-phase-dial ${dialBoxopen ? "open" : "close"} `}>
          <div className="delete-dial">
            <div className="dial-d-header">
              <h3
                style={{ backgroundColor: "transparent", marginLeft: "15px" }}
              >
                Delete Phase
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p style={{ backgroundColor: "white" }}>
                Are you sure you want to delete this phase? This action cannot
                be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                id="delete-phase"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked((prev) => !prev)}
              />
              <label
                htmlFor="delete-phase"
                style={{ backgroundColor: "white", cursor: "pointer" }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-dial-btn-con">
              <button
                onClick={handleDeleteConfirm}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                className="add-btn"
                style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
              >
                Delete
              </button>
              <button onClick={handleCloseDial} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {phaseEditDialogOpen && editingPhase && (
        <div className="add-variation-dial">
          <div className="dial-v-header">
            <h3 className="add-edit-variation">Edit Phase Details</h3>
          </div>
          <div className="edit-phase-content">
            <h3 className="issue-label">Phase No</h3>
            <input
              type="text"
              className="issue-number"
              name="phaseno"
              value={formValues.phaseno}
              onChange={handleInputChange}
            />

            <h3 className="issue-label">Phase Title</h3>
            <input
              type="text"
              className="issue-number"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
            />
          </div>
          <div className="edit-btn-con-second">
            <button className="add-edit-issue-btn" onClick={handleSaveClick}>
              Save
            </button>
            <button
              className="cancel-btn"
              style={{ marginRight: "140px", marginLeft: "0px" }}
              onClick={() => setPhaseEditDialogOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {showInvoiceTable && invoices && (
        <div className="invoice-table-container">
          <div className="phase-invoice-table-con">
            <div className="invoice-project-details">
              <h3
                style={{ marginRight: "25px" }}
                className="invoice-details-header"
              >
                Project : {invoices?.projectno}
              </h3>
              <h3 className="invoice-details-header">
                Phase : {invoices?.phase_no} - {invoices?.phase_name}
              </h3>
              <h3 style={{ marginLeft: "20px" }}> Claim % : {invoiceTotal}</h3>
              {selectedInvoiceId.length > 0 && (
                <button
                  className="delete-invoice-btn"
                  onClick={() => setDeleteInvoiceDialogOpen(true)}
                >
                  Delete
                </button>
              )}
            </div>
            <div className="phase-invoice-table-2">
              <table className="phase-invoice-table">
                <thead>
                  <tr>
                    <th>
                      <FaChevronDown />{" "}
                    </th>
                    <th style={{ width: "20px" }}>SNo</th>
                    <th style={{ width: "140px" }}>Invoice No</th>
                    <th>Date</th>
                    <th style={{ width: "25px" }}>%</th>
                    <th style={{ width: "220px" }}>Updated By</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices?.invoices && invoices.invoices.length > 0 ? (
                    invoices.invoices.map((invoice, index) => (
                      <tr key={invoice.id}>
                        <td>
                          <input
                            style={{ cursor: "pointer" }}
                            type="checkbox"
                            name={invoice.id}
                            checked={selectedInvoiceId.includes(invoice.id)}
                            onChange={(e) =>
                              handleDeleteinvoices(e, invoice.id)
                            }
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ padding: "0px" }}>
                          <input
                            className="invoice-no-input"
                            value={invoice.invoiceno || ""}
                            onChange={(e) => {
                              setInvoices((prev) => {
                                return {
                                  ...prev,
                                  invoices: prev.invoices.map((inv) =>
                                    inv.id === invoice.id
                                      ? { ...inv, invoiceno: e.target.value }
                                      : inv
                                  ),
                                };
                              });
                            }}
                            onBlur={(e) =>
                              updateInvoice(
                                "invoiceno",
                                invoice.id,
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          {/* {format(invoice.date, "dd-MM-yyyy")} */}
                          <DatePicker
                            className="enter-invoice-details-date-table"
                            selected={
                              invoice.date
                                ? typeof invoice.date === "string"
                                  ? parseISO(invoice.date) // Parse only if it's a string
                                  : invoice.date // Use directly if it's already a Date object
                                : null // Return null instead of an empty string
                            }
                            onChange={(date) => {
                              // Normalize the date to midnight in the local timezone (IST)
                              const normalizedDate = new Date(date); // Ensure it's a Date object
                              normalizedDate.setHours(0, 0, 0, 0); // Set time to midnight to avoid time zone issues

                              updateInvoice("date", invoice.id, normalizedDate); // Send the normalized date to backend
                              setInvoices((prev) => {
                                return {
                                  ...prev,
                                  invoices: prev.invoices.map((inv) =>
                                    inv.id === invoice.id
                                      ? { ...inv, date: normalizedDate }
                                      : inv
                                  ),
                                };
                              });
                            }}
                            dateFormat="dd-MM-yyyy"
                            required
                          />
                        </td>
                        <td style={{ padding: "5px" }}>
                          <input
                            type="number"
                            className="invoice-percentage-input-dial"
                            value={invoice.claimpercent}
                            onChange={(e) => {
                              setInvoices((prev) => {
                                return {
                                  ...prev,
                                  invoices: prev.invoices.map((inv) =>
                                    inv.id === invoice.id
                                      ? { ...inv, claimpercent: e.target.value }
                                      : inv
                                  ),
                                };
                              });
                            }}
                            onBlur={(e) =>
                              updateInvoice(
                                "claimpercent",
                                invoice.id,
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>{invoice.invoiced_by_name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No invoices available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div style={{ marginTop: "10px", fontWeight: "bold" }}></div>
            </div>
            <form
              className="invoice-input-dial"
              onSubmit={(e) => {
                e.preventDefault();
                const invoiceDetails = {
                  invoicno: invoiceNo,
                  date: invoiceDate,
                  invoiced_by: userId,
                  project_id: projectId,
                  phase_id: phaseId,
                  claimpercent: Number(percentage),
                };
                handleInvoiceSubmit(invoiceDetails, percentage);
              }}
            >
              <div
                style={{ marginTop: "10px", backgroundColor: "transparent" }}
              >
                <label style={{ marginLeft: "10px" }}>
                  Invoice No:
                  <input
                    className="enter-invoice-details-input"
                    type="text"
                    required
                    value={invoiceNo}
                    onChange={(e) => setInvoiceNo(e.target.value)}
                  />
                </label>
                <label style={{ marginLeft: "10px", position: "fixed" }}>
                  Date:
                  <DatePicker
                    className="enter-invoice-details-date"
                    selected={invoiceDate}
                    onChange={(date) => setInvoiceDate(date)}
                    dateFormat="dd-MM-yyyy"
                    required
                  />
                </label>
                <label className="invoice-percentage-input">
                  % :
                  <input
                    className="enter-invoice-details-input"
                    style={{ marginLeft: "8px", width: "40px" }}
                    type="text"
                    required
                    value={percentage}
                    onChange={(e) => setPercentage(e.target.value)}
                  />
                </label>
              </div>

              <button
                className="add-btn invoice-details-button"
                type="submit"
                style={{
                  backgroundColor: invoiceTotal === 100 ? "grey" : "#6200ee",
                }}
                disabled={invoiceTotal === 100}
              >
                Add
              </button>
              <button
                className="cancel-btn invoice-details-can-button"
                onClick={() => {
                  setShowInvoiceTable(false);
                  setPercentage("");
                  setInvoiceDate("");
                  setInvoiceNo("INV-25-");
                }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      {deleteInvoiceDialogOpen && (
        <div className={`delete-phase-dial ${dialBoxopen ? "open" : "close"} `}>
          <div className="delete-dial">
            <div className="dial-d-header">
              <h3
                style={{ backgroundColor: "transparent", marginLeft: "15px" }}
              >
                Delete Invoice
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p style={{ backgroundColor: "white" }}>
                Are you sure you want to delete this Invoice ? This action
                cannot be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                id="delete-phase"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked((prev) => !prev)}
              />
              <label
                htmlFor="delete-phase"
                style={{ backgroundColor: "white", cursor: "pointer" }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-dial-btn-con">
              <button
                onClick={handleDeleteInvoice}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                className="add-btn"
                style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
              >
                Delete
              </button>
              <button
                onClick={() => setDeleteInvoiceDialogOpen(false)}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
