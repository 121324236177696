import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import api from "../../api/axiosConfig";
import "./UserDetails.css";
import { ProjectContext } from "../../App";
import { IoMdClose } from "react-icons/io";
import { useAuth } from "../Authentication/AuthContext";

const UserDetails = () => {
  const { isNavBarOpen } = useContext(ProjectContext);
  const { id: userID } = useParams();
  const [user, setUser] = useState(null);
  const { userDetails } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(null);
  const [pictureDial, setPictureDial] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [previewPicture, setPreviewPicture] = useState(null);

  useEffect(() => {
    const endpoint =
      userDetails.role_name === "Administrator"
        ? `/api/settings/users/${userID}`
        : `/api/settings/users/${userDetails?.id}`;
    console.log("endpoint", endpoint);
    const fetchData = async () => {
      try {
        const response = await api.get(endpoint);
        setUser(response.data[0]); // Assuming response is an array with a single user
        setUpdatedUser(response.data[0]); // Clone data for editing
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    if (userDetails?.id || userID) {
      fetchData();
    }
  }, [userDetails?.id, userID, userDetails.role_name]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const endpoint = `/api/settings/users/${user.id}`;
    try {
      await api.put(endpoint, updatedUser); // Update user details in the backend
      setUser(updatedUser); // Update the user state with new data
      setIsEditing(false);
      alert("User details updated successfully.");
    } catch (error) {
      console.error("Error updating user details:", error);
      alert("Failed to update user details.");
    }
  };
  const handleUploadPicture = async (e) => {
    e.preventDefault();
    if (!selectedPicture) {
      alert("Please select a picture to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("profilePicture", selectedPicture);
    const endpoint =
      userDetails.role_name === "Administrator"
        ? `/api/users/${userID}/upload-picture`
        : `/api/users/${userDetails.id}/upload-picture`;
    try {
      const response = await api.post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        alert("Profile picture uploaded successfully.");
        setPictureDial(false);
        setSelectedPicture(null);
        setPreviewPicture(null);
        window.location.reload();
        // Optionally refresh user details to display the new picture
      } else {
        throw new Error("Failed to upload the profile picture.");
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };
  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedPicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewPicture(reader.result); // Display the image preview
      };
      reader.readAsDataURL(file);
    }
  };
  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error) return <p>Error loading user details.</p>;

  return (
    <div
      className={
        isNavBarOpen
          ? "user-p-details-container"
          : "user-p-details-container-close"
      }
    >
      <h2>User Details</h2>
      <Link
        to={userDetails.role_name === "Administrator" ? "/settings" : "/home"}
      >
        <IoMdClose className="user-details-exit-arrow" />
      </Link>
      {user && (
        <div className="user-details">
          {user.picture && user.picture !== "null" ? (
            <img
              src={
                user.picture
                  ? `${api.defaults.baseURL}${user.picture}`
                  : "/default-avatar.png"
              }
              alt={`${user.name}'s Profile`}
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                marginBottom: "20px",
              }}
              onClick={() => setPictureDial((prev) => !prev)}
              title="Change Profile Picture"
            />
          ) : (
            <img
              alt="profile-picture"
              src="/uploads/profile-pictures/default.png"
              onClick={() => setPictureDial((prev) => !prev)}
              title="Add Profile Picture"
            />
          )}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "none",
              borderRadius: "3px",
            }}
          >
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold", width: "150px" }}>Name:</td>
                <td>{user.name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Email:</td>
                <td>
                  {isEditing ? (
                    <input
                      type="email"
                      name="email"
                      value={updatedUser.email}
                      onChange={handleInputChange}
                      className="user-details-element"
                      placeholder="User Email"
                    />
                  ) : (
                    user.email
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Designation:</td>
                <td>{user.designation}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Employee Code:</td>
                <td>{user.employeecode}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Gender:</td>
                <td>
                  {isEditing ? (
                    <select
                      name="gender"
                      value={updatedUser.gender}
                      onChange={handleInputChange}
                      className="gender-dropdown"
                    >
                      <option value="" disabled>
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  ) : (
                    user.gender
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Phone:</td>
                <td>
                  {isEditing ? (
                    <input
                      type="tel"
                      name="phone"
                      value={updatedUser.phone}
                      onChange={handleInputChange}
                      className="user-details-element"
                      placeholder="User Phone Number"
                    />
                  ) : (
                    user.phone
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Role:</td>
                <td>{user.role_name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Status:</td>
                <td>{user.status}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Groups:</td>
                <td>
                  {user.groups.length > 0 ? (
                    <ul>
                      {user.groups.map((group, index) => (
                        <li key={index}>
                          {group.name} ({group.code}) - {group.description}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "No groups assigned"
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Projects:</td>
                <td>
                  {user.projects.length > 0 ? (
                    <ul style={{ overflow: "auto" }}>
                      {user.projects.map((project, index) => (
                        <li key={index}>
                          {project.name} ({project.number})
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "No projects assigned"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ marginTop: "20px" }}>
            {isEditing ? (
              <>
                <button
                  onClick={handleSave}
                  style={{ marginRight: "3px", marginLeft: "2px" }}
                  className="add-btn"
                >
                  Save
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className="cancel-btn"
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                onClick={() => setIsEditing(true)}
                className="add-btn"
                style={{ marginLeft: "2px" }}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      )}
      {pictureDial && (
        <div className="Upload-Profile-Picture-dial">
          <div className="Upload-Profile-Picture">
            <div className="Upload-Profile-header">
              <h3 className="password-header">Upload Profile Picture</h3>
            </div>
            <form onSubmit={handleUploadPicture} className="picture-form">
              <label className="password-element">
                Select Profile Picture:
              </label>
              <input
                type="file"
                accept="image/*"
                className="upload-input"
                onChange={handlePictureChange}
                required
              />
              {previewPicture && (
                <img
                  src={previewPicture}
                  alt="Preview"
                  className="preview-picture"
                />
              )}
              <div
                className={
                  !previewPicture
                    ? "picture-btn-container"
                    : "picture-btn-container-2"
                }
              >
                <button className="add-btn upload-btn" type="submit">
                  Upload
                </button>
                <button
                  className="cancel-btn"
                  onClick={() => {
                    setPreviewPicture(null);
                    setPictureDial(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
