import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { ProjectContext } from "../../App";
import { useRolePermissions } from "../Settings/RoleContext";
import { useAuth } from "../Authentication/AuthContext";
import api from "../../api/axiosConfig";
import { IoMdClose } from "react-icons/io";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EstimateTaskTable from "./EstimateTaskTable";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await api.get(url); // Axios automatically adds the baseURL and headers
    setData(response.data); // Axios automatically parses JSON
  } catch (error) {
    console.error("Error fetching data:", error);
    setError(error); // Handle error
  }
};

const EstimateTasks = () => {
  const { estimateId, phaseId } = useParams();
  const { hasPermission } = useRolePermissions();
  const { userDetails } = useAuth();
  const userID = userDetails.id;
  const { isNavBarOpen } = useContext(ProjectContext);
  const [estimate, setEstimate] = useState({});
  const [error, setError] = useState(null); // To handle errors
  const [loading, setLoading] = useState(true); // To show loading state
  const [success, setSuccess] = useState(false);
  const [templates, setTemplates] = useState([]); // State to store templates
  const [users, setUsers] = useState([]);
  const [estimatedBy, setEstimatedBy] = useState(userID);
  const [selectedTemplates, setSelectedTemplates] = useState([]); // State to store selected template IDs
  const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [detailingNo, setDetailingNo] = useState(false);
  const [designNo, setDesignNo] = useState(false);
  const [calculate, SetCalculate] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [tasksCalculation, setTasksCalculation] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [exclusion, setExclusion] = useState([]);
  const [revision, setRevision] = useState([]);
  const [showDesign, setShowDesign] = useState(false);
  const [showDetailing, setShowDetailing] = useState(false);
  const [reviseDesign, setReviseDesign] = useState(false);
  const [reviseDetailing, setReviseDetailing] = useState(false);
  const [alertMessageForDesign, SetAlertMessageForDesign] = useState(false);
  const [alertMessageForDetailing, SetAlertMessageForDetailing] =
    useState(false);

  useEffect(() => {
    if (reviseDesign) {
      // Find the "Design" template (template_name === "Design")
      const designTemplate = Object.values(estimate).find(
        (template) => template.template_name === "Design"
      );
      if (designTemplate) {
        const {
          template_id,
          template_name,
          estimated_by,
          estimateid,
          phase_id, // Ensure phase_id exists in the object or set it manually if needed
          tasks,
        } = designTemplate;

        // Prepare the payload
        const payload = {
          tasktemplate_id: template_id,
          template_name,
          estimated_by,
          revised_by: userID,
          estimate_id: estimateid,
          phase_id,
          tasks,
        };

        // Send the data to the backend only for the "Design" template
        api
          .post("/api/estimates/reviseDesign", payload)
          .then((response) => {
            toast.success("Estimate revised successfully!", {
              position: "bottom-center",
              autoClose: 2000,
            });
            setShowDesign(true);
            fetchTaskData();
            fetchRevisionTaskData();
            setReviseDesign(false);
            SetAlertMessageForDesign(false);
            setIsCheckboxChecked(false);
          })
          .catch((error) => {
            console.error("Error sending data:", error);
          });
      }
    }

    if (reviseDetailing) {
      // Find the "Design" template (template_name === "Design")
      const designTemplate = Object.values(estimate).find(
        (template) => template.template_name === "Detailing"
      );

      if (designTemplate) {
        const {
          template_id,
          template_name,
          estimated_by,
          estimateid,
          phase_id, // Ensure phase_id exists in the object or set it manually if needed
          tasks,
        } = designTemplate;

        // Prepare the payload
        const payload = {
          tasktemplate_id: template_id,
          template_name,
          estimated_by,
          revised_by: userID,
          estimate_id: estimateid,
          phase_id,
          tasks,
        };

        // Send the data to the backend only for the "Design" template
        api
          .post("/api/estimates/reviseDesign", payload)
          .then((response) => {
            toast.success("Estimate revised successfully!", {
              position: "bottom-center",
              autoClose: 2000,
            });
            fetchTaskData();
            setShowDetailing(true);
            fetchRevisionTaskData();
            setReviseDetailing(false);
            setIsCheckboxChecked(false);
            SetAlertMessageForDetailing(false);
          })
          .catch((error) => {
            console.error("Error sending data:", error);
          });
      }
    }
  }, [reviseDesign, reviseDetailing, estimate]);
  useEffect(() => {
    const updateSaveStatus = async (
      value,
      template_name,
      phase_id,
      estimate_id
    ) => {
      try {
        const response = await api.put("/api/update-save-status", {
          value,
          template_name,
          phase_id,
          estimate_id,
        });
        console.log(response.data.message); // Log success message
      } catch (error) {
        console.error(
          "Error updating save status:",
          error.response?.data || error.message
        );
      }
    };
    if (detailingNo) {
      // fetchData();
      setDetailingNo(false);
      updateSaveStatus("No", "Detailing", phaseId, estimateId);
    }
    if (designNo) {
      // fetchData();
      setDesignNo(false);
      updateSaveStatus("No", "Design", phaseId, estimateId);
    }
    // fetchData();
  }, [detailingNo, designNo]);
  let groupedData;
  if (estimate.length > 0) {
    groupedData = Object.values(estimate).reduce((acc, template) => {
      const { template_id, template_name, tasks } = template;

      // Initialize group for this template
      if (!acc[template_id]) {
        acc[template_id] = {
          template_name,
          tasksByCode: {}, // To group tasks by code
        };
      }

      // Group tasks by their code
      tasks.forEach((task) => {
        const { code } = task;

        if (!acc[template_id].tasksByCode[code]) {
          acc[template_id].tasksByCode[code] = [];
        }

        acc[template_id].tasksByCode[code].push(task);
      });

      return acc;
    }, {});
  }

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess(false);
        setError(false);
      }, 3000);

      // Clean up the timeout when the component unmounts or if success changes
      return () => clearTimeout(timer);
    }
  }, [success, error]);

  const fetchTaskData = async () => {
    try {
      const response = await api.get(
        `/api/grouped-estimate-tasks/${estimateId}/${phaseId}`
      );
      const exclusionresponse = await api.get(
        `/api/grouped-estimate-exclusion/${estimateId}/${phaseId}`
      );
      const taskCalculationresponse = await api.get(
        `/api/taskshours/${estimateId}/${phaseId}`
      );
      const data = response.data;
      setTasks(data.tasks);
      setTasksCalculation(taskCalculationresponse.data);
      setProjectDetails(data.projectDetails);
      setExclusion(exclusionresponse.data);
      // setRevision(revisionResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchRevisionTaskData = async () => {
    try {
      const revisionResponse = await api.get(
        `/api/estimatesrevision/${estimateId}/${phaseId}`
      );
      setRevision(revisionResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchRevisionTaskData();
    fetchTaskData();
  }, []);

  const fetchAllData = async () => {
    setLoading(true);

    // Fetch estimate tasks and templates in parallel
    await Promise.all([
      fetchData(
        `/api/estimate/${estimateId}/phase/${phaseId}/tasks`,
        setEstimate,
        setError
      ),
      fetchData(`/api/estimatestasktemplates`, setTemplates, setError),
      fetchData(`/api/users`, setUsers, setError),
    ]);

    setLoading(false);
  };

  useEffect(() => {
    fetchAllData();
  }, [estimateId, phaseId]);

  const handleTemplateChange = (e) => {
    const { options } = e.target;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedTemplates(selectedValues); // Update selected templates state
  };

  const [localTaskStates, setLocalTaskStates] = React.useState(
    Object.keys(estimate).reduce((acc, tasktemplateId) => {
      acc[tasktemplateId] = estimate[tasktemplateId].tasks || [];
      return acc;
    }, {})
  );

  const handleInputChange = (tasktemplateId, taskId, value) => {
    setLocalTaskStates((prevState) => ({
      ...prevState,
      [tasktemplateId]: prevState[tasktemplateId].map((task) =>
        task.id === taskId ? { ...task, planned_hours: value } : task
      ),
    }));
  };

  // Handle updating templates for the estimate and phase
  const handleUpdateTemplates = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    setSelectedTemplates([]);
    if (estimatedBy.length === 0) {
      setLoading(false);
      toast.error("Select Estimated by", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }
    try {
      // Send selected template IDs to backend along with estimateId and phaseId
      const response = await api.post(
        `/api/estimate/${estimateId}/phase/${phaseId}/update-templates`,
        {
          selectedTemplateIds: selectedTemplates, // Use the selected templates state
          estimatedBy: estimatedBy,
        }
      );
      // If successful, show the success message
      if (response.status === 200) {
        // Assuming response.data contains the new tasks to be added, you can merge them with the existing state
        fetchAllData();
        fetchTaskData();
        // setSuccess(true);
        toast.success("Templates updated successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (err) {
      // Handle any errors
      console.error("Error updating templates:", err);
      setError("Failed to update templates and tasks.");
    } finally {
      setLoading(false);
    }
  };

  // Use effect to load grouped tasks when the component mounts
  useEffect(() => {
    fetchAllData();
  }, [estimateId, phaseId]); // Re-fetch when estimateId or phaseId changes

  function normalizePlannedHours(input) {
    // If input is a decimal (e.g., "1.5"), convert it to HH:MM format
    if (/^[0-9]+([.][0-9]+)?$/.test(input)) {
      const hours = Math.floor(parseFloat(input));
      const minutes = Math.round((parseFloat(input) - hours) * 60);
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    }

    // If input is in HH:MM format, return it directly
    if (/^[0-9]+:[0-5][0-9]$/.test(input)) {
      return input;
    }

    // If input is a whole number, treat it as hours
    if (/^[0-9]+$/.test(input)) {
      return `${parseInt(input, 10)}:00`;
    }

    // For invalid formats, return an empty string
    return "00:00";
  }

  const handlePlannedHoursChange = (
    tasktemplateId,
    taskId,
    newPlannedHours
  ) => {
    SetCalculate(true);
    setEstimate((prevGroupedTasks) => {
      const updatedGroupedTasks = { ...prevGroupedTasks };
      updatedGroupedTasks[tasktemplateId].tasks = updatedGroupedTasks[
        tasktemplateId
      ].tasks.map((task) =>
        task.id === taskId ? { ...task, planned_hours: newPlannedHours } : task
      );
      return updatedGroupedTasks;
    });
  };
  const SavePlannedHours = async (estimate) => {
    SetCalculate(true);

    try {
      // Prepare the payload
      const payload = {
        estimate,
      };

      // Make the API request
      const response = await api.put(
        `/api/estimate/update-task-planned-hours`,
        payload
      );

      if (response.status === 200) {
        // Update the estimate state on success
        fetchAllData();
        fetchTaskData();
        console.log("Successfully updated planned hours.");
        SetCalculate(false);
      } else {
        throw new Error("Failed to update planned hours");
      }
    } catch (error) {
      console.error("Error updating planned hours:", error);
      SetCalculate(false);
    }
  };

  const updatePlannedHours = async (
    estimateId,
    taskId,
    phaseId,
    plannedHours,
    tasktemplateId
  ) => {
    SetCalculate(true);
    setEstimate((prevEstimate) => {
      const updatedEstimate = { ...prevEstimate };
      const tasks = updatedEstimate[tasktemplateId]?.tasks;
      if (tasks) {
        const taskIndex = tasks.findIndex((task) => task.id === taskId);
        if (taskIndex !== -1) {
          tasks[taskIndex].planned_hours = plannedHours;
        }
      }

      return updatedEstimate;
    });
  };

  const handleDeleteClick = (taskTemplateId) => {
    setTaskToDelete(Number(taskTemplateId));
    setDeleteTaskDialogOpen(true);
  };
  const handleDeleteTaskTemplate = async () => {
    const taskTemplateId = Number(taskToDelete); // Ensure taskToDelete is correctly set

    if (!taskTemplateId) {
      console.error("No task template ID set for deletion.");
      toast.error("Please select a task template to delete.", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    try {
      // Perform the deletion via API
      const response = await api.delete(`/api/deleteestimate-tasktemplate`, {
        data: { tasktemplateIds: [taskTemplateId] }, // Send the taskTemplateId in the request body
      });

      if (response.status === 200) {
        // Update state to reflect the deletion
        fetchAllData();
        fetchTaskData();
        setShowDesign(false);
        setShowDetailing(false);
        fabricationHours("");
        totalFabricationMinutes("");
        erectionHours("");
        gaDrawingHours("");
        setDeleteTaskDialogOpen(false);
        setIsCheckboxChecked(false);
        setEstimate((prevEstimates) => {
          const updatedPhases = { ...prevEstimates.phases };

          // Remove the task template with the given taskTemplateId and its associated tasks
          delete updatedPhases[taskTemplateId];

          return { ...prevEstimates, phases: updatedPhases };
        });

        // Close the delete dialog and reset the state
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage =
          error.response.data?.message || "An error occurred.";
        console.error("Deletion prevented:", errorMessage);
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
        });
      } else {
        console.error("Unexpected error during deletion:", error.message);
        toast.error("An unexpected error occurred. Please try again.", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } finally {
      setDeleteTaskDialogOpen(false);
      setIsCheckboxChecked(false);
    }
  };

  const handleTaskUser = async (taskId, e) => {
    const userId = e.target.value;

    const isConfirmed = window.confirm(
      "Are you sure you want to change the user ?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await api.put("/api/update-task-estimatedby", {
        userId,
        taskId,
      });
      fetchAllData();
    } catch (error) {
      console.error(
        "Error updating user:",
        error.response?.data || error.message
      );
    }
  };

  const calculateTotalHours = (tasks, filterFn) => {
    return tasks.filter(filterFn).reduce((total, task) => {
      const [hours, minutes] = task.planned_hours.split(":").map(Number);
      return total + hours * 60 + minutes;
    }, 0);
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, "0")}`;
  };

  // Filters
  const isFabrication = (task) => task.task_name.startsWith("Fab");
  const isErection = (task) => task.task_name.startsWith("Erec");
  const isRemainingForGA = (task) =>
    !task.task_name.startsWith("Fab") &&
    !task.task_name.startsWith("Erec") &&
    task.task_name !== "Reports & Final Package";

  // Calculations
  const fabricationMinutes = calculateTotalHours(
    tasksCalculation,
    isFabrication
  );
  const reportsAndFinalPackageMinutes =
    tasksCalculation
      .find((task) => task.task_name === "Reports & Final Package")
      ?.planned_hours.split(":")
      .reduce((total, value, index) => {
        return total + (index === 0 ? Number(value) * 60 : Number(value));
      }, 0) || 0;

  const totalFabricationMinutes =
    fabricationMinutes + reportsAndFinalPackageMinutes;

  const erectionHours = convertMinutesToHours(
    calculateTotalHours(tasksCalculation, isErection)
  );
  const gaDrawingHours = convertMinutesToHours(
    calculateTotalHours(tasksCalculation, isRemainingForGA)
  );

  // Final Fabrication Hours
  const fabricationHours = convertMinutesToHours(totalFabricationMinutes);

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    ); // Show loading spinner while fetching data
  }

  return (
    <div
      className={
        isNavBarOpen
          ? "estimate-task-main-container"
          : "estimate-task-main-container-close"
      }
    >
      <div>
        {/* <h3 className="grouped-estimate-tasks">Grouped Estimate Tasks</h3> */}
        <Link to={`/estimate/phases/${estimateId}`}>
          {" "}
          <IoMdClose
            className={isNavBarOpen ? "est-task-close" : "est-task-close-close"}
          />{" "}
        </Link>
        <EstimateTaskTable
          tasks={tasks}
          detailingSumHours={{
            gaDrawingHours,
            erectionHours,
            fabricationHours,
          }}
          revision={revision}
          exclusion={exclusion}
          design={setShowDesign}
          detailing={setShowDetailing}
          designRevise={SetAlertMessageForDesign}
          detailingRevise={SetAlertMessageForDetailing}
          projectDetails={projectDetails}
        />
      </div>

      {/* Show error message */}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {/* Show success message */}
      {success && (
        <p style={{ color: "green", marginLeft: "16px", marginBottom: "-9px" }}>
          Templates updated successfully!
        </p>
      )}
      <div
        className={
          isNavBarOpen ? "task-project-details" : "task-project-details-close"
        }
      >
        <h3 style={{ marginBottom: "5px", marginRight: "20px" }}>
          Estimate :{" "}
          <span style={{ marginLeft: "3px" }}>
            {projectDetails?.estimationno ? projectDetails.estimationno : ""}
          </span>
        </h3>
        <h3 style={{ marginBottom: "5px", marginRight: "20px" }}>
          Project :{" "}
          <span style={{ marginLeft: "3px" }}>
            {" "}
            {projectDetails?.projectname ? projectDetails.projectname : ""}
          </span>
        </h3>
        <h3 style={{ marginBottom: "5px" }}>
          Phase :{" "}
          <span style={{ marginLeft: "3px" }}>
            {" "}
            {projectDetails?.phase_name ? projectDetails.phase_name : ""}
          </span>
        </h3>
        {templates.length !== Object.keys(estimate).length && (
          <>
            {" "}
            <label className="est-select-template">Select Templates</label>
            <select
              className="e-select-template"
              // multiple
              value={selectedTemplates} // Bind selectedTemplates state to the dropdown
              onChange={handleTemplateChange}
              // size={5}
            >
              <option value={""} disabled>
                Select Template
              </option>
              {templates.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
          </>
        )}
        {templates.length !== Object.keys(estimate).length &&
          selectedTemplates.length > 0 && (
            <button
              onClick={handleUpdateTemplates}
              disabled={loading}
              className="update-e-template-btn"
            >
              Add Template
            </button>
          )}
        {templates.length === Object.keys(estimate).length && (
          <p className={isNavBarOpen ? "e-group-tasks" : "e-group-tasks-close"}>
            All Tasks are added
          </p>
        )}
      </div>
      {/* Dropdown to select multiple templates */}
      {templates.length !== Object.keys(estimate).length && (
        <div
          className={isNavBarOpen ? "e-tt-container" : "e-tt-container-close"}
        ></div>
      )}

      {/* Display grouped tasks */}
      {(showDesign || showDetailing) && (
        <div className="add-project-dial" style={{ width: "99%" }}>
          {/* <h3 className="e-grouped-tasks">Grouped Tasks: </h3> */}
          {Object.keys(estimate).length > 0 ? (
            Object.keys(estimate)
              .filter(
                (tasktemplateId) =>
                  (showDesign &&
                    estimate[tasktemplateId]?.template_name === "Design") ||
                  (showDetailing &&
                    estimate[tasktemplateId]?.template_name === "Detailing")
              )
              .map((tasktemplateId) => (
                <div
                  key={tasktemplateId}
                  className={
                    isNavBarOpen
                      ? "estimate-task-templates"
                      : "estimate-task-templates-close"
                  }
                >
                  {" "}
                  <div
                    className={
                      hasPermission("estimates", "delete")
                        ? "edit-est-admin"
                        : "edit-est-projectmanager"
                    }
                  >
                    {hasPermission("estimates", "delete") && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent toggle from firing
                          handleDeleteClick(tasktemplateId);
                        }}
                        title="Delete Task Template"
                        className={
                          isNavBarOpen
                            ? "est-delete-icon"
                            : "est-delete-icon-close"
                        }
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </button>
                    )}
                    <h3 className="e-group-template">
                      Task Template: {estimate[tasktemplateId].template_name}
                      {hasPermission("estimates", "delete") && (
                        <span style={{ marginLeft: "20px" }}>
                          Estimated By :
                          <select
                            className="select-estimated-by"
                            value={estimate[tasktemplateId].estimated_by}
                            onChange={(e) => handleTaskUser(tasktemplateId, e)}
                          >
                            <option value="" disabled>
                              Select User
                            </option>
                            {users
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((user) => (
                                <option key={user.id} value={user.id}>
                                  {user.name}
                                </option>
                              ))}
                          </select>
                        </span>
                      )}
                    </h3>
                  </div>
                  <table
                    className={
                      isNavBarOpen
                        ? "estimate-task-table"
                        : "estimate-task-table-close"
                    }
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "350px" }}>Task Name</th>
                        <th style={{ width: "100px" }}>Planned Hours</th>
                      </tr>
                    </thead>
                    <tbody>
                      {estimate[tasktemplateId].tasks &&
                      estimate[tasktemplateId].tasks.length > 0 ? (
                        <>
                          {estimate[tasktemplateId].tasks.some(
                            (task) => task.code === "CD"
                          ) && (
                            <>
                              <tr className="group-header">
                                <td
                                  colSpan="2"
                                  style={{
                                    fontWeight: "bold",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  Connection Design Tasks
                                </td>
                              </tr>
                              {estimate[tasktemplateId].tasks
                                .filter((task) => task.code === "CD") // Filter tasks for Connection Design
                                .sort((a, b) => Number(a.sno) - Number(b.sno)) // Optional sorting by task name
                                .map((task) => (
                                  <tr key={task.id}>
                                    <td>{task.task_name}</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      <input
                                        className="e-ph-hr"
                                        type="text"
                                        value={
                                          task.planned_hours === "00:00"
                                            ? ""
                                            : task.planned_hours || ""
                                        }
                                        pattern="([01]?[0-9]|2[0-3]):([0-5]?[0-9])"
                                        onChange={(e) =>
                                          handlePlannedHoursChange(
                                            tasktemplateId,
                                            task.id,
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) =>
                                          updatePlannedHours(
                                            estimateId,
                                            task.id,
                                            task.phase_id,
                                            normalizePlannedHours(
                                              e.target.value
                                            ),
                                            tasktemplateId
                                          )
                                        }
                                        title="Format: HH:MM"
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}

                          {/* Render Member Design Tasks */}
                          {estimate[tasktemplateId].tasks.some(
                            (task) => task.code === "MD"
                          ) && (
                            <>
                              <tr className="group-header">
                                <td
                                  colSpan="2"
                                  style={{
                                    fontWeight: "bold",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  Member Design Tasks
                                </td>
                              </tr>
                              {estimate[tasktemplateId].tasks
                                .filter((task) => task.code === "MD") // Filter tasks for Member Design
                                .sort((a, b) => Number(a.sno) - Number(b.sno)) // Optional sorting by task name
                                .map((task) => (
                                  <tr key={task.id}>
                                    <td>{task.task_name}</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      <input
                                        className="e-ph-hr"
                                        type="text"
                                        value={
                                          task.planned_hours === "00:00"
                                            ? ""
                                            : task.planned_hours || ""
                                        }
                                        pattern="([01]?[0-9]|2[0-3]):([0-5]?[0-9])"
                                        onChange={(e) =>
                                          handlePlannedHoursChange(
                                            tasktemplateId,
                                            task.id,
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) =>
                                          updatePlannedHours(
                                            estimateId,
                                            task.id,
                                            task.phase_id,
                                            normalizePlannedHours(
                                              e.target.value
                                            ),
                                            tasktemplateId
                                          )
                                        }
                                        title="Format: HH:MM"
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                          {estimate[tasktemplateId].tasks.some(
                            (task) => task.code === "SD"
                          ) && (
                            <>
                              <tr className="group-header">
                                <td
                                  colSpan="2"
                                  style={{
                                    fontWeight: "bold",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  Steel Detailing Tasks
                                </td>
                              </tr>
                              {estimate[tasktemplateId].tasks
                                .filter((task) => task.code === "SD") // Filter tasks for Member Design
                                .sort((a, b) => Number(a.sno) - Number(b.sno)) // Optional sorting by task name
                                .map((task) => (
                                  <tr key={task.id}>
                                    <td>{task.task_name}</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      <input
                                        className="e-ph-hr"
                                        type="text"
                                        value={
                                          task.planned_hours === "00:00"
                                            ? ""
                                            : task.planned_hours || ""
                                        }
                                        pattern="([01]?[0-9]|2[0-3]):([0-5]?[0-9])"
                                        onChange={(e) =>
                                          handlePlannedHoursChange(
                                            tasktemplateId,
                                            task.id,
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) =>
                                          updatePlannedHours(
                                            estimateId,
                                            task.id,
                                            task.phase_id,
                                            normalizePlannedHours(
                                              e.target.value
                                            ),
                                            tasktemplateId
                                          )
                                        }
                                        title="Format: HH:MM"
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="2">
                            No tasks available for this template.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="est-edit-button-con">
                    <button
                      onClick={() => {
                        fetchTaskData();
                        fetchAllData();
                        setShowDesign(false);
                        setShowDetailing(false);
                        setReviseDesign(false);
                        setReviseDetailing(false);
                        SavePlannedHours(estimate);
                        showDesign ? setDesignNo(true) : setDetailingNo(true);
                      }}
                      className="add-btn"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => {
                        setShowDesign(false);
                        setShowDetailing(false);
                        setReviseDesign(false);
                        setReviseDetailing(false);
                        fetchTaskData();
                        fetchAllData();
                      }}
                      className="cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ))
          ) : (
            <p
              className="est-phase-message"
              style={{ backgroundColor: "transparent" }}
            ></p>
          )}
        </div>
      )}
      <ToastContainer />

      {deleteTaskDialogOpen && (
        <div className="delete-var-issue-dial" style={{ zIndex: "17" }}>
          <div className="delete-var-dial">
            <div className="delete-var-header">
              <h3 className="delete-variation">Delete Task Template</h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p className="delete-t-para">
                Are you sure you want to delete this Task Template? This action
                cannot be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={isCheckboxChecked}
                id="tick"
                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
              />
              <label
                htmlFor="tick"
                style={{
                  backgroundColor: "white",
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-btn-issue-del-con">
              <button
                onClick={handleDeleteTaskTemplate}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                style={{
                  backgroundColor: isCheckboxChecked ? "red" : "grey",
                }}
                className="add-btn"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setDeleteTaskDialogOpen(false);
                  setIsCheckboxChecked(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {alertMessageForDesign && (
        <div className="delete-var-issue-dial" style={{ zIndex: "17" }}>
          <div className="delete-var-dial">
            <div className="delete-var-header">
              <h3 className="delete-variation">
                Revise Estimate Task Template
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p className="delete-t-para">
                Are you sure you want to revise this Task Template? This action
                cannot be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={isCheckboxChecked}
                id="tick"
                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
              />
              <label
                htmlFor="tick"
                style={{
                  backgroundColor: "white",
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-btn-issue-del-con">
              <button
                onClick={() => setReviseDesign(true)}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                style={{
                  backgroundColor: isCheckboxChecked ? "red" : "grey",
                }}
                className="add-btn"
              >
                Revise
              </button>
              <button
                onClick={() => {
                  SetAlertMessageForDesign(false);
                  setIsCheckboxChecked(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {alertMessageForDetailing && (
        <div className="delete-var-issue-dial" style={{ zIndex: "17" }}>
          <div className="delete-var-dial">
            <div className="delete-var-header">
              <h3 className="delete-variation">
                Revise Estimate Task Template
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p className="delete-t-para">
                Are you sure you want to revise this Task Template? This action
                cannot be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={isCheckboxChecked}
                id="tick"
                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
              />
              <label
                htmlFor="tick"
                style={{
                  backgroundColor: "white",
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-btn-issue-del-con">
              <button
                onClick={() => setReviseDetailing(true)}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                style={{
                  backgroundColor: isCheckboxChecked ? "red" : "grey",
                }}
                className="add-btn"
              >
                Revise
              </button>
              <button
                onClick={() => {
                  SetAlertMessageForDetailing(false);
                  setIsCheckboxChecked(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default EstimateTasks;
