import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/axiosConfig";
import "./ProjectUsers.css";
import { ProjectContext } from "../../App";
import { useRolePermissions } from "../Settings/RoleContext";
import { LuUserX } from "react-icons/lu";
import { FiUserPlus } from "react-icons/fi";
import { FaUserPen } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const ProjectUsers = ({ refresh }) => {
  const { projectId: currentProjectId } = useParams();
  const { hasPermission } = useRolePermissions();
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [nonAssociatedUsers, setNonAssociatedUsers] = useState([]);
  const [deleteUserDial, setDeleteUserDial] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [columnWidths, setColumnWidths] = useState(Array(9).fill(30));
  const startX = useRef(null);
  const startWidth = useRef(null);
  const activeColumnIndex = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const [hasAccess, setHasAccess] = useState(null);

  const fetchData = async (url) => {
    try {
      const res = await api.get(url);
      const activeUsers = res.data.filter(
        (member) => member.status !== "Inactive"
      ); // Filter only active users
      setUsers(activeUsers); // Set only active users
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkAccess = async () => {
      const access = await hasPermission("users", "update");
      setHasAccess(access);
    };

    checkAccess();
  }, [hasPermission]);

  useEffect(() => {
    if (hasAccess === null) {
      return;
    }

    const endpoint = hasAccess
      ? `/api/users`
      : `/api/projects/${currentProjectId}/users`;

    fetchData(endpoint);
  }, [hasAccess, currentProjectId]);

  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);

  useEffect(() => {
    const fetchAssociatedUsers = async () => {
      try {
        const associateres = await api.get(
          `/api/projects/${currentProjectId}/users`
        );
        const associatedUserIds = associateres.data.map((user) => user.id);

        // Separate users into associated and non-associated
        const assocUsers = users.filter((user) =>
          associatedUserIds.includes(user.id)
        );
        setAssociatedUsers(assocUsers);

        const nonAssocUsers = users.filter(
          (user) => !associatedUserIds.includes(user.id)
        );
        setNonAssociatedUsers(nonAssocUsers);
      } catch (error) {
        console.error(error);
      }
    };

    if (currentProjectId && users.length > 0) {
      fetchAssociatedUsers();
    }
  }, [currentProjectId, users, refresh]);

  const handleAssociateUsersToProject = async (userId) => {
    setLoading(true);
    try {
      const response = await api.post(
        `/api/projects/${currentProjectId}/users`,
        { userIds: [userId] }
      );

      const data = response.data;
      console.log(data.message);
      alert("User associated with the project successfully.");
      setLoading(false);
      setAssociatedUsers((prev) => [
        ...prev,
        users.find((user) => user.id === userId),
      ]);

      setNonAssociatedUsers((prev) =>
        prev.filter((user) => user.id !== userId)
      );
    } catch (error) {
      console.error("Error associating user with project:", error);
      alert("Error associating user with project. Please try again.");
    }
  };

  const handleRemoveUsersFromProject = async (userId) => {
    setLoading(true);
    if (isChecked) {
      try {
        const response = await api.delete(
          `/api/projects/${currentProjectId}/users`,
          {
            data: { userIds: [userId] }, // `data` key is used to send the request body in DELETE request
          }
        );

        const data = response.data;
        console.log(data.message);
        alert("User removed from the project successfully.");
        setLoading(false);
        setAssociatedUsers((prev) => prev.filter((user) => user.id !== userId));
        setNonAssociatedUsers((prev) => [
          ...prev,
          users.find((user) => user.id === userId),
        ]);
      } catch (error) {
        console.error("Error removing user from project:", error);
        alert("Error removing user from project. Please try again.");
      }
    } else {
      alert(
        "Please read the message and check the box to confirm your understanding before proceeding."
      );
    }
  };

  const handleMouseDown = (index, e) => {
    startX.current = e.pageX;
    startWidth.current = columnWidths[index];
    activeColumnIndex.current = index;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (activeColumnIndex.current !== null) {
      let newWidth = startWidth.current + (e.pageX - startX.current);
      if (newWidth < 50) newWidth = 50;
      if (newWidth > 500) newWidth = 500;
      setColumnWidths((prevWidths) => {
        const updatedWidths = [...prevWidths];
        updatedWidths[activeColumnIndex.current] = newWidth;
        return updatedWidths;
      });
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    activeColumnIndex.current = null;
  };

  const mapKeyToUserProperty = (heading) => {
    switch (heading) {
      case "User Name":
        return "name";
      case "Email ID":
        return "email";
      case "Role":
        return "role_name";
      case "Profile":
        return "designation";
      default:
        return "";
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleSearch = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  const sortedUsers = [...users]
    // Step 1: Apply the sorting order based on sortConfig
    .sort((a, b) => {
      const key = sortConfig.key || "name";
      const direction = sortConfig.direction === "desc" ? -1 : 1;
      if (a[key] < b[key]) return -1 * direction;
      if (a[key] > b[key]) return 1 * direction;
      return 0;
    })
    // Step 2: Prioritize associated users at the top
    .sort((a, b) => {
      const aIsAssociated = associatedUsers.some(
        (assocUser) => assocUser.id === a.id
      );
      const bIsAssociated = associatedUsers.some(
        (assocUser) => assocUser.id === b.id
      );

      // If a is associated and b is not, a comes first
      if (aIsAssociated && !bIsAssociated) return -1;
      // If b is associated and a is not, b comes first
      if (!aIsAssociated && bIsAssociated) return 1;

      // If both are associated or neither are, proceed with normal sorting
      return 0;
    });

  const filteredUsers = sortedUsers.filter((user) => {
    return (
      user.name.toLowerCase().includes(filter) ||
      user.email.toLowerCase().includes(filter)
    );
  });

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUsers, indexOfLastUsers);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      {!dialBoxopen && (
        <div
          className={
            isNavBarOpen
              ? "project-users-main-container"
              : "project-users-main-container-close"
          }
        >
          {hasPermission("users", "update") && (
            <div
              className={
                isNavBarOpen ? "filters-container" : "filters-container-close"
              }
            >
              <input
                type="search"
                className="user-search-filter"
                placeholder="Filter by name or email"
                value={filter}
                onChange={handleSearch}
              />
            </div>
          )}
          <div
            className={`users-table-container ${
              dialBoxopen ? "open" : "close"
            }`}
            style={{ top: "" }}
          >
            <table
              className={
                isNavBarOpen ? "user-content-table" : "user-content-table-close"
              }
            >
              <thead style={{ position: "sticky", top: "-1px" }}>
                <tr>
                  {[
                    "S.No",
                    "User Name",
                    "Email ID",
                    "Role",
                    "Profile",
                    ...(hasPermission("users", "update")
                      ? [<FaUserPen key="pen" />]
                      : []),
                    "",
                  ].map((heading, index) => (
                    <th
                      key={index}
                      onClick={
                        typeof heading === "string" && heading !== "S.No"
                          ? () => handleSort(mapKeyToUserProperty(heading))
                          : null
                      }
                      style={{
                        width: columnWidths[index],
                        textAlign: React.isValidElement(heading)
                          ? "center"
                          : "left", // Center align for FaUserPen
                        maxWidth: React.isValidElement(heading)
                          ? "10px"
                          : heading === "S.No" || heading === "User Name"
                          ? "28px"
                          : "auto", // Max width for FaUserPen and S.No
                      }}
                    >
                      <div
                        className="resize-handle"
                        onMouseDown={(e) => handleMouseDown(index, e)}
                      />
                      {heading}
                      {typeof heading === "string" &&
                        sortConfig.key === mapKeyToUserProperty(heading) && (
                          <span>
                            {sortConfig.direction === "asc" ? " ↑" : " ↓"}
                          </span>
                        )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user, index) => {
                  return (
                    <tr key={`${user.id}-${index}`}>
                      <td style={{ textAlign: "center" }}>
                        {(currentPage - 1) * usersPerPage + index + 1}
                      </td>
                      <td>
                        <div style={{ display: "flex" }}>
                          {hasPermission("users", "update") ? (
                            <>
                              <img
                                className="user-profile-dp"
                                src={user.picture}
                              />
                              <span className="user-profile-name">
                                {user.name}
                              </span>
                            </>
                          ) : (
                            <>
                              <img
                                className="user-profile-dp"
                                src={user.picture}
                              />

                              <span>{user.name}</span>
                            </>
                          )}
                        </div>
                      </td>
                      <td>{user.email}</td>
                      <td>{user.role_name}</td>
                      <td>{user.designation}</td>
                      {hasPermission("users", "update") && (
                        <td style={{ textAlign: "center" }}>
                          {associatedUsers.some(
                            (assocUser) => assocUser.id === user.id
                          ) ? (
                            <LuUserX
                              style={{
                                color: "red",
                                cursor: "pointer",
                                backgroundColor: "white",
                                strokeWidth: "3px",
                              }}
                              onClick={() => {
                                setUserIdToDelete(user.id);
                                setDeleteUserDial(true);
                              }}
                              title="Remove User"
                            />
                          ) : (
                            <FiUserPlus
                              style={{
                                color: "green",
                                cursor: "pointer",
                                backgroundColor: "white",
                                strokeWidth: "3px",
                              }}
                              onClick={() =>
                                handleAssociateUsersToProject(user.id)
                              }
                              title="Add User"
                            />
                          )}
                        </td>
                      )}
                      <td style={{ width: "560px" }}></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            className={
              isNavBarOpen
                ? "project-user-pagination-controls"
                : "project-user-pagination-controls-close"
            }
            style={{ marginTop: "40px" }}
          >
            <h3>Total : {users.length}</h3>
            <button
              onClick={handleFirstPage}
              disabled={currentPage === 1}
              className="arrow-project-btn"
            >
              <MdKeyboardDoubleArrowLeft title="First Page" />
            </button>
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="arrow-project-btn"
            >
              {/* */}
              <MdKeyboardArrowLeft title="Previous Page" />
            </button>
            <span
              style={{
                marginTop: "16px",
                textWrap: "nowrap",
              }}
            >
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="arrow-project-btn"
            >
              <MdKeyboardArrowRight title="Next Page" />
            </button>
            <button
              onClick={handleLastPage}
              disabled={currentPage === totalPages}
              className="arrow-project-btn"
            >
              <MdKeyboardDoubleArrowRight title="Last Page" />
            </button>
          </div>
          {deleteUserDial && (
            <div className="delete-user-dial">
              <div className="delete-con">
                <div className="delete-user-header">
                  <h3
                    style={{
                      backgroundColor: "transparent",
                      marginLeft: "13px",
                    }}
                  >
                    Delete User
                  </h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "white",
                    margin: "10px",
                    marginLeft: "-1px",
                  }}
                >
                  <input
                    type="checkbox"
                    id="delete"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    style={{ marginRight: "20px", marginTop: "-53px" }}
                  />
                  <label htmlFor="delete" style={{ backgroundColor: "white" }}>
                    Are you sure you want to remove this user from the project?
                    This user may be associated with timesheets or tasks. If you
                    proceed, all details related to this user in the project
                    will be deleted.
                  </label>
                </div>
                <div
                  className="delete-btn-container"
                  style={{ backgroundColor: "white" }}
                >
                  <button
                    className="add-btn"
                    style={{
                      backgroundColor: isChecked ? "red" : "#808080",
                      marginLeft: "17px",
                    }}
                    onClick={() => {
                      if (userIdToDelete) {
                        handleRemoveUsersFromProject(userIdToDelete);
                        setDeleteUserDial(false);
                        setIsChecked(false);
                      }
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="cancel-btn"
                    onClick={() => {
                      setDeleteUserDial(false);
                      setIsChecked(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProjectUsers;
