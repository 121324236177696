import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api/axiosConfig";

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(false);
  const navigate = useNavigate();

  // Validate the password input
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Validate the reset token when the component mounts
  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await api.get(`/reset-password/${token}`); // Ensure the correct endpoint
        setIsTokenValid(true); // Mark token as valid
        setMessage(response.data.message); // Set success message
      } catch (err) {
        setError(
          err.response?.data.message ||
            "Error validating token. Please try again."
        );
      }
    };

    validateToken();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const specialChars = /[!@#$%&*]/;
    const hasUppercase = /[A-Z]/;
    const hasNumber = /[0-9]/;
    if (
      !password ||
      password.length < 6 ||
      password.length > 15 ||
      !hasUppercase.test(password) ||
      !hasNumber.test(password) ||
      !specialChars.test(password)
    ) {
      setError(
        "(Password must be 6-15 characters long, with at least one uppercase letter, one number, and one special symbol (!,@,#,$,%,&,*))"
      );
      return;
    }

    try {
      const response = await api.post(`/reset-password/${token}`, { password });
      setMessage(response.data.message); // Show success message
      alert("Password updated");
      setTimeout(() => {
        navigate("/"); // Redirect after a slight delay to show success
      }, 2000); // Redirect after 2 seconds
      setError(""); // Clear any previous errors
    } catch (err) {
      setError(
        err.response?.data.message ||
          "Error resetting password. Please try again."
      );
    }
  };

  return (
    <div className="main-container-signin">
      <div className="type-email-parent">
        {isTokenValid ? ( // Check if token is valid
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              placeholder="Enter your new password"
              value={password}
              onChange={handlePasswordChange}
              className="type-email"
              required // Optional: to require password entry
            />
            <p
              onClick={() => navigate("/")}
              className="forgot-password1 forgot-to-signin"
            >
              Back to Sign In
            </p>
            <button type="submit" className="button2" style={{ top: "223px" }}>
              Reset Password
            </button>
            {!error && message && (
              <p className="forgot-password1 reset-password-message">
                {message}
              </p>
            )}
            {error && <p className="forgot-password1 forgot">{error}</p>}
            <img
              className="task-bro-1-icon1"
              alt="man-logo"
              src="/taskbro-1@2x.jpg"
            />
            <img
              className="group-158-1"
              alt="MSES Group"
              src="/group-158-1@2x.png"
            />
          </form>
        ) : (
          <>
            <p className="forgot-password1 reset-password-error">
              {error || "Validating token..."}
            </p>

            <p
              onClick={() => navigate("/")}
              className="forgot-password1 forgot-to-signin"
            >
              Back to Sign In
            </p>
            <img
              className="task-bro-1-icon1"
              alt="man-logo"
              src="/taskbro-1@2x.jpg"
            />
            <img
              className="group-158-1"
              alt="MSES Group"
              src="/group-158-1@2x.png"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
