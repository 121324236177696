import React, { useEffect, useState, useContext, useRef } from "react";
import { format } from "date-fns";
import { useRolePermissions } from "../Settings/RoleContext";
import { useAuth } from "../Authentication/AuthContext.js";
import { useParams } from "react-router-dom";
import api from "../../api/axiosConfig.js";
import { MdNumbers } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineCategory } from "react-icons/md";
import { GrStatusGood } from "react-icons/gr";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { TbNotes } from "react-icons/tb";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { IoTimerOutline } from "react-icons/io5";
import { GrUserExpert } from "react-icons/gr";
import { MdCreditScore } from "react-icons/md";
import { CgRename } from "react-icons/cg";
import { SiTyper } from "react-icons/si";
import { MdOutlineSubtitles } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { ProjectContext } from "../../App.js";
import FilterTimesheetOverview from "./FilterTimesheetOverview.js";
import { FaChevronDown } from "react-icons/fa";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import "./TimeSheetTable.css";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import { MdOutlineDateRange } from "react-icons/md";
import Userstimesheet from "./UsersTimesheet.js";
import { IoMdClose } from "react-icons/io";

const TimeSheetTable = () => {
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const { hasPermission } = useRolePermissions();
  const [timesheets, setTimesheets] = useState([]);
  const [selectedTimesheets, setSelectedTimesheets] = useState([]);
  const profileRef = useRef(null);
  const [bulkUpdate, SetBulkUpdate] = useState(false);
  const [deleteTimesheetDial, setDeleteTimesheetDial] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default sort order for date grouping
  const [groupBy, setGroupBy] = useState(
    localStorage.getItem("groupBy") || "project"
  );
  useEffect(() => {
    localStorage.setItem("groupBy", groupBy);
  }, [groupBy]);
  const { projectId } = useParams();
  const { userDetails } = useAuth();
  const userId = userDetails.id;
  let filterByValue;
  const [userTimesheetDial, setUserTimesheetDial] = useState(false);
  const [filters, setFilters] = useState({
    user: "",
    userFilterType: "contains",
    selectedProjects: "",
    ProjectFilterType: "contains", // exact or contains
    logTitle: "",
    logTitleFilterType: "contains", // exact or contains
    logCategory: "",
    logCategoryFilterType: "exact", // exact or contains
    date: "",
    fromDate: "",
    toDate: "",
    dateFilterType: "specified", // specified or range
    billable: "",
    approvalstatus: "",
  });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        SetBulkUpdate(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);
  const formatTime = (duration) => {
    const [hours, minutes] = duration.split(":"); // Split the duration string into hours and minutes
    return `${hours}:${minutes}`; // Return only hours and minutes
  };
  const fetchTimesheets = async (currentFilters = filters) => {
    try {
      setLoading(true);
      const endpoint =
        userDetails.role_name === "Administrator" ||
        userDetails.role_name === "Project_Manager"
          ? `/api/timesheets`
          : `/api/timesheets/${userId}`;

      const response = await api.get(endpoint);
      let timesheets = response.data;

      let ftimesheets = timesheets;
      // Apply user filter (exact or contains)
      if (currentFilters.user && currentFilters.user.value.length > 0) {
        const userFilterCondition = currentFilters.user.condition || "not";
        const userFilterValue = currentFilters.user.value; // No `toLowerCase` here, because we need to handle both string and array
        ftimesheets = ftimesheets.filter((ts) => {
          const user = ts.user_name ? ts.user_name.toLowerCase() : "";

          if (Array.isArray(userFilterValue)) {
            // If the filter value is an array
            if (userFilterCondition === "is") {
              // Return true if the user matches any value in the array (exact match)
              return userFilterValue.some(
                (filterUser) => user === filterUser.toLowerCase()
              );
            } else if (userFilterCondition === "not") {
              // Return true if the user does not match any value in the array
              return userFilterValue.every(
                (filterUser) => !user.includes(filterUser.toLowerCase())
              );
            }
          } else {
            // If the filter value is a string
            const userFilterValueLower = userFilterValue.toLowerCase();

            if (userFilterCondition === "is") {
              return user === userFilterValueLower; // Match exact user name
            } else if (userFilterCondition === "not") {
              return !user.includes(userFilterValueLower); // User name does not include the filter value
            }
          }
        });
      }

      if (
        currentFilters.selectedProjects &&
        currentFilters.selectedProjects.value.length > 0
      ) {
        const projectFilterCondition =
          currentFilters.selectedProjects.condition || "not";
        const projectFilterValue = currentFilters.selectedProjects.value; // No `toLowerCase` here, because we need to handle both string and array
        ftimesheets = ftimesheets.filter((ts) => {
          const project = ts.project_name ? ts.project_name.toLowerCase() : "";

          if (Array.isArray(projectFilterValue)) {
            // If the filter value is an array
            if (projectFilterCondition === "is") {
              // Return true if the project matches any value in the array (exact match)
              return projectFilterValue.some(
                (filterProject) => project === filterProject.toLowerCase()
              );
            } else if (projectFilterCondition === "not") {
              // Return true if the project does not match any value in the array
              return projectFilterValue.every(
                (filterProject) =>
                  !project.includes(filterProject.toLowerCase())
              );
            }
          } else {
            // If the filter value is a string
            const projectFilterValueLower = projectFilterValue.toLowerCase();

            if (projectFilterCondition === "is") {
              return project === projectFilterValueLower; // Match exact project name
            } else if (projectFilterCondition === "not") {
              return !project.includes(projectFilterValueLower); // project name does not include the filter value
            }
          }
        });
      }

      if (
        currentFilters.logTitle &&
        currentFilters.logTitle.value.trim() !== ""
      ) {
        const filterLogTitle = currentFilters.logTitle.value
          .toLowerCase()
          .trim();
        const logTitleFilterType = currentFilters.logTitle.condition || "not";
        ftimesheets = ftimesheets.filter((ts) => {
          const logTitle = ts.logtitle ? ts.logtitle.toLowerCase() : "";
          if (logTitleFilterType === "is") {
            return logTitle === filterLogTitle;
          } else {
            return !logTitle.includes(filterLogTitle);
          }
        });
      }

      // Apply date filter
      if (
        currentFilters.date ||
        (currentFilters.fromDate && currentFilters.toDate)
      ) {
        if (
          currentFilters.dateFilterType === "range" &&
          currentFilters.fromDate &&
          currentFilters.toDate
        ) {
          const fromDate = new Date(currentFilters.fromDate).setHours(
            0,
            0,
            0,
            0
          );
          const toDate = new Date(currentFilters.toDate).setHours(
            23,
            59,
            59,
            999
          );
          ftimesheets = ftimesheets.filter((ts) => {
            const timesheetDate = new Date(ts.date).getTime();
            return timesheetDate >= fromDate && timesheetDate <= toDate;
          });
        }
      }

      // Apply billable filter
      if (currentFilters.billable && currentFilters.billable.value.length > 0) {
        const billableValues = currentFilters.billable.value; // Get the billable values as an array
        const billableCondition = currentFilters.billable.condition || "is"; // Default condition is "is"

        ftimesheets = ftimesheets.filter((ts) => {
          const billable = ts.billable.toString(); // Convert the billable value to string

          if (billableCondition === "is") {
            // Check if the current billable value is included in the array of billable values
            return billableValues.includes(billable);
          } else if (billableCondition === "not") {
            // Check if the current billable value is not included in the array of billable values
            return !billableValues.includes(billable);
          }
        });
      }

      // Apply approval status filter
      if (
        currentFilters.approvalstatus &&
        currentFilters.approvalstatus.value.length > 0 // Ensure value is an array and has entries
      ) {
        const approvalFilterValues = currentFilters.approvalstatus.value; // Get approval status values as an array
        const approvalFilterCondition =
          currentFilters.approvalstatus.condition || "is"; // Default to "is"

        ftimesheets = ftimesheets.filter((ts) => {
          const approvalStatus = ts.approvalstatus
            ? ts.approvalstatus.toLowerCase()
            : ""; // Convert approval status to lower case for comparison

          if (approvalFilterCondition === "is") {
            // Check if the current approval status is included in the filter values
            return approvalFilterValues.some(
              (filterValue) => filterValue.toLowerCase() === approvalStatus
            );
          } else if (approvalFilterCondition === "not") {
            // Check if the current approval status is NOT included in the filter values
            return !approvalFilterValues.some(
              (filterValue) => filterValue.toLowerCase() === approvalStatus
            );
          }
          return true; // Default case, keep the item
        });
      }

      // Apply admin filter
      if (
        currentFilters.selectedAdmin &&
        Array.isArray(currentFilters.selectedAdmin.value) &&
        currentFilters.selectedAdmin.value.length > 0
      ) {
        const adminFilterValues = currentFilters.selectedAdmin.value; // Array of admin IDs to filter by
        const adminFilterCondition =
          currentFilters.selectedAdmin.condition || "is"; // Default condition

        ftimesheets = ftimesheets.filter((ts) => {
          const approvedById = ts.approved_by; // Get the approved by ID

          // Ensure approvedById is a string for comparison
          const approvedByIdStr = approvedById ? approvedById.toString() : "";

          if (adminFilterCondition === "is") {
            const isIncluded = adminFilterValues.includes(
              Number(approvedByIdStr)
            ); // Check if approvedById is in the filter values
            return isIncluded; // Keep item if condition is met
          } else if (adminFilterCondition === "not") {
            const isNotIncluded = !adminFilterValues.includes(
              Number(approvedByIdStr)
            ); // Check if approvedById is NOT in the filter values

            return isNotIncluded; // Keep item if condition is met
          }

          return true; // Default case, keep the item
        });
      }
      // Update state with filtered timesheets
      setTimesheets(ftimesheets);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = (timesheets, groupBy) => {
    return () => {
      if (timesheets.length === 0) {
        alert("No data to export!");
        return;
      }

      const workbook = new ExcelJS.Workbook();

      // Apply border to each cell
      const applyBorder = (row) => {
        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      };

      // Function to parse time in hh:mm format to minutes
      const parseTimeToMinutes = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      };

      // Function to format minutes into hh:mm format
      const formatMinutesToTime = (minutes) => {
        const hours = String(Math.floor(minutes / 60)).padStart(2, "0");
        const mins = String(minutes % 60).padStart(2, "0");
        return `${hours}:${mins}`;
      };

      // Format all timesheets for the main sheet
      const formattedTimesheets = timesheets.map((timesheet, index) => ({
        Sno: index + 1,
        User: timesheet.user_name,
        Project_no: timesheet.projectno,
        Project: timesheet.project_name,
        Phase_no: timesheet.phase_no,
        Phase: timesheet.phase_title,
        Logtitle: timesheet.logtitle,
        Date: timesheet.date
          ? format(new Date(timesheet.date), "dd-MM-yyyy")
          : "",
        Duration: timesheet.duration,
        Billable: timesheet.billable === 1 ? "Billable" : "Non Billable",
        Status: timesheet.approvalstatus,
        Note: timesheet.notes,
        Created_at: timesheet.created_at
          ? format(new Date(timesheet.created_at), "dd-MM-yyyy")
          : "",
        Approved_at: timesheet.approved_at
          ? format(new Date(timesheet.approved_at), "dd-MM-yyyy")
          : "",
        Updated_at: timesheet.updated_at
          ? format(new Date(timesheet.updated_at), "dd-MM-yyyy")
          : "",
        Category: timesheet.category,
        CreatedBy: timesheet.creator_name,
        Approved_by: timesheet.approver_name,
      }));

      // Overall Timesheet Sheet
      const wb = workbook.addWorksheet("Timesheets");
      // Add Company Header for Overall Sheet
      const companyHeaderRow = wb.addRow(["Company", "MSES Group Ltd."]);
      applyBorder(companyHeaderRow);
      companyHeaderRow.eachCell((cell) => {
        cell.style.font = { bold: true }; // Bold font for company header
      });
      // Overall Headers
      const overallHeaders = [
        "Sno",
        "User",
        "Project_no",
        "Project",
        "Phase_no",
        "Phase",
        "Logtitle",
        "Date",
        "Duration",
        "Billable",
        "Status",
        "Note",
        "Created_at",
        "Approved_at",
        "Updated_at",
        "Category",
        "CreatedBy",
        "Approved_by",
      ];

      // Add Overall Header Row and Apply Border
      const overallHeaderRow = wb.addRow(overallHeaders);
      applyBorder(overallHeaderRow);
      overallHeaderRow.eachCell((cell) => {
        cell.style.font = { bold: true }; // Bold font for headers
      });

      // Add Data Rows for Overall Timesheets
      let totalDuration = 0;
      formattedTimesheets.forEach((timesheetData) => {
        const row = wb.addRow(Object.values(timesheetData));
        applyBorder(row); // Apply borders to data rows

        // Sum up the durations in minutes
        totalDuration += parseTimeToMinutes(timesheetData.Duration);
      });

      // Add Total Duration Row in hh:mm format
      const totalDurationRow = wb.addRow([
        "Total Duration",
        formatMinutesToTime(totalDuration),
      ]);
      applyBorder(totalDurationRow);

      // If groupBy is selected, group the data
      if (groupBy) {
        let groupbyHeader;

        switch (groupBy) {
          case "project":
            groupbyHeader = (timesheet) =>
              `${timesheet.projectno} - ${timesheet.project_name}`;
            break;
          case "date":
            groupbyHeader = (timesheet) =>
              timesheet.date
                ? format(new Date(timesheet.date), "yyyy-MM-dd")
                : "";
            break;
          case "phase":
            groupbyHeader = (timesheet) =>
              `${timesheet.projectno} - ${timesheet.phase_no} - ${timesheet.phase_title}`;
            break;
          case "category":
            groupbyHeader = (timesheet) => timesheet.category;
            break;
          case "user":
            groupbyHeader = (timesheet) => timesheet.user_name;
            break;
          default:
            groupbyHeader = (timesheet) =>
              `${timesheet.projectno} - ${timesheet.project_name}`;
        }

        // Group the data based on selected header
        const groupedData = timesheets.reduce((acc, timesheet) => {
          const key = groupbyHeader(timesheet) || "Uncategorized";
          if (!acc[key]) acc[key] = [];
          acc[key].push(timesheet);
          return acc;
        }, {});

        // If grouped by date, sort keys in descending order
        let sortedGroups = Object.keys(groupedData);
        if (groupBy === "date") {
          sortedGroups = sortedGroups
            .filter((date) => date) // Remove any empty keys
            .sort((a, b) => new Date(b) - new Date(a)); // Sort in descending order
        }

        sortedGroups.forEach((group) => {
          const groupData = groupedData[group];
          const sheetName = group.substring(0, 31); // Sheet name can't be longer than 31 characters
          const groupSheet = workbook.addWorksheet(sheetName);
          // Add Company Header for Group Sheets
          const companyGroupHeaderRow = groupSheet.addRow([
            "Company",
            "MSES Group Ltd.",
          ]);
          applyBorder(companyGroupHeaderRow);
          companyGroupHeaderRow.eachCell((cell) => {
            cell.style.font = { bold: true }; // Bold font for company header
          });
          // Determine Headers for the Group Sheet
          const groupHeaders = [
            "Sno",
            "User",
            "Project_no",
            "Project",
            "Phase_no",
            "Phase",
            "Logtitle",
            "Date",
            "Duration",
            "Billable",
            "Status",
            "Note",
            "Created_at",
            "Approved_at",
            "Updated_at",
            "Category",
            "CreatedBy",
            "Approved_by",
          ];

          // Add Group Header Row and Apply Border
          const groupHeaderRow = groupSheet.addRow(groupHeaders);
          applyBorder(groupHeaderRow);
          groupHeaderRow.eachCell((cell) => {
            cell.style.font = { bold: true }; // Bold font for group headers
          });

          // Add Group Data Rows and Apply Border
          let groupTotalDuration = 0;
          groupData.forEach((timesheet, index) => {
            const row = groupSheet.addRow(
              Object.values(formattedTimesheets[index])
            );
            applyBorder(row);

            // Sum up the durations in minutes for the group
            groupTotalDuration += parseTimeToMinutes(timesheet.duration);
          });

          // Add Total Duration Row for the Group in hh:mm format
          const groupTotalDurationRow = groupSheet.addRow([
            "Total Duration",
            formatMinutesToTime(groupTotalDuration),
          ]);
          applyBorder(groupTotalDurationRow);
        });
      }

      // Write and save the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "timesheets.xlsx");
      });
    };
  };

  const handleFilterChange = (newFilters) => {
    const updatedFilters = { ...filters, ...newFilters };

    setFilters(updatedFilters);
    fetchTimesheets(updatedFilters); // Pass the updated filters to the fetch function
  };
  const handleClearFilters = () => {
    fetchTimesheets({});
    setFilters({
      user: { value: "", condition: "" },
      userFilterType: "contains",
      logTitle: "",
      logTitleFilterType: "contains",
      logCategory: "",
      logCategoryFilterType: "",
      date: "",
      fromDate: "",
      toDate: "",
      dateFilterType: "range",
      billable: "",
      approvalstatus: "",
      admin: { value: "", condition: "" },
    });

    // Use callback to ensure you're working with the updated state
    setIsFilterActive(false);
  };
  useEffect(() => {
    fetchTimesheets();
  }, [filters]);

  useEffect(() => {
    // Check each filter individually to determine its active status
    const isUserFilterActive = filters.user && filters.user.value;
    const isDateFilterActive = filters.date;
    const isLogTitleFilterActive = filters.logTitle;
    const isFromDateFilterActive = filters.fromDate;
    const isToDateFilterActive = filters.toDate;
    const isLogCategoryFilterActive = filters.logCategory;
    const isBillableFilterActive = filters.billable;
    const isApprovalStatusFilterActive = filters.approvalstatus;
    const isAdminFilterActive = filters.admin && filters.admin.value.length > 0;

    // Determine if any filter is active
    const hasActiveFilters =
      isUserFilterActive ||
      isDateFilterActive ||
      isLogTitleFilterActive ||
      isFromDateFilterActive ||
      isToDateFilterActive ||
      isLogCategoryFilterActive ||
      isBillableFilterActive ||
      isApprovalStatusFilterActive ||
      isAdminFilterActive;

    setIsFilterActive(hasActiveFilters);
  }, [filters]);

  const calculateSummary = (timesheets) => {
    return timesheets.reduce(
      (summary, timesheet) => {
        const durationMinutes = parseDuration(timesheet.duration);

        if (timesheet.billable === 1) {
          summary.billable += durationMinutes;
        } else {
          summary.nonBillable += durationMinutes;
        }

        if (timesheet.approvalstatus === "Approved") {
          summary.approved += durationMinutes;
        } else {
          summary.unapproved += durationMinutes;
        }

        return summary;
      },
      {
        billable: 0,
        nonBillable: 0,
        approved: 0,
        unapproved: 0,
      }
    );
  };

  // Grouping functions
  const groupByUser = (timesheets) => {
    return timesheets.reduce((groups, timesheet) => {
      const user = timesheet.user_name;
      if (!groups[user]) {
        groups[user] = [];
      }
      groups[user].push(timesheet);
      return groups;
    }, {});
  };
  const groupByProject = (timesheets) => {
    return timesheets.reduce((groups, timesheet) => {
      const project = timesheet.projectno;
      if (!groups[project]) {
        groups[project] = [];
      }
      groups[project].push(timesheet);
      return groups;
    }, {});
  };

  const groupByDate = (timesheets) => {
    const grouped = timesheets.reduce((groups, timesheet) => {
      const date = new Date(timesheet.date).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(timesheet);
      return groups;
    }, {});

    const sortedDates = Object.keys(grouped).sort((a, b) => {
      return sortOrder === "asc"
        ? new Date(a) - new Date(b)
        : new Date(b) - new Date(a);
    });

    return sortedDates.reduce((sortedGroups, date) => {
      sortedGroups[date] = grouped[date];
      return sortedGroups;
    }, {});
  };

  const groupByCategory = (timesheets) => {
    return timesheets.reduce((groups, timesheet) => {
      const category = timesheet.variation_id
        ? "Variation"
        : timesheet.issue_id
        ? "Issue"
        : "General";
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(timesheet);
      return groups;
    }, {});
  };

  const updateApprovalStatus = async (timesheetId, newStatus) => {
    try {
      await api.put(`/api/timesheettable/${timesheetId}/${userId}/approval`, {
        status: newStatus,
      });
      fetchTimesheets(); // Refresh the data after update
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  // Bulk update the approval status for selected timesheets
  const bulkUpdateApprovalStatus = async (selectedTimesheets, newStatus) => {
    // Extract just the 'id' values from the selectedTimesheets
    const timesheetIds = selectedTimesheets.map((timesheet) => timesheet.id);
    try {
      // Build the payload with extracted timesheet IDs and the new status
      const payload = {
        timesheetIds: timesheetIds,
        approvalstatus: newStatus,
        userId: userId,
      };
      toast.success("Timesheets status updated successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      const response = await api.put(`/api/updatetimesheets`, payload);

      setTimeout(() => {
        fetchTimesheets();
      }, 1500);
    } catch (error) {
      // Log the entire error response for better clarity
      console.error(
        "Failed to update status:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to update timesheet statuses. Please try again.");
    } finally {
      SetBulkUpdate(false); // Hide bulk update modal or reset state
      setSelectedTimesheets(""); // Clear the selected timesheets
    }
  };

  const deleteSelectedTimesheets = async () => {
    const selectedTimesheetIds = selectedTimesheets.map((ts) => ts.id);
    try {
      const response = await api.delete("/api/timesheets", {
        headers: {
          "Content-Type": "application/json",
        },
        data: { ids: selectedTimesheetIds },
      });
      if (response.status === 204) {
        toast.success("Timesheet successfully deleted", {
          position: "bottom-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          fetchTimesheets(); // Refresh data after adding new timesheet
        }, 1000);
        setSelectedTimesheets("");
        setDeleteTimesheetDial(false);
        setIsCheckboxChecked(false);
      } else {
        console.error("Failed to delete timesheets", response.data);
      }
    } catch (error) {
      console.error("Error deleting timesheets", error);
    }
  };

  const getGroupedTimesheets = (timesheets, groupBy) => {
    switch (groupBy) {
      case "user":
        return groupByUser(timesheets);
      case "project":
        return groupByProject(timesheets);
      case "phase":
        return groupByProject(timesheets);
      case "date":
        return groupByDate(timesheets);
      case "category":
        return groupByCategory(timesheets);
      default:
        return {};
    }
  };

  const parseDuration = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  };

  const groupedByPhase = timesheets.reduce((phases, timesheet) => {
    let phase;
    switch (groupBy) {
      case "user":
        phase = timesheet.user_name;
        filterByValue = "User";
        break;
      case "date":
        phase = format(timesheet.date, "dd-MM-yyyy");
        filterByValue = "Date";
        break;
      case "category":
        phase = timesheet.category;
        filterByValue = "Category";
        break;
      case "phase":
        phase = `${timesheet.projectno} - ${timesheet.project_name} - ${timesheet.phase_no}`;
        filterByValue = "Phase";
        break;
      default:
        phase = `${timesheet.projectno} - ${timesheet.project_name}`;
        filterByValue = "Project";
    }

    if (!phases[phase]) {
      phases[phase] = [];
    }
    phases[phase].push(timesheet);
    return phases;
  }, {});

  // const groupedByPhase = timesheets.reduce((projects, timesheet) => {
  //   // Define the group by key
  //   let project;
  //   let phase;

  //   switch (groupBy) {
  //     case "user":
  //       project = timesheet.user_name;
  //       phase = `${timesheet.projectno} - ${timesheet.project_name}`;
  //       break;
  //     case "date":
  //       project = format(timesheet.date, "dd-MM-yyyy");
  //       phase = `${timesheet.projectno} - ${timesheet.project_name}`;
  //       break;
  //     default:
  //       project = timesheet.project_name; // Group by project name
  //       phase = timesheet.phase_title; // Phase is still based on phase_title
  //   }

  //   // Ensure the project exists in the grouping object
  //   if (!projects[project]) {
  //     projects[project] = {}; // Initialize as an object to store phases for each project
  //   }

  //   // Ensure the phase exists in the project
  //   if (!projects[project][phase]) {
  //     projects[project][phase] = [];
  //   }

  //   // Add the timesheet to the correct phase under the correct project
  //   projects[project][phase].push(timesheet);

  //   return projects;
  // }, {});

  const overallSummary = Object.keys(groupedByPhase).reduce(
    (overall, phaseTitle) => {
      const phaseSummary = calculateSummary(groupedByPhase[phaseTitle]);
      overall.billable += phaseSummary.billable;
      overall.nonBillable += phaseSummary.nonBillable;
      overall.approved += phaseSummary.approved;
      overall.unapproved += phaseSummary.unapproved;
      return overall;
    },
    {
      billable: 0,
      nonBillable: 0,
      approved: 0,
      unapproved: 0,
    }
  );

  const handleDeleteTimesheet = (e, selectedTimesheet) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedTimesheets((prevSelected) => [
        ...prevSelected,
        selectedTimesheet,
      ]);
    } else {
      setSelectedTimesheets((prevSelected) =>
        prevSelected.filter(
          (timesheet) => timesheet.id !== selectedTimesheet.id
        )
      );
    }
  };

  useEffect(() => {
    fetchTimesheets();
  }, [projectId, userDetails]);

  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <ToastContainer />
      {!dialBoxopen && (
        <div
          className={
            isNavBarOpen
              ? "main-container overview-main-container"
              : "main-container-close overview-main-container"
          }
          // style={{ marginTop: "-82px" }}
        >
          {bulkUpdate && selectedTimesheets.length > 0 && (
            <div className="bulk-approval" ref={profileRef}>
              {hasPermission("timesheets", "delete") && (
                <>
                  {hasPermission("timesheets", "update") && (
                    <h3 className="bulk-approval-header">Timesheet Status</h3>
                  )}
                  {hasPermission("timesheets", "update") && (
                    <select
                      className="bulk-approval-dropdown"
                      onChange={(e) =>
                        bulkUpdateApprovalStatus(
                          selectedTimesheets,
                          e.target.value
                        )
                      }
                      style={{
                        cursor: !hasPermission("timesheets", "update")
                          ? "not-allowed"
                          : "pointer",
                      }}
                      disabled={!hasPermission("timesheets", "update")}
                    >
                      <option
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                        value=""
                      >
                        Select Status
                      </option>
                      <option
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                        value="Pending"
                      >
                        Pending
                      </option>
                      <option
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                        value="Approved"
                      >
                        Approved
                      </option>
                      <option
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                        value="Rejected"
                      >
                        Rejected
                      </option>
                    </select>
                  )}
                  <button
                    onClick={() => setDeleteTimesheetDial(true)}
                    className={
                      hasPermission("projects", "delete")
                        ? "admin-with-export"
                        : "user-without-export"
                    }
                    style={{
                      backgroundColor: "red",
                      marginTop: "6px",
                      height: "28px",
                      fontWeight: "700",
                      width: "117px",
                      marginLeft: "19px",
                      marginTop: "10px",
                    }}
                    title="Delete Timesheet"
                  >
                    Delete
                  </button>
                </>
              )}
            </div>
          )}
          <div className="filter-categories">
            <select
              className="select-group-by"
              value={groupBy}
              onChange={(e) => setGroupBy(e.target.value)}
            >
              <option value="project">Group By Project</option>
              <option value="phase">Group By Phase</option>
              <option value="user">Group By User</option>
              <option value="date"> Group By Date</option>
              <option value="category"> Group By Category</option>
            </select>

            {isFilterActive && (
              <button
                className="clear-filter-btn clear-btn-overview"
                onClick={handleClearFilters}
                title="Clear Filters"
              >
                Clear Filters
              </button>
            )}
            <div
              className={
                isNavBarOpen
                  ? "overview-add-filter"
                  : "overview-add-filter-close"
              }
            >
              {hasPermission("timesheets", "update") && (
                <MdOutlineDateRange
                  className="users-timesheet"
                  onClick={() => setUserTimesheetDial(true)}
                />
              )}
              <FilterTimesheetOverview refresh={handleFilterChange} />
            </div>
            <button
              onClick={exportToExcel(timesheets, groupBy)}
              className="export-btn"
              style={{ marginRight: isNavBarOpen ? "141px" : "86px" }}
              title="Export to excel"
            >
              Export to Excel
            </button>
          </div>
          {selectedTimesheets.length > 0 &&
            hasPermission("timesheets", "delete") && (
              <button
                className="bulk-update-overview-btn"
                onClick={() => SetBulkUpdate((prev) => !prev)}
              >
                Mass Update
              </button>
            )}
          {timesheets.length === 0 && (
            <>
              <img className="timesheet-image" src="/AppLayout/Timesheet.png" />
              <p className="timesheet-message-t">No timesheets available.</p>
            </>
          )}
          <div
            className={
              isNavBarOpen ? "overall-summary" : "overall-summary-close"
            }
          >
            <h3
              className="over-all-summary-header"
              style={{ marginTop: "5px", backgroundColor: "transparent" }}
            >
              Overall Summary :
            </h3>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "600px",
              }}
            >
              Billable :{" "}
              <span className="billable">
                {" "}
                {formatDuration(overallSummary.billable)}
              </span>
            </p>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "600px",
              }}
            >
              Non-Billable :{" "}
              <span className="non-billable">
                {" "}
                {formatDuration(overallSummary.nonBillable)}
              </span>
            </p>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "600px",
              }}
            >
              Approved :{" "}
              <span className="approved">
                {formatDuration(overallSummary.approved)}
              </span>
            </p>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "600px",
              }}
            >
              Unapproved:{" "}
              <span className="un-approved">
                {formatDuration(overallSummary.unapproved)}
              </span>
            </p>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "bold",
              }}
            >
              Total:{" "}
              <span
                className="un-approved"
                style={{ marginTop: "2px", fontWeight: "bold", color: "black" }}
              >
                {formatDuration(
                  overallSummary.billable + overallSummary.nonBillable
                )}
              </span>
            </p>
          </div>
          <div
            className={
              isNavBarOpen
                ? "overview-scrolling-content"
                : "overview-scrolling-content-close"
            }
          >
            {Object.keys(groupedByPhase)
              .sort((a, b) => {
                // Convert string dates to Date objects
                const dateA = new Date(a.split("-").reverse().join("-"));
                const dateB = new Date(b.split("-").reverse().join("-"));

                return dateB - dateA; // Sort in descending order (latest date first)
              })
              .map((phaseTitle) => {
                const groupedTimesheets = getGroupedTimesheets(
                  groupedByPhase[phaseTitle],
                  groupBy
                );
                const phaseSummary = calculateSummary(
                  groupedByPhase[phaseTitle]
                );
                const isSinglePhase = Object.keys(groupedByPhase).length === 1;
                return (
                  <div
                    key={phaseTitle}
                    style={{
                      width: isNavBarOpen
                        ? isSinglePhase
                          ? "95%"
                          : "97%"
                        : isSinglePhase
                        ? "99%"
                        : "99.5%",
                    }}
                    className={
                      isSinglePhase
                        ? "single-phase additional"
                        : "table-container-timesheet"
                    }
                  >
                    {" "}
                    <h3 className="timesheet-phase-header">
                      <span className="timesheet-phase-details">
                        {phaseTitle}
                      </span>
                    </h3>
                    <div
                      className={
                        isSinglePhase
                          ? "single-summary additional-summary"
                          : "phase-summary"
                      }
                    >
                      <h3
                        className="category-summary"
                        style={{ marginTop: "2px", fontWeight: "500" }}
                      >
                        {filterByValue} Summary : {""}
                      </h3>
                      <p className="billable" style={{ marginTop: "2px" }}>
                        {formatDuration(phaseSummary.billable)}
                      </p>
                      <p className="non-billable" style={{ marginTop: "2px" }}>
                        {formatDuration(phaseSummary.nonBillable)}
                      </p>
                      <p className="approved" style={{ marginTop: "2px" }}>
                        {formatDuration(phaseSummary.approved)}
                      </p>
                      <p className="un-approved" style={{ marginTop: "2px" }}>
                        {formatDuration(phaseSummary.unapproved)}
                      </p>
                      <p
                        className="total"
                        style={{ marginTop: "2px", fontWeight: "bold" }}
                      >
                        {formatDuration(
                          phaseSummary.billable + phaseSummary.nonBillable
                        )}
                      </p>
                    </div>
                    {Object.keys(groupedTimesheets).map((key, index) => (
                      <div
                        key={key}
                        style={{ marginBottom: "-25px" }}
                        className="table-parent"
                      >
                        <table className="timesheet-phase-table">
                          <thead>
                            <tr>
                              {hasPermission("timesheets", "delete") && (
                                <th
                                  style={{
                                    minWidth: "2px",
                                    textAlign: "center",
                                  }}
                                >
                                  <FaChevronDown />
                                </th>
                              )}
                              <th style={{ minWidth: "60px" }}>
                                <span className="table-icons">
                                  <MdNumbers />
                                  <p className="table-header">S.No</p>
                                </span>
                              </th>
                              <th>
                                <span className="table-icons">
                                  <MdOutlineSubtitles
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header">Log Title</p>
                                </span>
                              </th>
                              {(groupBy === "project" ||
                                groupBy === "phase") && (
                                <th style={{ minWidth: "100px" }}>
                                  <span className="table-icons">
                                    <IoCalendarOutline
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header"> Date</p>
                                  </span>
                                </th>
                              )}
                              {(groupBy === "project" ||
                                groupBy === "phase") && (
                                <th style={{ minWidth: "110px" }}>
                                  <span className="table-icons">
                                    <MdOutlineCategory
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header">Category</p>
                                  </span>
                                </th>
                              )}
                              {groupBy === "project" && (
                                <>
                                  <th style={{ minWidth: "120px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">Phase No</p>
                                    </span>
                                  </th>
                                  <th style={{ minWidth: "130px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">
                                        Phase Title
                                      </p>
                                    </span>
                                  </th>
                                </>
                              )}
                              {groupBy === "user" && (
                                <th style={{ minWidth: "100px" }}>
                                  <span className="table-icons">
                                    <IoCalendarOutline
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header"> Date</p>
                                  </span>
                                </th>
                              )}
                              {(groupBy === "project" ||
                                groupBy === "phase") && (
                                <th style={{ minWidth: "150px" }}>
                                  <span className="table-icons">
                                    <CgProfile
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header">User</p>
                                  </span>
                                </th>
                              )}
                              {groupBy === "user" && (
                                <th style={{ minWidth: "100px" }}>
                                  <span className="table-icons">
                                    <MdOutlineCategory
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header">Category</p>
                                  </span>
                                </th>
                              )}
                              {groupBy === "date" && (
                                <th style={{ minWidth: "150px" }}>
                                  <span className="table-icons">
                                    <CgProfile
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header">User</p>
                                  </span>
                                </th>
                              )}
                              {groupBy === "date" && (
                                <th style={{ minWidth: "100px" }}>
                                  <span className="table-icons">
                                    <MdOutlineCategory
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header">Category</p>
                                  </span>
                                </th>
                              )}
                              {groupBy === "date" && (
                                <>
                                  <th style={{ minWidth: "120px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">Phase No</p>
                                    </span>
                                  </th>
                                  <th style={{ minWidth: "130px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">
                                        Phase Title
                                      </p>
                                    </span>
                                  </th>
                                </>
                              )}
                              {groupBy === "category" && (
                                <th style={{ minWidth: "150px" }}>
                                  {" "}
                                  <span className="table-icons">
                                    <CgProfile
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header">User</p>
                                  </span>
                                </th>
                              )}
                              {groupBy === "user" && (
                                <>
                                  <th style={{ minWidth: "120px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">Phase No</p>
                                    </span>
                                  </th>
                                  <th style={{ minWidth: "130px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">
                                        Phase Title
                                      </p>
                                    </span>
                                  </th>
                                </>
                              )}
                              {groupBy === "category" && (
                                <th style={{ minWidth: "100px" }}>
                                  <span className="table-icons">
                                    <IoCalendarOutline
                                      style={{
                                        height: "14px",
                                        width: "30px",
                                      }}
                                    />{" "}
                                    <p className="table-header">Date</p>
                                  </span>
                                </th>
                              )}
                              {groupBy === "category" && (
                                <>
                                  <th style={{ minWidth: "120px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">Phase No</p>
                                    </span>
                                  </th>
                                  <th style={{ minWidth: "130px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">
                                        Phase Title
                                      </p>
                                    </span>
                                  </th>
                                </>
                              )}
                              {groupBy !== "project" && (
                                <>
                                  <th style={{ minWidth: "140px" }}>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">Project No</p>
                                    </span>
                                  </th>
                                  <th>
                                    <span className="table-icons">
                                      <CgRename
                                        style={{
                                          height: "14px",
                                          width: "30px",
                                        }}
                                      />{" "}
                                      <p className="table-header">
                                        Project Name
                                      </p>
                                    </span>
                                  </th>
                                </>
                              )}
                              {/* <th>
                            <span className="table-icons">
                              <BiTask
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Task</p>
                            </span>
                          </th> */}
                              <th style={{ minWidth: "120px" }}>
                                <span className="table-icons">
                                  <IoTimerOutline
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header"> Duration</p>
                                </span>
                              </th>
                              <th style={{ minWidth: "100px" }}>
                                <span className="table-icons">
                                  <SiTyper
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header">Billable</p>
                                </span>
                              </th>
                              <th style={{ minWidth: "100px" }}>
                                <span className="table-icons">
                                  <GrStatusGood
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header">Status</p>
                                </span>
                              </th>
                              <th style={{ minWidth: "120px" }}>
                                <span className="table-icons">
                                  <TbNotes
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header">Notes</p>
                                </span>
                              </th>
                              <th style={{ minWidth: "150px" }}>
                                <span className="table-icons">
                                  <MdOutlineCreateNewFolder
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header"> Created By</p>
                                </span>
                              </th>
                              <th style={{ minWidth: "130px" }}>
                                <span className="table-icons">
                                  <LiaBusinessTimeSolid
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header"> Created At</p>
                                </span>
                              </th>
                              <th style={{ minWidth: "150px" }}>
                                <span className="table-icons">
                                  <GrUserExpert
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header"> Approved By</p>
                                </span>{" "}
                              </th>
                              <th style={{ minWidth: "137px" }}>
                                <span className="table-icons">
                                  <MdCreditScore
                                    style={{
                                      height: "14px",
                                      width: "30px",
                                    }}
                                  />{" "}
                                  <p className="table-header">Approved At</p>
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {groupedTimesheets[key].length > 0 ? (
                              groupedTimesheets[key]
                                .sort((a, b) => b.id - a.id)
                                .map((timesheet, index) => (
                                  <tr key={timesheet.id}>
                                    {hasPermission("timesheets", "delete") && (
                                      <td>
                                        <input
                                          type="checkbox"
                                          name={timesheet.name}
                                          style={{
                                            cursor:
                                              timesheet.approvalstatus ===
                                              "Approved"
                                                ? "not-allowed"
                                                : "pointer",
                                          }}
                                          onChange={(e) =>
                                            handleDeleteTimesheet(e, timesheet)
                                          }
                                          disabled={
                                            timesheet.approvalstatus ===
                                            "Approved"
                                          }
                                        />
                                      </td>
                                    )}
                                    <td style={{ textAlign: "center" }}>
                                      {index + 1}
                                    </td>
                                    <td>
                                      {timesheet.logtitle
                                        ? timesheet.logtitle
                                        : "Not Available"}
                                    </td>
                                    {groupBy === "user" && (
                                      <>
                                        <td>
                                          {format(timesheet.date, "dd-MM-yyyy")}
                                        </td>
                                        <td>
                                          {timesheet.variation_id
                                            ? "Variation"
                                            : timesheet.issue_id
                                            ? "Issue"
                                            : "General"}
                                        </td>
                                        <td>{timesheet.phase_no}</td>
                                        <td>{timesheet.phase_title}</td>
                                      </>
                                    )}
                                    {groupBy === "project" && (
                                      <>
                                        <td>
                                          {format(timesheet.date, "dd-MM-yyyy")}
                                        </td>
                                        <td>
                                          {timesheet.variation_id
                                            ? "Variation"
                                            : timesheet.issue_id
                                            ? "Issue"
                                            : "General"}
                                        </td>
                                        <td>{timesheet.phase_no}</td>
                                        <td>{timesheet.phase_title}</td>
                                        <td>{timesheet.user_name}</td>
                                      </>
                                    )}
                                    {groupBy === "phase" && (
                                      <>
                                        <td>
                                          {format(timesheet.date, "dd-MM-yyyy")}
                                        </td>
                                        <td>
                                          {timesheet.variation_id
                                            ? "Variation"
                                            : timesheet.issue_id
                                            ? "Issue"
                                            : "General"}
                                        </td>
                                        <td>{timesheet.user_name}</td>
                                      </>
                                    )}
                                    {groupBy === "date" && (
                                      <>
                                        <td>{timesheet.user_name}</td>
                                        <td>
                                          {timesheet.variation_id
                                            ? "Variation"
                                            : timesheet.issue_id
                                            ? "Issue"
                                            : "General"}
                                        </td>
                                        <td>{timesheet.phase_no}</td>
                                        <td>{timesheet.phase_title}</td>
                                      </>
                                    )}
                                    {groupBy === "category" && (
                                      <>
                                        <td>{timesheet.user_name}</td>
                                        <td>
                                          {format(timesheet.date, "dd-MM-yyyy")}
                                        </td>
                                        <td>{timesheet.phase_no}</td>
                                        <td>{timesheet.phase_title}</td>
                                      </>
                                    )}
                                    {groupBy !== "project" &&
                                      groupBy !== "Phase" && (
                                        <>
                                          <td>{timesheet.projectno}</td>
                                          <td>{timesheet.project_name}</td>
                                        </>
                                      )}
                                    {/* <td>{timesheet.task_name}</td> */}
                                    <td
                                      style={{
                                        color: timesheet.billable
                                          ? "#3acce6"
                                          : "#ee9c29",
                                        fontWeight: "700",
                                        textAlign: "center",
                                      }}
                                    >
                                      {formatTime(timesheet.duration)}
                                    </td>
                                    <td
                                      style={{
                                        color: timesheet.billable
                                          ? "#3acce6"
                                          : "#ee9c29",
                                        fontWeight: "550",
                                      }}
                                    >
                                      {timesheet.billable === 1
                                        ? "Billable"
                                        : "Non Billable"}
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          timesheet.approvalstatus ===
                                          "Approved"
                                            ? "white"
                                            : timesheet.approvalstatus ===
                                              "Rejected"
                                            ? "White"
                                            : timesheet.approvalstatus ===
                                              "Pending"
                                            ? "White"
                                            : "white",
                                        // minWidth: "200px",
                                      }}
                                    >
                                      <select
                                        className="status-dropdown"
                                        value={timesheet.approvalstatus}
                                        onChange={(e) =>
                                          updateApprovalStatus(
                                            timesheet.id,
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          backgroundColor:
                                            timesheet.approvalstatus ===
                                            "Approved"
                                              ? "#7ace4c"
                                              : timesheet.approvalstatus ===
                                                "Rejected"
                                              ? "#fa2929"
                                              : timesheet.approvalstatus ===
                                                "Pending"
                                              ? "#78909c"
                                              : "#78909c",
                                          color:
                                            timesheet.approvalstatus ===
                                            "Approved"
                                              ? "white"
                                              : timesheet.approvalstatus ===
                                                "Rejected"
                                              ? "White"
                                              : timesheet.approvalstatus ===
                                                "Pending"
                                              ? "White"
                                              : "white",
                                          cursor: !hasPermission(
                                            "timesheets",
                                            "update"
                                          )
                                            ? "not-allowed"
                                            : "pointer",
                                          position: "relative",

                                          width: "100px",
                                        }}
                                        disabled={
                                          !hasPermission("timesheets", "update")
                                        }
                                      >
                                        <option
                                          style={{
                                            backgroundColor: "white",
                                            color: "black",
                                          }}
                                          value="Pending"
                                        >
                                          Pending
                                        </option>
                                        <option
                                          style={{
                                            backgroundColor: "white",
                                            color: "black",
                                          }}
                                          value="Approved"
                                        >
                                          Approved
                                        </option>
                                        <option
                                          style={{
                                            backgroundColor: "white",
                                            color: "black",
                                          }}
                                          value="Rejected"
                                        >
                                          Rejected
                                        </option>
                                      </select>
                                    </td>
                                    <td title={timesheet.notes}>
                                      {timesheet.notes
                                        ? timesheet.notes.substring(0, 30)
                                        : ""}
                                    </td>
                                    <td>{timesheet.creator_name}</td>
                                    <td>
                                      {timesheet.created_at
                                        ? format(
                                            timesheet.created_at,
                                            "dd-MM-yyyy"
                                          )
                                        : "Not Available"}
                                    </td>
                                    <td>
                                      {timesheet.approver_name
                                        ? timesheet.approver_name
                                        : "Not Available"}
                                    </td>
                                    <td>
                                      {timesheet.approved_at &&
                                      timesheet.approved_at !== "NULL"
                                        ? format(
                                            timesheet.approved_at,
                                            "dd-MM-yyyy"
                                          )
                                        : "Not Approved"}
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <tr>
                                <td colSpan="14">No timesheets available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
          {deleteTimesheetDial && (
            <div className="delete-phase-dial">
              <div className="delete-dial">
                <div className="delete-header">
                  <h3
                    style={{
                      backgroundColor: "transparent",
                      marginLeft: "12px",
                    }}
                  >
                    Delete Timesheet
                  </h3>
                </div>
                <div style={{ backgroundColor: "white" }}>
                  <p style={{ backgroundColor: "white" }}>
                    Are you sure you want to delete this Timesheet? This action
                    cannot be undone.
                  </p>
                  <input
                    style={{ cursor: "pointer" }}
                    type="checkbox"
                    id="delete-phase"
                    checked={isCheckboxChecked}
                    onChange={() => setIsCheckboxChecked((prev) => !prev)}
                  />
                  <label
                    htmlFor="delete-phase"
                    style={{
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                  >
                    I understand this action cannot be undone.
                  </label>
                </div>
                <div className="delete-dial-btn-con">
                  <button
                    color="secondary"
                    variant="contained"
                    onClick={deleteSelectedTimesheets}
                    disabled={!isCheckboxChecked}
                    className="add-btn"
                    style={{
                      backgroundColor: isCheckboxChecked ? "red" : "gray",
                    }}
                  >
                    Delete
                  </button>

                  <button
                    onClick={() => {
                      setDeleteTimesheetDial(false);
                      setIsCheckboxChecked(false);
                    }}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {userTimesheetDial && (
            <div className="usertimesheetdial-con">
              <div className="usertimesheetdial">
                <IoMdClose
                  className="overview-user-timesheet-close"
                  onClick={() => setUserTimesheetDial(false)}
                />
                <Userstimesheet />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TimeSheetTable;
