import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api/axiosConfig";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Please enter your email address");
      return;
    }

    try {
      const frontendURL = window.location.origin;
      const response = await api.post("/forgot-password", {
        email,
        frontendURL,
      });
      setMessage(response.data.message);
      setError("");
    } catch (error) {
      setError("Error sending email. Please try again.");
      console.error("Error sending forgot password email:", error);
    }
  };

  return (
    <div className="main-container-signin">
      <div className="type-email-parent">
        <form onSubmit={handleSubmit}>
          {/* <h2>Forgot Password</h2> */}
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            className="type-email"
          />
          <button type="submit" className="button2" style={{ top: "204px" }}>
            Send Reset Link
          </button>
          {message && (
            <p className="forgot-password1 forgot-success">{message}</p>
          )}
          {error && <p className="forgot-password1 forgot-error">{error}</p>}
        </form>
        <p
          onClick={() => navigate("/")}
          className="forgot-password1 forgot-to-signin"
        >
          Back to Sign In
        </p>
        <img
          className="task-bro-1-icon1"
          alt="man-logo"
          src="/taskbro-1@2x.jpg"
        />
        <img
          className="group-158-1"
          alt="MSES Group"
          src="/group-158-1@2x.png"
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
