import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/axiosConfig";
import { format } from "date-fns";
import { useAuth } from "../Authentication/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../TimeSheet/TimeSheetTable.css";
import { ProjectContext } from "../../App";
import { parseISO } from "date-fns";
import { ToastContainer, toast } from "react-toastify";

const AddTimeSheet = ({ refresh: refreshTrigger, onTimesheetAdded }) => {
  const { dialBoxopen, addTimesheetOpen, setAddTimesheetOpen } =
    useContext(ProjectContext);
  const [timesheets, setTimesheets] = useState([]);
  const { userDetails } = useAuth();
  const userId = userDetails.id;
  const { projectId } = useParams();
  const [loading, setLoading] = useState(true);
  const [taskTemplate, setTaskTemplate] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [phases, setPhases] = useState([]);
  const [logCategory, setLogCategories] = useState([]);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState("");
  const [user, setUser] = useState("");
  const [timeEntryMethod, setTimeEntryMethod] = useState("duration");
  const [durationHours, setDurationHours] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedFromTime, setSelectedFromTime] = useState(null);
  const [selectedToTime, setSelectedToTime] = useState(null);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("");
  const [selectedProjectStartDate, setSelectedProjectStartDate] = useState("");
  const [selectedProjectEndDate, setSelectedProjectEndDate] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedPhaseId, setSelectedPhaseId] = useState(null);
  const [selectedLogCategory, setSelectedLogCategory] = useState("");
  const [selectedTasks, setSelectedTasks] = useState("");
  const [selectedTasksStartDate, setSelectedTasksStartDate] = useState("");
  const [selectedTasksDueDate, setSelectedTasksDueDate] = useState("");
  const [isBillable, setIsBillable] = useState("");
  const [error, setError] = useState(null);
  const [dropdownVariationOptions, setDropdownVariationOptions] = useState([]);
  const [dropdownIssueOptions, setDropdownIssueOptions] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");
  const [usersId, setUsersId] = useState("");
  const [userExistingHoursInMinutes, setUserExistingHoursInMinutes] =
    useState("");
  const userID = userDetails.id;
  useEffect(() => {
    if (selectedTasks && tasks.length > 0) {
      console.log("selected task", selectedTasks);

      const task = tasks.find((t) => t.id === Number(selectedTasks));

      if (task) {
        setSelectedTasksDueDate(task.due_date !== null ? task.due_date : 0);
        setSelectedTasksStartDate(
          task.start_date !== null ? task.start_date : 0
        );
      } else {
        console.warn("No task found for the selected ID:", selectedTasks);
        setSelectedTasksDueDate(0); // Default value or handle as per your needs
        setSelectedTasksStartDate(0);
      }

      console.log("tasks date", task);
    }
  }, [selectedTasks, tasks]);
  function normalizePlannedHours(input) {
    console.log("input", input);
    // If input is a decimal (e.g., "1.5"), convert it to HH:MM format
    if (/^[0-9]+([.][0-9]+)?$/.test(input)) {
      const hours = Math.floor(parseFloat(input));
      const minutes = Math.round((parseFloat(input) - hours) * 60);
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    }

    // If input is in HH:MM format, return it directly
    if (/^[0-9]+:[0-5][0-9]$/.test(input)) {
      return input;
    }

    // If input is a whole number, treat it as hours
    if (/^[0-9]+$/.test(input)) {
      return `${parseInt(input, 10)}:00`;
    }

    // For invalid formats, return an empty string
    return "";
  }

  useEffect(() => {
    const calculateExistingHours = async (user, selectedDate) => {
      console.log(
        "user, selectedDate",
        user,
        selectedDate.toLocaleDateString("en-CA")
      );

      try {
        const response = await api.get(
          `/api/timesheets/${user}/${selectedDate.toLocaleDateString("en-CA")}`
        );
        const userTimesheets = response.data;
        console.log("userTimesheets", userTimesheets);

        let totalMinutes = 0;
        userTimesheets.forEach((timesheet) => {
          const [hours, minutes] = timesheet.duration.split(":").map(Number);
          if (!isNaN(hours) && !isNaN(minutes)) {
            totalMinutes += hours * 60 + minutes;
          }
        });

        let res = (8 * 60 - totalMinutes) / 60; // Convert to hours
        res = normalizePlannedHours(res);

        console.log("Final Remaining Hours:", res);
        setUserExistingHoursInMinutes(res); // Update state once
      } catch (error) {
        console.error("Error fetching existing timesheets:", error);
      }
    };

    if (user && selectedDate) {
      calculateExistingHours(user, selectedDate);
    }
  }, [user, selectedDate]);

  const onClosePopUp = () => {
    setSelectedPhase([]);
    setSelectedLogCategory([]);
    setSelectedTasks([]);
    setSelectedTaskTemplate([]);
    setIsBillable([]);
    setDurationHours([]);
    setSelectedDate(null);
    setUser([]);
    setAddTimesheetOpen(false);
    setUserExistingHoursInMinutes("");
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleFromTime = (timeFrom) => {
    setSelectedFromTime(timeFrom);
  };

  const handleToTime = (timeTo) => {
    setSelectedToTime(timeTo);
  };

  const handlFetchUserId = (e) => {
    setUsersId(e.target.value);
    setUser(e.target.value);
    setSelectedPhase([]);
    setSelectedLogCategory([]);
    setSelectedTasks([]);
    setSelectedTaskTemplate([]);
    setIsBillable([]);
    setDurationHours([]);
    setSelectedDate(null);
    setSelectedProject("");
    setSelectedProjectName("");
    setShowDropdown(false);
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and profile
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) //&&
      // profileRef.current &&  !profileRef.current.contains(event.target)
    ) {
      setShowDropdown(false); // Close the dropdown
    }
  };

  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(e.target.value);
    setSelectedProjectName("");

    const filtered = projects.filter((project) => {
      const name = project.name || ""; // Fallback to an empty string
      const projectno = project.projectno || ""; // Fallback to an empty string
      const displayName = project.displayName || ""; // Fallback to an empty string

      return (
        name.toLowerCase().includes(searchValue) ||
        projectno.toLowerCase().includes(searchValue) ||
        displayName.toLowerCase().includes(searchValue)
      );
    });

    console.log("filtered", filtered);

    setFilteredProjects(filtered);
    setShowDropdown(true); // Show the dropdown when the user types
  };

  const handleFocus = () => {
    setSearchTerm(""); // Reset the search term to show all projects
    setFilteredProjects(projects); // Show all projects when focusing on the input
    setShowDropdown(true); // Show the dropdown
  };

  //update userId for user login
  useEffect(() => {
    if (userDetails.role !== "Administrator") {
      setUser(userDetails.id); // Set the current user's ID in state if not admin
    }
  }, [userDetails, setUser]);

  const handleTaskTemplateChange = (event) => {
    setSelectedTaskTemplate(event.target.value);
    setSelectedTasks([]);
    setSelectedDate(null);
  };

  const handlelogcategoryChanger = (e) => {
    setSelectedLogCategory(e.target.value);
    setSelectedTaskTemplate([]);
    setSelectedTasks([]);
    setTasks([]);
    setSelectedDate(null);
  };

  const handleRadioChange = (event) => {
    setIsBillable(event.target.value);
  };

  //fetch phase changes
  const handlePhaseChange = (event) => {
    const phaseId = event.target.value;
    setSelectedPhase(phaseId);
    const phase = selectedProject?.phases?.find(
      (p) => p.id === parseInt(phaseId)
    );
    if (phase) {
      setSelectedPhaseId(phase.id);
    } else {
      setSelectedPhaseId(null);
    }
    // Clear selected task template and tasks when phase changes
    setSelectedTaskTemplate("");
    setTasks([]);
  };
  //fetch users, category , tasktemplate
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersRes, logCategories] = await Promise.all([
          api.get(`/api/users`),
          api.get("/api/categories"),
        ]);
        const activeUsers = usersRes.data.filter(
          (member) => member.status !== "Inactive"
        );
        setUsers(activeUsers);

        setLogCategories(logCategories.data);

        if (selectedPhase) {
          const taskTemplatesRes = await api.get(
            `/api/phase/${selectedPhase}/taskTemplates`
          );
          setTaskTemplate(taskTemplatesRes.data);
        } else {
          setTaskTemplate([]); // Clear task templates if no phase is selected
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedPhase, projectId]); // Include selectedPhase as a dependency

  //fetch project details
  useEffect(() => {
    const fetchProject = async () => {
      const id = userDetails.role_name === "Administrator" ? usersId : userID;

      try {
        const endpoint = `/api/aprojects/${id}`;
        const res = await api.get(endpoint);
        const filteredProject = res.data.filter(
          (project) => project.activestatus === 1
        );
        setProjects(filteredProject);
        console.log("project data", res.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        console.log(error);
        setLoading(false);
      }
    };
    const id = userDetails.role_name === "Administrator" ? usersId : userID;
    if (id) {
      fetchProject();
    }
  }, [userDetails.role, userID, usersId]);

  //fetch timesheetData
  const fetchTimesheetData = async () => {
    try {
      const timestamp = new Date().getTime();
      const endpoint =
        userDetails.role === "Administrator"
          ? `/api/timesheets/projects/${projectId}`
          : `/api/timesheets/${projectId}/${userId}`;

      const timesheetRes = await api.get(endpoint);
      let timesheets = timesheetRes.data;

      // Step 3: Group timesheets by phase_id
      const groupedByPhase = timesheets.reduce((acc, timesheet) => {
        const phaseId = timesheet.phase_id;
        if (!acc[phaseId]) {
          acc[phaseId] = [];
        }
        acc[phaseId].push(timesheet);
        return acc;
      }, {});

      // Step 4: Fetch phase details for each phaseId
      const phaseIds = Object.keys(groupedByPhase);
      const phaseDetailsPromises = phaseIds.map((phaseId) =>
        api.get(`/api/phase/${phaseId}`)
      );
      const phaseDetailsResponses = await Promise.all(phaseDetailsPromises);
      const phaseDetailsMap = phaseDetailsResponses.reduce((acc, response) => {
        const phase = response.data;
        acc[phase.id] = phase;
        return acc;
      }, {});

      // Step 5: Create a set of unique user IDs for the approvers
      const approverIds = new Set(
        timesheets.map((timesheet) => timesheet.approved_by)
      );

      // Step 6: Fetch user details for all approvers
      const usersRes = await api.get(`/api/users`, {
        params: { ids: Array.from(approverIds) },
      });
      const users = usersRes.data.reduce((acc, user) => {
        acc[user.id] = user.name;
        return acc;
      }, {});

      let approvedMinutesTotal = 0;
      let billableMinutesTotal = 0;
      let nonBillableMinutesTotal = 0;

      // Step 7: Loop through filtered timesheets and calculate duration
      const adjustedDataByPhase = Object.entries(groupedByPhase).map(
        ([phaseId, phaseTimesheets]) => {
          const adjustedTimesheets = phaseTimesheets.map((timesheet) => {
            const date = new Date(timesheet.date);

            // Handle duration in HH:MM:SS format
            const [hours, minutes] = timesheet.duration.split(":").map(Number);
            const totalMinutes = (hours || 0) * 60 + (minutes || 0);

            if (timesheet.approvalstatus === "Approved") {
              approvedMinutesTotal += totalMinutes;
            }

            if (timesheet.billable) {
              billableMinutesTotal += totalMinutes;
            } else {
              nonBillableMinutesTotal += totalMinutes;
            }

            return {
              ...timesheet,
              date: date.toLocaleDateString(),
              approved_by:
                users[timesheet.approved_by] || "Waiting for approval",
              hours, // Updated field to display duration in "xx hours xx minutes" format
            };
          });

          return {
            phaseId,
            phaseDetails: phaseDetailsMap[phaseId] || {}, // Fallback to an empty object if phase details are missing
            timesheets: adjustedTimesheets,
          };
        }
      );

      // Set approved, billable, and non-billable hours and minutes
      setTimesheets(adjustedDataByPhase);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching timesheets:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTimesheetData();
  }, [refreshTrigger, projectId, userDetails, userId]);
  useEffect(() => {
    if (selectedTaskTemplate) {
      const fetchTasks = async () => {
        try {
          // const res = await api.get(
          //   `/api/tasks-timesheet/${selectedTaskTemplate}`
          // );
          const taskUser =
            userDetails.role_name === "Administrator" ? user : userId;
          console.log("task user", taskUser);
          const res = await api.get(
            `/api/tasks-timesheet/${selectedTaskTemplate}/${taskUser}`
          );
          setTasks(res.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchTasks();
    }
  }, [selectedTaskTemplate]);

  const handleProjectChange = (e) => {
    setSearchTerm("");
    setSelectedProjectStatus("");
    setShowDropdown(false);
    setSelectedProject(e.target.value); // Sets selected project
    setSelectedPhase(""); // Reset phase when project changes
    setSelectedPhase([]);
    setSelectedLogCategory([]);
    setSelectedTasks([]);
    setSelectedTaskTemplate([]);
    setIsBillable([]);
    setDurationHours([]);
    setSelectedDate(null);
    setTasks([]);
  };

  useEffect(() => {
    if (selectedProject) {
      console.log("selected project id", selectedProject);
      const project = projects.find((p) => p.id === Number(selectedProject));
      setSelectedProjectStatus(project.tags);
      setSelectedProjectName(project.name);
      console.log("project", project);
      if (project) {
        setSelectedProjectStartDate(parseISO(project.start_date));
        setSelectedProjectEndDate(parseISO(project.end_date));
        console.log(
          "dates",
          parseISO(project.start_date),
          parseISO(project.end_date)
        );
      }
    }
  }, [selectedProject, projects]);

  //fetch phases based on project ID
  useEffect(() => {
    const fetchPhases = async () => {
      if (selectedProject) {
        try {
          const res = await api.get(`/api/project/${selectedProject}/phases`);
          setPhases(res.data); // Assuming res.data is the list of phases
        } catch (error) {
          console.error("Error fetching phases:", error);
        }
      }
    };
    fetchPhases();
  }, [selectedProject]);

  // general || variation || issue header
  let headingText;
  switch (selectedLogCategory) {
    case "1":
      headingText = "General";
      break;
    case "2":
      headingText = "Variations";
      break;
    case "4":
      headingText = "Issues";
      break;
    default:
      headingText = "Title";
  }

  //fetch variation and issue dropdown options
  useEffect(() => {
    let fetchData;

    switch (headingText) {
      case "Variations":
        fetchData = async () => {
          try {
            const res = await api.get(`/api/phase/${selectedPhase}/variations`);
            const filterDate = res.data.filter(
              (v) => v.variation_status === "Approved"
            );
            setDropdownVariationOptions(filterDate);
          } catch (error) {
            console.error(error);
          }
        };
        break;
      case "Issues":
        fetchData = async () => {
          try {
            const res = await api.get(`/api/phase/${selectedPhase}/issues`);

            setDropdownIssueOptions(res.data);
          } catch (error) {
            console.error(error);
          }
        };
        break;
      default:
        setDropdownVariationOptions([]);
        setDropdownIssueOptions([]);
    }

    if (fetchData) {
      fetchData();
    }
  }, [headingText, selectedPhase]);
  //dropdown options for variation and issue
  const dropdownOptions =
    headingText === "Variations"
      ? dropdownVariationOptions
      : dropdownIssueOptions;
  const selectedOption =
    headingText === "Variations" ? selectedVariation : selectedIssue;

  //add new timesheet
  const handleAddTimesheet = async () => {
    setLoading(true);
    console.log("selected task", selectedTasks);
    if (
      !user ||
      !selectedDate ||
      (!selectedFromTime && timeEntryMethod === "from-to") ||
      (!selectedToTime && timeEntryMethod === "from-to") ||
      (!durationHours && timeEntryMethod === "duration") ||
      !selectedProject ||
      !selectedPhase ||
      durationHours === "0:00" ||
      selectedTasks.length === 0 ||
      (isBillable !== "Yes" && isBillable !== "No") ||
      (selectedLogCategory !== "1" && !(selectedVariation || selectedIssue))
    ) {
      toast.error(
        durationHours === "0:00"
          ? "Enter valid duration hours"
          : "Please fill in all required details.",
        {
          position: "bottom-center",
          autoClose: 2000,
        }
      );

      return; // Stop the function execution if any details are missing
    }

    // Step 1: Fetch existing hours for the user on the selected date
    let existingHoursInMinutes = 0;
    try {
      const response = await api.get(
        `/api/timesheets/${user}/${selectedDate.toLocaleDateString("en-CA")}`
      );
      const userTimesheets = response.data;

      // Calculate the total duration in minutes from the user's existing timesheets on this date
      userTimesheets.forEach((timesheet) => {
        const [hours, minutes] = timesheet.duration.split(":").map(Number);
        existingHoursInMinutes += hours * 60 + minutes;
      });
      // window.location.reload();
    } catch (error) {
      console.error("Error fetching existing timesheets:", error);

      return;
    }

    // Step 2: Calculate the duration of the new entry
    let currentEntryDurationInMinutes = 0;
    if (timeEntryMethod === "from-to") {
      const fromTimeParts = selectedFromTime
        .toLocaleTimeString("en-CA", { hour12: false })
        .split(":")
        .map(Number);
      const toTimeParts = selectedToTime
        .toLocaleTimeString("en-CA", { hour12: false })
        .split(":")
        .map(Number);

      const fromTimeInMinutes = fromTimeParts[0] * 60 + fromTimeParts[1];
      const toTimeInMinutes = toTimeParts[0] * 60 + toTimeParts[1];

      currentEntryDurationInMinutes = toTimeInMinutes - fromTimeInMinutes;
      if (currentEntryDurationInMinutes < 0) {
        currentEntryDurationInMinutes += 24 * 60; // Adjust for overnight shifts
      }
    } else if (timeEntryMethod === "duration") {
      const [hours, minutes] = durationHours.split(":").map(Number);
      currentEntryDurationInMinutes = hours * 60 + minutes;
    }

    // Step 3: Compare the total hours (existing + current entry) with the limit of 8 hours (480 minutes)
    const totalMinutesForDay =
      existingHoursInMinutes + currentEntryDurationInMinutes;
    const totalHoursForDay = totalMinutesForDay / 60;

    if (totalHoursForDay > 8) {
      toast.error(
        `You cannot log more than 8 hours in a day. Your current total would be ${totalHoursForDay.toFixed(
          2
        )} hours.`,
        {
          position: "bottom-center",
          autoClose: 2000,
        }
      );
      // alert(
      //   `You cannot log more than 8 hours in a day. Your current total would be ${totalHoursForDay.toFixed(
      //     2
      //   )} hours.`
      // );
      setError(
        `You cannot log more than 8 hours in a day. Your current total would be ${totalHoursForDay.toFixed(
          2
        )} hours.`
      );
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      return; // Stop function if the total exceeds 8 hours
    }

    // Step 4: Proceed to add the timesheet if the total hours are <= 8
    const title = tasks.find((task) => task.id === Number(selectedTasks));
    const titleName = title ? title.phase_taskname : "";

    const adjustedTimesheet = {
      user_id: user,
      date: selectedDate.toLocaleDateString("en-CA"),
      project_id: selectedProject,
      phase_id: selectedPhase,
      log_category: selectedLogCategory,
      task_id: Number(selectedTasks),
      variation_id: selectedVariation || null,
      issue_id: selectedIssue || null,
      billable: isBillable === "Yes" ? "1" : "0",
      logtitle: titleName,
      duration: "", // Will be set below
      approvalstatus: "Pending",
      notes: notes,
    };

    if (timeEntryMethod === "from-to") {
      adjustedTimesheet.fromtime = selectedFromTime.toLocaleTimeString(
        "en-CA",
        { hour12: false }
      );
      adjustedTimesheet.totime = selectedToTime.toLocaleTimeString("en-CA", {
        hour12: false,
      });

      // Convert to hh:mm:ss format
      const hours = Math.floor(currentEntryDurationInMinutes / 60);
      const minutes = currentEntryDurationInMinutes % 60;
      const seconds = 0; // Seconds are set to 0 since we only have hours and minutes

      adjustedTimesheet.duration = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    } else if (timeEntryMethod === "duration") {
      // Handle the duration input for duration method
      const [hours, minutes] = durationHours.split(":").map(Number);
      adjustedTimesheet.duration = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:00`; // Store in hh:mm:ss format
    }

    // Step 5: Send the timesheet to the backend
    try {
      const url = `/api/timesheets/${userId}`;
      if (onTimesheetAdded) {
        onTimesheetAdded();
      }
      const response = await api.post(url, adjustedTimesheet);
      const data = response.data;
      const newTimesheetWithNames = {
        ...data,
        duration: adjustedTimesheet.duration, // Store the formatted duration
      };

      // Update the local state with the new timesheet
      setTimesheets((prevTimesheets) => {
        const updatedTimesheets = prevTimesheets.map((phase) =>
          phase.phaseId === adjustedTimesheet.phase_id
            ? {
                ...phase,
                timesheets: [...phase.timesheets, newTimesheetWithNames],
              }
            : phase
        );
        return updatedTimesheets;
      });
      toast.success("New Timesheet added", {
        position: "bottom-center",
        autoClose: 2000,
      });

      setTimeout(() => {
        setLoading(false);
        window.location.reload();
        setAddTimesheetOpen(false);
      }, 500);
      document.body.classList.remove("no-scroll");
    } catch (error) {
      console.error("Error adding timesheet:", error);
    } finally {
      setLoading(false); // Always stop loading, even on error
      setUserExistingHoursInMinutes("");
      document.body.classList.remove("no-scroll");
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }

  //set variation or issue details
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;

    if (headingText === "Variations") {
      setSelectedVariation(selectedValue);
    } else if (headingText === "Issues") {
      setSelectedIssue(selectedValue);
    }
  };
  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );

  return (
    <div>
      {addTimesheetOpen && (
        <div
          className={`timesheet-overlay-add-time-log ${
            dialBoxopen ? "open" : "close"
          }`}
          style={{ zIndex: "15" }}
        >
          <ToastContainer />
          <div className="new-time-log-dial">
            <h3 style={{ backgroundColor: "transparent", marginLeft: "19px" }}>
              Add Time Log
            </h3>
          </div>
          <div className="scrollable-t-content">
            <h3 className="user-name">User</h3>
            {userDetails.role_name === "Administrator" ? (
              <select
                onChange={handlFetchUserId}
                value={usersId}
                className="timesheet-input"
              >
                <option value="" disabled>
                  Select User
                </option>
                {users
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
              </select>
            ) : (
              <input
                type="text"
                value={userDetails.name} // Automatically select the logged user's name
                className="timesheet-input"
                readOnly
              />
            )}
            {userDetails.role_name === "Administrator" && usersId.length > 0 ? (
              <>
                <h3 className="user-name">Project</h3>
                <input
                  ref={dropdownRef}
                  className="timesheet-d-input"
                  placeholder="Search Projects"
                  value={searchTerm || selectedProjectName}
                  onChange={handleSearchChange}
                  onFocus={handleFocus}
                  onMouseDown={() => handleClickOutside}
                />
                {showDropdown &&
                  (showDropdown && filteredProjects?.length > 0 ? (
                    <ul
                      style={{ zIndex: "50" }}
                      ref={dropdownRef}
                      className="project-display-box"
                    >
                      {filteredProjects.map((project) => (
                        <li
                          key={project.id}
                          value={project.id}
                          onClick={(e) => handleProjectChange(e)}
                          onMouseDown={() => handleClickOutside}
                          className="project-dropdown-item"
                        >
                          {project.projectno}-{project.name}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>
                      No Projects available, Contact DC to add project for you.
                    </p>
                  ))}
              </>
            ) : (
              <>
                <h3 className="user-name">Project</h3>
                <input
                  ref={dropdownRef}
                  className="timesheet-d-input"
                  placeholder="Search Projects"
                  value={searchTerm || selectedProjectName}
                  onChange={handleSearchChange}
                  onFocus={handleFocus}
                  onMouseDown={() => handleClickOutside}
                />
                {showDropdown &&
                  (showDropdown && filteredProjects?.length > 0 ? (
                    <ul
                      style={{ zIndex: "50" }}
                      ref={dropdownRef}
                      className="project-display-box"
                    >
                      {filteredProjects.map((project) => (
                        <li
                          key={project.id}
                          value={project.id}
                          onClick={(e) => handleProjectChange(e)}
                          onMouseDown={() => handleClickOutside}
                          className="project-dropdown-item"
                        >
                          {project.projectno}-{project.name}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>
                      No Projects available, Contact DC to add project for you.
                    </p>
                  ))}
              </>
            )}
            {selectedProject &&
              selectedProjectStatus !== "Completed" &&
              selectedProjectStatus !== "Cancelled" && (
                <>
                  <h3 className="user-name">Phase</h3>
                  <select
                    className="timesheet-input"
                    onChange={handlePhaseChange}
                    value={selectedPhase}
                  >
                    <option value="" disabled>
                      --Select Phase--
                    </option>
                    {phases?.phases?.length > 0 ? (
                      phases.phases.map((phase) => (
                        <option key={phase.id} value={phase.id}>
                          {phase.title}
                        </option>
                      ))
                    ) : (
                      <option disabled>
                        No Phases Available, Contact DC to add Phase for this
                        project.
                      </option>
                    )}
                  </select>
                </>
              )}
            {selectedProjectStatus === "Completed" && (
              <h3 style={{ color: "red", fontWeight: "400" }}>
                The Project is already completed, You can't book timesheets in
                this project
              </h3>
            )}
            {selectedProjectStatus === "Cancelled" && (
              <h3 style={{ color: "red", fontWeight: "400" }}>
                The Project is cancelled, You can't book timesheets in this
                project
              </h3>
            )}
            <h3 className="user-name">Log Category</h3>
            <select
              className="timesheet-input"
              onChange={handlelogcategoryChanger}
              value={selectedLogCategory}
            >
              <option value="" disabled>
                --Select Category--
              </option>
              {logCategory.map((log) => (
                <option key={log.id} value={log.id}>
                  {log.name.charAt(0).toUpperCase() + log.name.slice(1)}{" "}
                </option>
              ))}
            </select>
            {headingText !== "General" && headingText !== "Title" && (
              <div className="Title-container">
                <h3 className="user-name">{headingText}</h3>
                <select
                  onChange={handleDropdownChange}
                  value={selectedOption}
                  className="timesheet-input"
                  aria-label={`Select ${headingText}`}
                >
                  <option value="" disabled hidden>
                    --Select {headingText}--
                  </option>
                  {dropdownOptions?.length > 0 ? (
                    dropdownOptions.map((option) =>
                      headingText === "Variations" ? (
                        <option key={option.id} value={option.id}>
                          {`${option.variationno} - ${option.name}`}
                        </option>
                      ) : headingText === "Issues" ? (
                        <option key={option.id} value={option.id}>
                          {`${option.issue_number} - ${option.name}`}
                        </option>
                      ) : null
                    )
                  ) : (
                    <option disabled>
                      No {headingText} available for this project
                    </option>
                  )}
                </select>
              </div>
            )}
            <h3 className="user-name">Task Template</h3>
            <select
              className="timesheet-input"
              onChange={handleTaskTemplateChange}
              value={selectedTaskTemplate}
            >
              <option value="" disabled>
                --Select Task Template--
              </option>
              {taskTemplate.length > 0 ? (
                taskTemplate.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  There are no Task Template available for you. Contact DC to
                  add Task Template for you.
                </option>
              )}
            </select>
            {
              <>
                <h3 className="user-name">Task</h3>
                <select
                  onChange={(e) => {
                    setSelectedTasks(e.target.value);
                    setSelectedDate(null);
                  }}
                  value={selectedTasks}
                  className="timesheet-input"
                >
                  <option value="" disabled>
                    --Select Task--
                  </option>
                  {tasks.length > 0 ? (
                    tasks.map((task) => (
                      <option key={task.id} value={task.id}>
                        {task.phase_taskname}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      There are no tasks available for you. Contact DC to add
                      tasks for you.
                    </option>
                  )}
                </select>
              </>
            }
            <h3 className="user-name">Date</h3>
            <DatePicker
              placeholderText="Select Date"
              className="timesheet-d-input"
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd-MM-yyyy"
              minDate={selectedTasksStartDate || selectedProjectStartDate}
              maxDate={selectedTasksDueDate || selectedProjectEndDate}
            />
            <h3 className="user-name">Time Entry Method</h3>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="timeEntryToggle"
                className="toggle-checkbox"
                checked={timeEntryMethod === "duration"}
                onChange={(e) =>
                  setTimeEntryMethod(e.target.checked ? "duration" : "from-to")
                }
              />
              <label className="toggle-label" htmlFor="timeEntryToggle">
                <span className="toggle-inner"></span>
                <span className="toggle-switch"></span>
              </label>
            </div>
            {timeEntryMethod === "from-to" && (
              <div className="from-to-container">
                {" "}
                <div className="from-time-container">
                  <h3 className="from">From Time</h3>
                  <DatePicker
                    className="timesheet-d-input"
                    selected={selectedFromTime}
                    onChange={handleFromTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Select From Time"
                  />{" "}
                </div>{" "}
                <div className="to-time-container">
                  <h3 className="to">To Time</h3>
                  <DatePicker
                    className="timesheet-d-input"
                    selected={selectedToTime}
                    onChange={handleToTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Select To Time"
                  />{" "}
                </div>{" "}
              </div>
            )}
            {timeEntryMethod === "duration" && (
              <div className="duration-container">
                <h3 className="user-name">Duration (hh:mm)</h3>
                <input
                  type="text"
                  className="timesheet-d-input"
                  value={durationHours}
                  onChange={(e) => setDurationHours(e.target.value)} // Allow the user to type freely
                  onBlur={(e) =>
                    setDurationHours(normalizePlannedHours(e.target.value))
                  }
                  placeholder="Enter Duration"
                  title="Enter duration"
                />
              </div>
            )}
            <h3 className="user-name">Note</h3>
            <input
              type="text"
              maxLength={60}
              className="timesheet-d-input"
              value={notes}
              onChange={(e) => setNotes(e.target.value)} // Allow the user to type freely
              placeholder="Enter Note (max 60 characters)"
            />
            <h3 className="bill">Billable?</h3>
            <div className="radio-container">
              <label htmlFor="yes" className="radio-label">
                Yes
              </label>
              <input
                style={{ cursor: "pointer" }}
                type="radio"
                id="yes"
                name="isBillable-input"
                value="Yes"
                checked={isBillable === "Yes"}
                onChange={handleRadioChange}
              />
              <label htmlFor="no" className="radio-label">
                No
              </label>
              <input
                style={{ cursor: "pointer" }}
                type="radio"
                id="no"
                name="isBillable-input"
                value="No"
                checked={isBillable === "No"}
                onChange={handleRadioChange}
              />
              <div
                className={
                  timeEntryMethod === "duration"
                    ? "remaining-hours"
                    : "remaining-hours-fromto"
                }
              >
                {" "}
                <p style={{ color: "black", fontSize: "12px" }}>
                  {userExistingHoursInMinutes.length !== 0 &&
                    (userExistingHoursInMinutes &&
                    userExistingHoursInMinutes !== "0:00"
                      ? `Remaining hours for the date ⌛ ${format(
                          new Date(selectedDate),
                          "dd-MM-yyyy"
                        )}: ${userExistingHoursInMinutes} Hrs.`
                      : "You have already booked 8 hours for this date.")}
                </p>
              </div>

              <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
            </div>
          </div>
          <div className="btn-container-add-timesheet">
            <button
              className="add-btn"
              onClick={handleAddTimesheet}
              style={{
                backgroundColor:
                  selectedProjectStatus !== "Completed" &&
                  selectedProjectStatus !== "Cancelled" &&
                  userExistingHoursInMinutes !== "0:00"
                    ? "#6200ee"
                    : "grey",
              }}
              disabled={
                selectedProjectStatus === "Completed" ||
                selectedProjectStatus === "Cancelled" ||
                userExistingHoursInMinutes === "0:00"
              }
            >
              Add
            </button>
            <button onClick={onClosePopUp} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddTimeSheet;
