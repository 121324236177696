import React, { useState, useEffect, useContext } from "react";
import "../../styles/projects/ConvertToProject.css";
import { useAuth } from "../Authentication/AuthContext";
import api from "../../api/axiosConfig";
import { ProjectContext } from "../../App";
import { IoMdClose } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";

const ConvertToProject = ({ estimateDetails, goBack }) => {
  // Log the received prop for debugging

  const { userDetails } = useAuth();
  const userID = userDetails.id;
  const [taskMapping, setTaskMapping] = useState({});
  const [taskToConvert, setTasktoConvert] = useState([]);

  const { isNavBarOpen } = useContext(ProjectContext);
  const [estimate, setEstimate] = useState(estimateDetails || { phases: [] });
  console.log("estimate", estimate);
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [templateSelections, setTemplateSelections] = useState({});
  const [selectedTemplates, setSelectedTemplates] = useState({});
  const [tasks, setTasks] = useState({});
  const [users, setUsers] = useState({});
  const [manager, setManager] = useState([]);

  const calculatePlannedHoursGroupedByPhaseTemplateTask = (tasks) => {
    console.log("tasks", tasks);
    const groupedByPhase = {};

    // Step 1: Group by phase_name
    tasks.forEach((task) => {
      const { phase_name, phaseno, template_name, task_name, planned_hours } =
        task;

      // Parse planned_hours into seconds for summation
      const [hours, minutes, seconds] = planned_hours.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      if (!groupedByPhase[phase_name]) {
        groupedByPhase[phase_name] = { phaseno, templates: {} };
      }

      // Step 2: Group by template_name within each phase_name
      if (!groupedByPhase[phase_name].templates[template_name]) {
        groupedByPhase[phase_name].templates[template_name] = {};
      }

      // Step 3: Group by task_name within each template_name
      if (!groupedByPhase[phase_name].templates[template_name][task_name]) {
        groupedByPhase[phase_name].templates[template_name][task_name] = 0;
      }

      groupedByPhase[phase_name].templates[template_name][task_name] +=
        totalSeconds;
    });

    // Step 4: Convert the grouped data back to a structured format
    const result = Object.entries(groupedByPhase).map(
      ([phase_name, { phaseno, templates }]) => {
        const templateSummaries = Object.entries(templates).map(
          ([template_name, tasks]) => {
            const taskSummaries = Object.entries(tasks).map(
              ([task_name, totalSeconds]) => {
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;

                return {
                  task_name,
                  planned_hours: `${String(hours).padStart(2, "0")}:${String(
                    minutes
                  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
                };
              }
            );

            return {
              template_name,
              tasks: taskSummaries,
            };
          }
        );

        return {
          phase_name,
          phaseno, // Added phase number here
          templates: templateSummaries,
        };
      }
    );

    return result;
  };

  // Example usage with your data
  const groupedAndSummedTasks =
    calculatePlannedHoursGroupedByPhaseTemplateTask(taskToConvert);

  // Update estimate state when selectedEstimateDetails changes
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userres = await api.get("/api/users");
        setUsers(userres.data.filter((member) => member.status !== "Inactive"));
        // console.log("users", userres.data);
      } catch (error) {
        console.error("Error fetching Users:", error);
      }
    };
    if (estimateDetails) {
      setEstimate(estimateDetails);
      console.log("estimate details", estimateDetails);
    }
    fetchUsers();
  }, [estimateDetails]);

  useEffect(() => {
    const fetchTaskTemplates = async () => {
      try {
        const response = await api.get("/api/tasktemplates"); // Adjust API URL
        setTaskTemplates(response.data);
        console.log("tasktemplates", response.data);

        // Initialize templateSelections dynamically
        const initialSelections = {};
        response.data.forEach((template) => {
          initialSelections[template.id] = []; // Start with an empty array for each template
        });
        setTemplateSelections(initialSelections);

        console.log("Fetched Task Templates:", response.data);
      } catch (error) {
        console.error("Error fetching task templates:", error);
      }
    };
    console.log("managerid:", manager);
    fetchTaskTemplates();
  }, []);
  console.log(estimateDetails);

  const handleTaskSelection = (
    phasename,
    phaseno,
    templateId,
    templateName,
    taskId,
    plannedhours,
    selectedTaskName
  ) => {
    const newTask = {
      phase_name: phasename,
      phaseno: phaseno,
      template_id: templateId,
      template_name: templateName,
      task_id: taskId,
      task_name: selectedTaskName,
      planned_hours: plannedhours,
    };

    setTasktoConvert((prev) => {
      // Check if the task already exists in the array
      const taskExists = prev.some(
        (task) => task.template_id === templateId && task.task_id === taskId
      );

      if (taskExists) {
        // Update the existing task
        return prev.map((task) =>
          task.template_id === templateId && task.task_id === taskId
            ? newTask
            : task
        );
      } else {
        // Add the new task to the array
        return [...prev, newTask];
      }
    });
  };
  console.log(tasks);
  const handleTemplateSelection = async (
    templateId,
    rowId,
    selectedTemplateId
  ) => {
    if (!manager.length > 0) {
      toast.error("Select Manager", {
        position: "top-center", // Use position as a string
        autoClose: 2000,
      });
      return;
    }
    setSelectedTemplates((prev) => ({
      ...prev,
      [rowId]: selectedTemplateId,
    }));

    try {
      // Fetch tasks for the selected template
      const response = await api.get(`/api/tasks/${selectedTemplateId}`);
      setTasks((prev) => ({
        ...prev,
        [rowId]: response.data, // Store tasks for the corresponding row
      }));
      console.log("Tasks", tasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  console.log("SelectedTemplates", selectedTemplates);
  console.log("estimate", estimate);

  const handleSubmit = async () => {
    setManager([]);
    setSelectedTemplates([]);
    try {
      if (!tasks || Object.keys(tasks).length === 0) {
        alert("Tasks are missing or empty. Please ensure tasks are loaded.");
        return;
      }

      const validTasks = [];
      const allTasks = Object.values(tasks).flat();

      estimate.phases.forEach((phase) => {
        phase.taskTemplates.forEach((template) => {
          template.estimateTasks.forEach((task) => {
            const mappedTaskId = taskMapping[`${template.id}-${task.id}`];
            const mappedTask = allTasks.find(
              (t) => t.id === Number(mappedTaskId)
            );

            if (mappedTask) {
              validTasks.push({
                taskId: task.id,
                mappedTaskId,
                taskName: mappedTask.name,
              });
            }
          });
        });
      });

      const payload = {
        estimateDetails: estimate,
        taskMapping: validTasks,
        selectedTemplates,
        managerId: manager,
        groupedAndSummedTasks,
        convertedBy: userID,
      };

      const response = await api.post("/api/convertToProject", payload);

      if (response.status === 200) {
        alert("Project created successfully!");
        goBack();
      } else {
        alert("Failed to create project.");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      alert("An error occurred. Please try again.");
    }
  };

  // Check if estimate or phases are missing
  if (!estimate.phases || estimate.phases.length === 0) {
    return <p>No estimate details available.</p>;
  }

  return (
    <div
      className={
        isNavBarOpen ? "full-page-container" : "full-page-container-close"
      }
    >
      <div className="page-header">
        <div
          className={
            isNavBarOpen
              ? "convert-to-project-header"
              : "convert-to-project-header-close"
          }
        >
          <h3 style={{ marginRight: "20px" }}>
            Estimate No : {estimate.estimationno}
          </h3>
          <h3 style={{ marginRight: "20px" }}>
            Project : {estimate.projectname}
          </h3>
          <h3 style={{ marginRight: "20px" }}>Project Manager : </h3>
          {users.length > 0 && (
            <select
              onChange={(e) => setManager(e.target.value)}
              className="estimate-select-user"
            >
              <option value="">Select Manager</option>
              {users
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
            </select>
          )}
        </div>
        <h1 style={{ marginTop: "-0.5%" }}>Convert Estimate to Project</h1>
        <IoMdClose
          className={
            isNavBarOpen
              ? "estimate-conver-close"
              : "estimate-conver-close-close"
          }
          onClick={goBack}
        />
      </div>
      <div
        className={
          isNavBarOpen
            ? "convert-to-project-table-con"
            : "convert-to-project-table-con-close"
        }
      >
        {estimate.phases.map((phase) => (
          <div key={phase.id} className="phase-section">
            <h3>{phase.phase_name}</h3>
            {phase.taskTemplates.map((template) => (
              <div key={template.id} className="template-section">
                {
                  <div>
                    <h3>Select Task template for mapping</h3>
                    <select
                      className="estimate-select-tasktemplate"
                      onChange={(e) =>
                        handleTemplateSelection(
                          template.id,
                          template.id,
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select TaskTemplate</option>
                      {taskTemplates.map((templates) => (
                        <option key={templates.id} value={templates.id}>
                          {templates.name}
                        </option>
                      ))}
                    </select>
                  </div>
                }
                <table className="task-table">
                  <thead>
                    <tr>
                      <th className="task-name-estimate">Task Name</th>
                      <th
                        className="task-name-estimate"
                        style={{ whiteSpace: "nowrap", width: "80px" }}
                      >
                        Planned Hrs
                      </th>
                      <th
                        className="task-name-estimate"
                        style={{ width: "239px" }}
                      >
                        Map to Task
                      </th>
                      <th style={{ width: "640px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {template.estimateTasks.map((task) => (
                      <tr key={task.id}>
                        <td>{task.task_name}</td>
                        <td style={{ textAlign: "center", width: "80px" }}>
                          {task.planned_hours.substring(0, 5)}
                        </td>
                        <td>
                          {
                            <select
                              className="estimate-table-dropdown"
                              onChange={(e) =>
                                handleTaskSelection(
                                  phase.phase_name,
                                  phase.phase_number,
                                  template.id,
                                  template.template_name,
                                  task.id,
                                  task.planned_hours,
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Task</option>
                              {tasks[template.id] &&
                                tasks[template.id].map((task) => (
                                  <option key={task.id} value={task.name}>
                                    {task.name}
                                  </option>
                                ))}
                            </select>
                          }
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
      </div>
      <button onClick={handleSubmit} className="submit-btn">
        Submit
      </button>
      <ToastContainer />
    </div>
  );
};

export default ConvertToProject;
