import React, { useState, useEffect, useContext, useRef } from "react";
import DatePicker from "react-datepicker";
import "../../styles/projects/Projects.css";
import { MultiSelect } from "react-multi-select-component";
import api from "../../api/axiosConfig";
import { ProjectContext } from "../../App";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../Authentication/AuthContext";

const AddProjectDial = () => {
  const [projects, setProjects] = useState([]);
  const { userDetails } = useAuth();
  const { isNavBarOpen, setAddProjectOpen } = useContext(ProjectContext);
  const [loading, setLoading] = useState(true);
  const [newProject, setNewProject] = useState({
    name: "",
    projectno: "",
    start_date: "",
    end_date: "",
    actual_hours: "",
    budget_hours: "",
    manager_id: "",
    description: "",
    customer_id: "",
    team_id: "",
    tags: "",
    priority: "",
  });
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [projectGroup, setProjectGroup] = useState([]);
  useEffect(() => {
    if (userDetails) {
      setSelectedMembers((prev) => {
        // Check if the user is already in the list
        const isAlreadyAdded = prev.some(
          (member) => member.value === userDetails.id
        );
        if (isAlreadyAdded) {
          return prev; // Return the current state without changes
        }
        // Add the new user
        return [...prev, { value: userDetails.id, label: userDetails.name }];
      });
    }
  }, [userDetails]); // Only run when userDetails changes
  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and profile
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) //&&
      // profileRef.current &&  !profileRef.current.contains(event.target)
    ) {
      setShowDropdown(false); // Close the dropdown
    }
  };

  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (newProject.customer_id) {
      const customer = customers.find(
        (c) => c.id === Number(newProject.customer_id)
      );
      const name = `${customer.customerno}-${customer.name}`;
      setSelectedCustomerName(name);
    }
  }, [newProject, customers]);
  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(e.target.value);
    setSelectedCustomerName("");

    const filtered = customers.filter((customer) => {
      const name = customer.name || ""; // Fallback to an empty string
      const no = customer.customerno || ""; // Fallback to an empty string
      const displayName = customer.displayName || ""; // Fallback to an empty string

      return (
        name.toLowerCase().includes(searchValue) ||
        no.toLowerCase().includes(searchValue) ||
        displayName.toLowerCase().includes(searchValue)
      );
    });

    setFilteredCustomers(filtered);
    setShowDropdown(true); // Show the dropdown when the user types
  };

  const handleFocus = () => {
    setSearchTerm(""); // Reset the search term to show all projects
    setFilteredCustomers(customers); // Show all projects when focusing on the input
    setShowDropdown(true); // Show the dropdown
  };
  useEffect(() => {
    if (newProject.manager_id) {
      const newUser = users.find((u) => u.id === Number(newProject.manager_id));
      if (newUser) {
        setSelectedMembers((prev) => {
          // Check if the user is already in the list
          const isAlreadyAdded = prev.some(
            (member) => member.value === newUser.id
          );
          if (isAlreadyAdded) {
            return prev; // Return the current state without changes
          }
          // Add the new user
          return [...prev, { value: newUser.id, label: newUser.name }];
        });
      }
    }
  }, [newProject, users]); // Only run when newProject or users change
  //new project dial
  const closeDialog = () => {
    setAddProjectOpen(false);
    setNewProject({
      name: "",
      projectno: "",
      start_date: "",
      end_date: "",
      actual_hours: "",
      budget_hours: "",
      manager_id: "",
      description: "",
      customer_id: "",
      team_id: "",
      tags: "",
      priority: "",
    });
    setSelectedMembers([]);
    document.body.classList.remove("no-scroll");
  };
  //add new project
  const handleAddNewProject = async () => {
    const formatDateForMySQL = (date, daysToAdd = 0) => {
      if (!date) return null; // If no date is provided, return null

      // Convert to Date object if it's a valid date string
      let validDate = new Date(date);
      if (isNaN(validDate)) {
        return null; // Return null if invalid date
      }

      // Add the specified number of days (30 in this case)
      validDate.setDate(validDate.getDate() + daysToAdd);

      // Adjust for timezone and return as 'YYYY-MM-DD'
      const localDate = new Date(
        validDate.getTime() - validDate.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().split("T")[0]; // Convert to 'YYYY-MM-DD'
    };

    let formattedProject;
    if (newProject.start_date && newProject.end_date) {
      formattedProject = {
        ...newProject,
        start_date: formatDateForMySQL(newProject.start_date),
        end_date: formatDateForMySQL(newProject.end_date),
      };
    } else {
      formattedProject = {
        ...newProject,
        start_date: formatDateForMySQL(newProject.start_date),
        end_date: formatDateForMySQL(newProject.start_date, 30), // Add 30 days to the start_date
      };
    }
    if (
      !formattedProject.projectno ||
      !formattedProject.name ||
      !formattedProject.start_date ||
      !formattedProject.customer_id ||
      !formattedProject.manager_id
    ) {
      toast.error("Check the input fields", {
        position: "bottom-center",
        autoClose: 2000,
      });
    } else {
      try {
        const response = await api.post(`/api/projects`, formattedProject);
        if (response.status === 201) {
          const newProject = response.data; // Newly created project
          const currentProjectId = newProject.id; // Get project ID

          setProjects([...projects, newProject]);
          toast.success("Project added successfully!.", {
            position: "bottom-center",
            autoClose: 2000,
          });
          window.location.reload();

          // After project is added, associate selected members with the new project
          if (selectedMembers.length > 0) {
            const userIds = selectedMembers.map((member) => member.value); // Extract 'value' instead of 'id'
            await handleAssociateUsersToProject(currentProjectId, userIds);
          }

          setNewProject({
            name: "",
            projectno: "",
            start_date: "",
            end_date: "",
            actual_hours: "",
            budget_hours: "",
            manager_id: "",
            description: "",
            customer_id: "",
            team_id: "",
            tags: "",
            priority: "",
          });
          setTimeout(() => {
            closeDialog();
          }, 1000);
        }
      } catch (error) {
        console.error("Error adding project:", error);
        toast.error("Failed to add project", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    }
  };

  //add users to an project
  const handleAssociateUsersToProject = async (currentProjectId, userIds) => {
    if (userIds.length === 0 || userIds.includes(undefined)) {
      console.error("Error: Invalid user IDs", userIds);
      toast.error("Error: Invalid user selection", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    try {
      // eslint-disable-next-line
      const response = await api.post(
        `/api/projects/${currentProjectId}/users`,
        {
          userIds: userIds,
        }
      );

      toast.error("Users associated with the project successfully.", {
        position: "bottom-center",
        autoClose: 2000,
      });
      setSelectedMembers((prev) => "");
      // Update associated and non-associated users state
    } catch (error) {
      console.error(
        "Error associating users with project:",
        error.response?.data || error.message
      );
      toast.error("Error associating users with project. Please try again.", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  //fetching admin users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/users`);
        const activeUsers = res.data.filter(
          (member) => member.status !== "Inactive"
        );
        setUsers(activeUsers);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //fetching all users and customers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/users`);
        const cusres = await api.get(`/api/customers`);
        const groupres = await api.get(`/api/groups`);
        const formattedMembers = res.data
          .filter((member) => member.status !== "Inactive") // Filter out inactive members
          .map((member) => ({
            value: member.id, // Assuming `id` is a unique identifier
            label: member.name, // Display the name as the label
          }))
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          });

        setMembers(formattedMembers);
        setCustomers(cusres.data);
        setProjectGroup(groupres.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false); // Always stop loading after attempting to fetch
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }

  return (
    <div style={{ position: "relative", left: isNavBarOpen ? "0px" : "-80px" }}>
      <ToastContainer />
      <>
        <div className="add-project-dial slide-in-right">
          <div className="add-new-project-content">
            <div className="add-project-user-header">
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "14px" }}
              >
                New Project
              </h3>
            </div>
            <div className="project-name-number-container">
              <div className="name-container">
                <h3 className="box-heading">Project No</h3>
                <input
                  className="box-input"
                  placeholder="Enter Project No"
                  value={newProject.projectno}
                  maxLength={20}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      projectno: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="name-container" style={{ marginLeft: "14px" }}>
                <h3 className="box-heading">Project Name</h3>
                <input
                  className="box-input"
                  placeholder="Enter Project Name"
                  value={newProject.name}
                  maxLength={50}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="start-date-due-date-container">
              <div className="start-dat-container">
                <h3 className="box-heading">Start date</h3>
                <DatePicker
                  placeholderText="dd-mm-yyyy"
                  selected={newProject.start_date}
                  onChange={(date) =>
                    setNewProject((prev) => ({
                      ...prev,
                      start_date: date,
                    }))
                  }
                  dateFormat="dd-MM-yyyy"
                  className="box-input"
                  maxDate={newProject.end_date}
                />
              </div>
              <div className="due-date-container">
                <h3 className="box-heading">Due date</h3>
                <DatePicker
                  placeholderText="dd-mm-yyyy"
                  selected={newProject.end_date}
                  onChange={(date) =>
                    setNewProject((prev) => ({
                      ...prev,
                      end_date: date,
                    }))
                  }
                  dateFormat="dd-MM-yyyy"
                  className="box-input"
                  minDate={newProject.start_date}
                />
              </div>
            </div>
            <div className="project-owner-budget-container">
              <div className="owner-container">
                <h3 className="box-heading">Owner</h3>
                <select
                  className="select-d-input"
                  value={newProject.manager_id}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      manager_id: e.target.value,
                    }))
                  }
                >
                  <option value="" disabled>
                    Select Owner
                  </option>
                  {users
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="name-container" style={{ marginLeft: "14px" }}>
                <h3 className="box-heading">Tags</h3>
                <select
                  value={newProject.tags}
                  className="select-d-input"
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      tags: e.target.value,
                    }))
                  }
                >
                  <option value="" disabled>
                    Select Tags
                  </option>
                  <option value="Yet to Start">Yet to Start</option>
                  <option value="On Progress">On Progress</option>
                  <option value="On Hold">On Hold</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
                {/* <input
                  className="box-input"
                  value={newProject.tags}
                  maxLength={20}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      tags: e.target.value,
                    }))
                  }
                /> */}
              </div>
            </div>
            <div className="add-description-container ">
              <div style={{ backgroundColor: "transparent" }}>
                <h3 className="box-heading">Add Description</h3>
                <textarea
                  className="box-des-input"
                  placeholder="Enter Description"
                  value={newProject.description}
                  maxLength={100}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="priority-container">
                <h3 className="box-heading">Priority</h3>
                <select
                  value={newProject.priority}
                  className="select-d-input"
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      priority: e.target.value,
                    }))
                  }
                >
                  <option value="" disabled>
                    Select Priority
                  </option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ backgroundColor: "transparent" }}>
                  <h3 className="box-heading">Select Customer</h3>
                  <input
                    className="box-input"
                    placeholder="Search Customer"
                    ref={dropdownRef}
                    value={searchTerm || selectedCustomerName}
                    onChange={handleSearchChange}
                    onFocus={handleFocus}
                    onMouseDown={() => handleClickOutside}
                  />
                  {showDropdown &&
                    (showDropdown && filteredCustomers?.length > 0 ? (
                      <ul
                        style={{ zIndex: "50" }}
                        ref={dropdownRef}
                        className="project-p-display-box"
                      >
                        {filteredCustomers.map((customer) => (
                          <li
                            key={customer.id}
                            value={customer.id}
                            onClick={() => {
                              setNewProject((prev) => ({
                                ...prev,
                                customer_id: customer.id,
                              }));
                              setShowDropdown(false);
                              setSearchTerm("");
                            }}
                            onMouseDown={() => handleClickOutside}
                            className="project-dropdown-item"
                          >
                            {customer.customerno}-{customer.name}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No Customers available</p>
                    ))}
                  {/* <select
                    className="select-d-input"
                    value={newProject.customer_id}
                    onChange={(e) => {
                      const selectedCustomerId = e.target.value;
                      setNewProject((prev) => ({
                        ...prev,
                        customer_id: selectedCustomerId,
                      }));
                    }}
                  >
                    <option value="" disabled>
                      Select Customer
                    </option>
                    {customers.map((customer) => (
                      <option key={customer.id} value={customer.id}>
                        {customer.name}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div className="tags-container">
                  <h3 className="box-heading">Project Group</h3>
                  <select
                    className="select-d-input"
                    style={{ marginTop: "0px", backgroundColor: "white" }}
                    value={newProject.team_id}
                    onChange={(e) => {
                      const selectedGroupId = e.target.value;
                      setNewProject((prev) => ({
                        ...prev,
                        team_id: selectedGroupId,
                      }));
                    }}
                  >
                    <option value="" disabled>
                      Select Group
                    </option>
                    {projectGroup.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.code}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="project-select-container">
              <div className="project-new-project-container "></div>
            </div>
            <div className="project-details-container">
              <h3 className="box-heading">Project Members</h3>
              <MultiSelect
                options={members}
                value={selectedMembers}
                onChange={setSelectedMembers}
                className="member-select-option"
              />
            </div>
          </div>
          <div className="add-new-project-button-container">
            <button className="add-btn" onClick={handleAddNewProject}>
              Add
            </button>
            <button onClick={closeDialog} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddProjectDial;
