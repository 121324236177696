import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { format } from "date-fns";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { IoNotificationsOutline } from "react-icons/io5";
import Profile from "../Home/Profile";
import api from "../../api/axiosConfig";
import { useAuth } from "../Authentication/AuthContext";
import { FaPlus } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import "../../styles/layouts/AppLayout.css";
import AddProjectDial from "../DialBox/AddProjectDial";
import AddTimeSheetDial from "../DialBox/AddTimeSheetDial";
import AddUser from "../DialBox/AddUser";
import { ProjectContext } from "../../App";
import { useRolePermissions } from "../Settings/RoleContext";

const AppLayout = () => {
  const { userDetails } = useAuth();
  const { hasPermission } = useRolePermissions();
  const {
    setDialBoxopen,
    setIsNavBarOpen,
    isNavBarOpen,
    openDialog,
    setOpenDialog,
    setAddTimesheetOpen,
    addTimesheetOpen,
    addProjectOpen,
    setAddProjectOpen,
  } = useContext(ProjectContext);
  const profileRef = useRef(null);
  const [addDialBox, setAddDialBox] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false); // State for profile popup
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(() => {
    return localStorage.getItem("selectedProject") || "";
  });
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const userID = userDetails.id;
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(() => {
    return localStorage.getItem("activeLink") || "";
  });
  const [userExistingHoursInMinutes, setUserExistingHoursInMinutes] =
    useState("");

  useEffect(() => {
    const currentPath = location.pathname.split("/")[1]; // Get the main segment of the path
    setActiveLink(currentPath); // Update the activeLink state
    localStorage.setItem("activeLink", currentPath); // Optionally persist it
  }, [location]); // Run this effect whenever location changes
  // Update activeLink whenever the URL changes

  const [profilePicture, setProfilePicture] = useState(null);
  const fetchProfilePicture = async () => {
    try {
      const response = await api.get(`/api/users/${userID}/profile-picture`, {
        responseType: "blob", // Ensure we receive binary data
      });

      if (response.status === 200) {
        // Axios uses `status` to check response status
        // Assuming the response contains the profile picture URL in a property (like response.data)

        const imageBlob = response.data;
        const imageUrl = URL.createObjectURL(imageBlob);

        setProfilePicture(imageUrl); // Update state with the profile picture URL
      } else {
        console.error("Failed to fetch profile picture:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
    }
  };

  // Fetch profile picture when the component loads
  useEffect(() => {
    fetchProfilePicture();
  }, [userID]);

  // update changes on the select option
  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and profile
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) //&&
      // profileRef.current &&  !profileRef.current.contains(event.target)
    ) {
      setShowDropdown(false); // Close the dropdown
    }
  };

  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    const filtered = projects.filter(
      (project) =>
        project.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        project.projectno
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        project.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilteredProjects(filtered);
    setShowDropdown(true); // Show the dropdown when the user types
  };

  const handleFocus = () => {
    setSearchTerm(""); // Reset the search term to show all projects
    setFilteredProjects(projects); // Show all projects when focusing on the input
    setShowDropdown(true); // Show the dropdown
  };
  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setDialBoxopen(false);
    setSearchTerm(project.displayName); // Use the concatenated value

    setShowDropdown(false);

    setTimeout(() => {
      localStorage.setItem("selectedProject", project.displayName);
      const frequentlyAccessedProjects =
        JSON.parse(localStorage.getItem("frequentlyAccessedProjects")) || [];
      if (!frequentlyAccessedProjects.includes(project.id)) {
        frequentlyAccessedProjects.push(project.id);
        localStorage.setItem(
          "frequentlyAccessedProjects",
          JSON.stringify(frequentlyAccessedProjects)
        );
      }
      navigate(`/project/${project.id}/dashboard`);
    }, 0);
  };

  // Load selected project from localStorage on component mount
  useEffect(() => {
    const storedProjectId = localStorage.getItem("selectedProject");
    if (storedProjectId) {
      const storedProject = projects.find(
        (project) => project.id === storedProjectId
      );
      setSelectedProject(storedProject);
      setSearchTerm(storedProject?.name || "");
    }

    const frequentlyAccessedProjects =
      JSON.parse(localStorage.getItem("frequentlyAccessedProjects")) || [];
    setProjects((prevProjects) =>
      prevProjects.sort((a, b) => {
        const aFreq = frequentlyAccessedProjects.includes(a.id);
        const bFreq = frequentlyAccessedProjects.includes(b.id);
        return bFreq - aFreq;
      })
    );
  }, [projects]);

  const handleAddBox = () => {
    setAddDialBox((prev) => !prev);
  };

  //close add popup when click outside container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        if (handleAddBox) {
          handleAddBox();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleAddBox]);

  // fetching project details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint =
          userDetails.role_name === "Administrator"
            ? `/api/projects1`
            : `/api/aprojects/${userID}`;
        const res = await api.get(endpoint);

        // Concatenate two fields from the database here, for example: name and code
        const updatedProjects = res.data
          .filter((project) => project.activestatus === 1)
          .map((project) => ({
            ...project,
            displayName: `${project.projectno}-${project.name}`, // Concatenate fields
          }));

        setProjects(updatedProjects);
        setLoading(false);
      } catch (error) {
        setError(error);
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userDetails.role, userID]);

  // Filter projects based on search term
  useEffect(() => {
    const filtered = projects.filter((project) =>
      // project.name.toLowerCase().includes(searchTerm.toLowerCase())
      project.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  }, [searchTerm, projects]);

  const resetProjectSelection = (link) => {
    return () => {
      // Set the active link state
      // setActiveLink(link);

      // Save the active link to localStorage
      setDialBoxopen(false);
      localStorage.setItem("activeLink", link);
      localStorage.removeItem("openSection");
      localStorage.removeItem("tabIndex");
      localStorage.removeItem("activeproject");
      // Clear other states
      setSelectedProject(null);
      setSearchTerm("");
      localStorage.removeItem("selectedProject");
    };
  };

  // profile popup open
  const openProfile = useCallback(() => {
    setIsProfileOpen(true);
  }, []);

  // profile popup close
  const closeProfile = useCallback(() => {
    setIsProfileOpen(false);
  }, []);

  // for loading state
  if (loading) {
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <span className="loading-animation">LOADING...</span>
    </div>;
  }

  // Function to get the avatar style based on the first letter of the username
  const getAvatarStyle = () => {
    if (userDetails && userDetails.name) {
      const color = "#6ca0dc";
      return {
        backgroundColor: color,
        color: "white",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "30px",
        height: "32px",
        fontSize: "17px",
        textAlign: "center",
        fontWeight: "800",
        marginTop: "0px",
      };
    }
    return {};
  };

  const firstLetter = userDetails
    ? userDetails.name.charAt(userDetails.name.length - 1).toUpperCase() +
      userDetails.name.charAt(0).toUpperCase()
    : "?";
  function normalizePlannedHours(input) {
    // If input is a decimal (e.g., "1.5"), convert it to HH:MM format
    if (/^[0-9]+([.][0-9]+)?$/.test(input)) {
      const hours = Math.floor(parseFloat(input));
      const minutes = Math.round((parseFloat(input) - hours) * 60);
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    }

    // If input is in HH:MM format, return it directly
    if (/^[0-9]+:[0-5][0-9]$/.test(input)) {
      return input;
    }

    // If input is a whole number, treat it as hours
    if (/^[0-9]+$/.test(input)) {
      return `${parseInt(input, 10)}:00`;
    }

    // For invalid formats, return an empty string
    return "";
  }

  useEffect(() => {
    const calculateExistingHours = async (user, selectedDate) => {
      try {
        const response = await api.get(
          `/api/timesheets/${user}/${selectedDate.toLocaleDateString("en-CA")}`
        );
        const userTimesheets = response.data;

        let totalMinutes = 0;
        userTimesheets.forEach((timesheet) => {
          const [hours, minutes] = timesheet.duration.split(":").map(Number);
          if (!isNaN(hours) && !isNaN(minutes)) {
            totalMinutes += hours * 60 + minutes;
          }
        });

        let res = (8 * 60 - totalMinutes) / 60; // Convert to hours
        res = normalizePlannedHours(res);

        setUserExistingHoursInMinutes(res);
      } catch (error) {
        console.error("Error fetching existing timesheets:", error);
      }
    };

    if (userID) {
      calculateExistingHours(userID, new Date()); // ✅ Pass the correct date object
    }
  }, [userID]); // ✅ Keeps it dynamic

  return (
    <>
      {!isNavBarOpen && (
        <span className="arrow-nav-icon slide-icon" title="Show sidebar">
          <MdKeyboardDoubleArrowRight
            style={{ color: "black" }}
            className="icon"
            onClick={() => setIsNavBarOpen(true)}
          />
        </span>
      )}
      {isNavBarOpen && (
        <div className="left-side-navbar">
          <span className="arrow-nav-icon slide-icon" title="Hide sidebar">
            <MdOutlineKeyboardDoubleArrowLeft
              className="icon"
              onClick={() => setIsNavBarOpen(false)}
            />
          </span>
          <img
            className="project-image"
            alt="Projects"
            src="/AppLayout/projects.png"
          />
        </div>
      )}
      <div className="home-layout">
        <div className="left-side-layout-group">
          <div className="tabs-parent">
            <div>
              <Link
                className={`navbar-select-project ${
                  activeLink === "home" ? "active" : ""
                }`}
                to="/home"
                onClick={resetProjectSelection("home")}
              >
                Home
              </Link>
            </div>
            <div>
              <Link
                className={`navbar-select-project ${
                  activeLink === "feed" ? "active" : ""
                }`}
                to="/home"
                onClick={resetProjectSelection("feed")}
              >
                Feed
              </Link>
            </div>
            {hasPermission("reports", "create") && (
              <div>
                <Link
                  className={`navbar-select-project ${
                    activeLink === "report" ? "active" : ""
                  }`}
                  to="/report"
                  onClick={resetProjectSelection("report")}
                >
                  Report
                </Link>
              </div>
            )}
            {hasPermission("estimates", "read") && (
              <div>
                <Link
                  className={`navbar-select-project ${
                    activeLink === "estimate" ? "active" : ""
                  }`}
                  to="/estimate"
                  onClick={resetProjectSelection("estimation")}
                >
                  Estimate
                </Link>
              </div>
            )}
            {hasPermission("projects", "read") && (
              <div>
                <Link
                  className={`navbar-select-project ${
                    activeLink === "project" ? "active" : ""
                  }`}
                  to="/project"
                  onClick={resetProjectSelection("project")}
                >
                  Project
                </Link>
              </div>
            )}

            {hasPermission("timesheets", "read") && (
              <div>
                <Link
                  className={`navbar-select-project ${
                    activeLink === "timesheet" ? "active" : ""
                  }`}
                  to="/timesheet/overview"
                  onClick={resetProjectSelection("overview")}
                >
                  Overview
                </Link>
              </div>
            )}
            {hasPermission("projects", "read") && (
              <div className="select-project-parent" ref={dropdownRef}>
                <input
                  type="text"
                  placeholder="Search or Select Projects"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onFocus={handleFocus}
                  onClick={() =>
                    localStorage.setItem("activePLink", "dashboard")
                  }
                  onMouseDown={() => handleClickOutside}
                  className={`project-search-input ${
                    activeLink === "project" ? "active" : ""
                  }`}
                />
                {showDropdown && (
                  <ul className="project-dropdown">
                    {filteredProjects.map((project) => (
                      <li
                        key={project.id}
                        onClick={() => handleProjectSelect(project)}
                        onMouseDown={() => handleClickOutside}
                        className="project-dropdown-item"
                      >
                        {/* {project.name || project.projectno} */}
                        {project.displayName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="right-side-parent">
          <GoPlus
            onClick={handleAddBox}
            className="plus-icon"
            title="Quick Actions"
          />

          <>
            {/* <CiSearch className="search-icon" /> */}
            <Link
              to={
                hasPermission("settings", "update")
                  ? "settings"
                  : "settings/user-details"
              }
            >
              {" "}
              <CiSettings
                onClick={resetProjectSelection("settings")}
                className={`settings-icon ${
                  activeLink === "settings" ? "active" : ""
                }`}
                title="Configure Settings"
              />
            </Link>
            {/* <p>{userExistingHoursInMinutes}</p> */}
            <IoNotificationsOutline
              onClick={resetProjectSelection("notification")}
              className={`notification-icon ${
                activeLink === "notification" ? "active" : ""
              }`}
              title="Notifications"
            />
            <p className="existing-hrs">
              {userExistingHoursInMinutes === "0:00"
                ? ""
                : ` ${userExistingHoursInMinutes}`}
            </p>
          </>

          <a
            style={{ cursor: "pointer" }}
            alt="profile"
            title="profile"
            onClick={openProfile}
          >
            {profilePicture && profilePicture !== "null" ? (
              <img
                className="avatar-icon2"
                style={getAvatarStyle()}
                src={profilePicture}
                alt="profile"
              />
            ) : (
              <span className="avatar-icon2" style={getAvatarStyle()}>
                {firstLetter}
              </span>
            )}
          </a>
        </div>
        <hr className="horizontal-line" />
      </div>
      {isProfileOpen && <Profile onClose={closeProfile} />}
      {addDialBox && (
        <div className="add-dial-box-container" ref={profileRef}>
          <div className="add-container"></div>

          <>
            {hasPermission("users", "create") && (
              <div className="add-container" style={{ cursor: "pointer" }}>
                <FaPlus className="add-icon-plus" />
                <button
                  className="add-user-layout"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenDialog((prev) => !prev)}
                >
                  Add User
                </button>
              </div>
            )}
            {hasPermission("projects", "create") && (
              <div className="add-container" style={{ cursor: "pointer" }}>
                <FaPlus className="add-icon-plus" />
                <button
                  className="add-user-layout"
                  style={{ cursor: "pointer" }}
                  onClick={() => setAddProjectOpen(true)}
                >
                  Add Project
                </button>
              </div>
            )}
            {hasPermission("timesheets", "create") && (
              <div className="add-container" style={{ cursor: "pointer" }}>
                <FaPlus className="add-icon-plus" />
                <button
                  className="add-user-layout"
                  style={{ cursor: "pointer" }}
                  onClick={() => setAddTimesheetOpen(true)}
                >
                  Add Timesheet
                </button>
              </div>
            )}
          </>
        </div>
      )}
      {openDialog && <AddUser />}
      {addTimesheetOpen && <AddTimeSheetDial />}
      {addProjectOpen && <AddProjectDial />}
    </>
  );
};

export default AppLayout;
