import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { ProjectContext } from "../../App";
import { useRolePermissions } from "../Settings/RoleContext";
import api from "../../api/axiosConfig";
import { IoMdClose } from "react-icons/io";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import { colors } from "@mui/material";
const formattedDate = format(new Date(), "dd-MM-yyyy");

const parseTimeToHours = (timeString) => {
  if (!timeString || timeString === "00:00") return 0; // Handle empty or 00:00
  const [hours, minutes = 0] = timeString.split(":").map(Number);
  return hours + minutes / 60; // Convert to decimal hours
};

const calculateTotalHours = (tasks) => {
  return tasks
    .map((task) => {
      const time = task.total_planned_hours || "00:00";
      return parseTimeToHours(time);
    })
    .reduce((sum, hours) => sum + hours, 0);
};

// Convert decimal hours to HH:MM format
const formatHoursToHHMM = (decimalHours) => {
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

const EstimateTaskTable = ({
  tasks,
  revision,
  exclusion,
  design,
  detailing,
  designRevise,
  detailingRevise,
  detailingSumHours,
  projectDetails,
}) => {
  const { estimateId, phaseId } = useParams();
  const [designExclusion, setDesignExclusion] = useState();
  const [detailingExclusion, setDetailingExclusion] = useState();
  const [designSave, setDesignSave] = useState(false);
  const [designNo, setDesignNo] = useState(false);
  const [detailingSave, setDetailingSave] = useState(false);
  const [detailingNo, setDetailingNo] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const updateSaveStatus = async (
      value,
      template_name,
      phase_id,
      estimate_id
    ) => {
      try {
        const response = await api.put("/api/update-save-status", {
          value,
          template_name,
          phase_id,
          estimate_id,
        });
      } catch (error) {
        console.error(
          "Error updating save status:",
          error.response?.data || error.message
        );
      }
    };

    if (designSave) {
      setDesignSave(false);
      updateSaveStatus("Yes", "Design", phaseId, estimateId);
      setTimeout(() => {
        toast.success("Estimate for Design submitted successfully!", {
          position: "bottom-center",
          autoClose: 500,
        });
      }, 500);
    }

    if (detailingSave) {
      setDetailingSave(false);
      fetchData();
      updateSaveStatus("Yes", "Detailing", phaseId, estimateId);
      setTimeout(() => {
        toast.success("Estimate for Detailing submitted successfully!", {
          position: "bottom-center",
          autoClose: 500,
        });
      }, 500);
    }
    if (detailingNo) {
      fetchData();
      setDetailingNo(false);
      // updateSaveStatus("No", "Detailing", phaseId, estimateId);
    }
    if (designNo) {
      fetchData();
      setDesignNo(false);
      // updateSaveStatus("No", "Design", phaseId, estimateId);
    }
    fetchData();
  }, [designSave, phaseId, estimateId, detailingSave, detailingNo, designNo]);

  const fetchData = async () => {
    try {
      const response = await api.get(
        `/api/estimatestasktemplates/submitstatus/${estimateId}/${phaseId}`
      ); // Axios automatically adds the baseURL and headers
      setSubmitValue(response.data); // Axios automatically parses JSON
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error); // Handle error
    }
  };
  useEffect(() => {
    if (exclusion) {
      exclusion.forEach((e) => {
        if (e.template_name === "Design") {
          setDesignExclusion(e.exclusion);
        } else if (e.template_name === "Detailing") {
          setDetailingExclusion(e.exclusion);
        }
      });
    }
  }, [exclusion]);

  const { isNavBarOpen } = useContext(ProjectContext);

  // Split tasks into design and detailing categories
  const designTasks = tasks.filter((task) =>
    task.task_category.startsWith("D")
  );

  const detailingTasks = tasks.filter((task) =>
    task.task_category.startsWith("S")
  );

  // Helper function to convert "HH:MM:SS" to total seconds
  const timeToSeconds = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60;
  };

  // Helper function to convert total seconds back to "HH:MM:SS"
  const secondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}`;
    //:${secs}
  };

  // Calculate total planned hours for CD and MD
  const totalHours = designTasks.reduce(
    (acc, task) => {
      const seconds = timeToSeconds(task.total_planned_hours);
      if (task.code === "CD") {
        acc.CD += seconds;
      } else if (task.code === "MD") {
        acc.MD += seconds;
      }
      return acc;
    },
    { CD: 0, MD: 0 } // Initial accumulator
  );

  // Convert back to "HH:MM:SS" format
  const totalCDHours = secondsToTime(totalHours.CD);
  const totalMDHours = secondsToTime(totalHours.MD);

  const designTotalDecimalHours = calculateTotalHours(designTasks);
  const detailingTotalDecimalHours = calculateTotalHours(detailingTasks);

  const designTotalHours = formatHoursToHHMM(designTotalDecimalHours);
  const detailingTotalHours = formatHoursToHHMM(detailingTotalDecimalHours);
  // Convert "HH:MM" to total hours as a numeric value
  const convertToHours = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours + minutes / 60;
  };
  // Custom rounding logic for man-days
  const roundManDays = (value) => {
    const roundedValue = Math.floor(value); // Take the whole number part
    const fractionalPart = value - roundedValue; // Extract fractional part

    if (fractionalPart < 0.25) {
      return roundedValue; // Round down to the nearest whole number
    } else if (fractionalPart < 0.75) {
      return roundedValue + 0.5; // Round to 0.5
    } else {
      return roundedValue + 1; // Round up to the next whole number
    }
  };

  const designTotalHoursNumeric = convertToHours(designTotalHours);
  const connectionDesignTotalHoursNumeric = convertToHours(totalCDHours);
  const memberDesignTotalHoursNumeric = convertToHours(totalMDHours);
  const erectionHoursToTotalNumeric = convertToHours(
    detailingSumHours.erectionHours
  );
  const fabricationHoursToTotalNumeric = convertToHours(
    detailingSumHours.fabricationHours
  );
  const gaHoursToTotalNumeric = convertToHours(
    detailingSumHours.gaDrawingHours
  );
  const gaManDays = roundManDays(gaHoursToTotalNumeric / 8);
  const erectionManDays = roundManDays(erectionHoursToTotalNumeric / 8);
  const fabricationManDays = roundManDays(fabricationHoursToTotalNumeric / 8);
  // const designManDays = roundManDays(designTotalHoursNumeric / 8);

  const connectionDesignManDays = roundManDays(
    connectionDesignTotalHoursNumeric / 8
  );
  const memberDesignManDays = roundManDays(memberDesignTotalHoursNumeric / 8);
  const designManDays = connectionDesignManDays + memberDesignManDays;
  const detailingTotalHoursNumeric = convertToHours(detailingTotalHours);
  // const detailingManDays = roundManDays(detailingTotalHoursNumeric / 8);
  const detailingManDays = fabricationManDays + erectionManDays + gaManDays;
  const handleChangeExlusion = async (fieldToUpdate, e) => {
    const updatedExclusion = e.target.value;
    try {
      const response = await api.put(
        `/api/grouped-estimate-exclusion/${estimateId}/${phaseId}`,
        {
          fieldToUpdate,
          updatedExclusion,
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to update exclusion");
      }
    } catch (error) {
      console.error("Error updating exclusion:", error);
      alert("Failed to update exclusion. Please try again.");
    }
  };

  const getLeftValue = () => {
    let navbarShift = 0; // Default value for navbarShift

    let designShift = 0; // Default value for designShift

    // Check for screen width to determine if it's a laptop/tablet

    if (windowWidth >= 1440 && windowWidth <= 1919) {
      // Adjust for laptop/tablet screens

      // Determine shift for detailing tasks based on navbar state

      if (isNavBarOpen) {
        navbarShift = detailingTasks.length === 0 ? "0px" : "0px"; // Shift when navbar is open and no detailing tasks
      } else {
        navbarShift = detailingTasks.length === 0 ? "70px" : "0px"; // Shift when navbar is closed and no detailing tasks
      }

      // Determine shift for design tasks based on navbar state

      if (isNavBarOpen) {
        designShift = designTasks.length === 0 ? "0px" : "0px"; // Shift when navbar is open and no design tasks
      } else {
        designShift = designTasks.length === 0 ? "70px" : "0px"; // Shift when navbar is closed and no design tasks
      }
    } else {
      // Default value for larger screens (desktop)

      // Determine shift for detailing tasks based on navbar state

      if (isNavBarOpen) {
        navbarShift = detailingTasks.length === 0 ? "0px" : "0px"; // Shift when navbar is open and no detailing tasks
      } else {
        navbarShift = detailingTasks.length === 0 ? "65px" : "0px"; // Shift when navbar is closed and no detailing tasks
      }

      // Determine shift for design tasks based on navbar state

      if (isNavBarOpen) {
        designShift = designTasks.length === 0 ? "0px" : "0px"; // Shift when navbar is open and no design tasks
      } else {
        designShift = designTasks.length === 0 ? "60px" : "0px"; // Shift when navbar is closed and no design tasks
      }
    }

    if (windowWidth >= 1440 && windowWidth <= 1919) {
      // Adjust for laptop/tablet screens

      if (detailingTasks.length === 0) {
        return `-${710 + parseInt(navbarShift)}px`; // Adjust for navbar open/closed
      }

      if (designTasks.length === 0) {
        return `-${710 + parseInt(designShift)}px`; // Adjust for navbar open/closed for design tasks
      }

      return `-${57 + parseInt(navbarShift)}px`; // Apply shift for multiple tables
    } else {
      // Default value for larger screens (desktop)

      if (detailingTasks.length === 0) {
        return `-${910 + parseInt(navbarShift)}px`; // Adjust for navbar open/closed for detailing tasks
      }

      if (designTasks.length === 0) {
        return `-${916 + parseInt(designShift)}px`; // Adjust for navbar open/closed for design tasks
      }

      return `-${55 + parseInt(navbarShift)}px`; // Apply shift for multiple tables
    }
  };

  const exportToExcel = (
    projectDetails,
    designTasks,
    detailingTasks,
    designTotalHours,
    connectionDesignManDays,
    memberDesignManDays,
    designManDays,
    designExclusion,
    gaManDays,
    fabricationManDays,
    erectionManDays,
    detailingManDays,
    detailingTotalHours,
    detailingExclusion
  ) => {
    if (designTasks.length === 0 && detailingTasks.length === 0) {
      alert("No data to export!");
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const applyBorder = (row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    };

    const addSheetWithData = (
      sheetName,
      tasks,
      totalHours,
      manDaysData,
      exclusion
    ) => {
      const sheet = workbook.addWorksheet(sheetName);

      let rowIdx = 1;

      // ✅ Apply Border for Company, Estimate, Project, Date
      if (projectDetails) {
        let companyRow = sheet.addRow(["Company", "MSES Group Ltd."]);
        let estimateRow = sheet.addRow([
          "Estimate",
          projectDetails.estimationno,
        ]);
        let projectRow = sheet.addRow(["Project", projectDetails.projectname]);
        let dateRow = sheet.addRow(["Date", formattedDate]);

        [companyRow, estimateRow, projectRow, dateRow].forEach(applyBorder);

        sheet.addRow([]); // Empty row
        rowIdx += 5;
      }

      // ✅ Section Header (With Border)
      let sectionHeader = sheet.addRow([`${sheetName} Tasks`]);
      applyBorder(sectionHeader);

      let tableHeader = sheet.addRow([
        "Description",
        "Total Planned Hours",
        "Unit Price",
        "Total Price",
      ]);
      applyBorder(tableHeader);
      rowIdx += 2;

      // ✅ Group tasks by category and sum total_planned_hours
      const parseTimeToMinutes = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes; // Convert time to minutes
      };

      const formatMinutesToTime = (minutes) => {
        const hours = String(Math.floor(minutes / 60)).padStart(2, "0");
        const mins = String(minutes % 60).padStart(2, "0");
        return `${hours}:${mins}`; // Convert minutes back to HH:mm
      };

      const groupedTasks = tasks.reduce((acc, task) => {
        const existingTask = acc.find(
          (t) => t.task_category === task.task_category
        );
        if (existingTask) {
          // Add the times by converting them to minutes first
          existingTask.total_planned_hours = formatMinutesToTime(
            parseTimeToMinutes(existingTask.total_planned_hours) +
              parseTimeToMinutes(task.total_planned_hours)
          );
        } else {
          acc.push({ ...task });
        }
        return acc;
      }, []);

      // ✅ Sort and add grouped tasks
      groupedTasks
        .sort((a, b) => a.task_category.localeCompare(b.task_category))
        .forEach((task) => {
          let row = sheet.addRow([
            task.task_category,
            task.total_planned_hours === "00:00"
              ? ""
              : task.total_planned_hours,
            "",
            "",
          ]);
          applyBorder(row);
          rowIdx++;
        });

      // ✅ Total Hours Row (With Border and Yellow Background)
      let totalRow = sheet.addRow(["Total", totalHours]);
      applyBorder(totalRow);
      totalRow.eachCell((cell) => {
        if (cell.row === totalRow.rowNumber) {
          cell.style.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          }; // Yellow color
        }
      });
      rowIdx++;

      sheet.addRow([]); // Empty row
      rowIdx++;

      // ✅ Man Days Table (Apply Borders to All Rows)
      let durationHeader = sheet.addRow(["Duration in Days", null, null]);
      applyBorder(durationHeader);

      Object.entries(manDaysData).forEach(([key, value]) => {
        let row = sheet.addRow([key, value === 0 ? "" : value]);
        applyBorder(row);
        rowIdx++;
      });

      sheet.addRow([]); // Empty row
      rowIdx++;

      // ✅ Exclusion Row (With Border)
      if (exclusion) {
        let exclusionRow = sheet.addRow(["Exclusions", exclusion]);
        applyBorder(exclusionRow);
        sheet.addRow([]); // Empty row
      }
    };

    // ✅ Create Design and Detailing Sheets
    if (designTasks.length > 0) {
      addSheetWithData(
        "Design",
        designTasks,
        designTotalHours,
        {
          "Connection Design": connectionDesignManDays,
          "Member Design": memberDesignManDays,
          Total: designManDays,
        },
        designExclusion
      );
    }

    if (detailingTasks.length > 0) {
      addSheetWithData(
        "Detailing",
        detailingTasks,
        detailingTotalHours,
        {
          "GA Drawing": gaManDays,
          "Fabrication Drawing": fabricationManDays,
          "Erection Drawing": erectionManDays,
          Total: detailingManDays,
        },
        detailingExclusion
      );
    }

    // ✅ Save Workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `Estimate-${projectDetails.estimationno}.xlsx`);
    });
  };

  return (
    <>
      <ToastContainer />
      {(designTasks.length > 0 || detailingTasks.length > 0) && (
        <button
          className={isNavBarOpen ? "est-export-btn" : "est-export-btn-close"}
          onClick={() =>
            exportToExcel(
              projectDetails,
              designTasks,
              detailingTasks,
              designTotalHours,
              connectionDesignManDays,
              memberDesignManDays,
              designManDays,
              designExclusion,
              gaManDays,
              fabricationManDays,
              erectionManDays,
              detailingManDays,
              detailingTotalHours,
              detailingExclusion
            )
          }
        >
          Export to Excel
        </button>
      )}
      <div
        className={
          isNavBarOpen
            ? "estimate-details-container"
            : "estimate-details-container-close"
        }
      >
        {designTasks.length > 0 && (
          <table
            className="estimate-details-table"
            style={{ left: getLeftValue() }}
          >
            <thead>
              <tr>
                <th colSpan={4}>
                  Design Tasks{" "}
                  {designTasks.length > 0 && (
                    <>
                      <button
                        className="add-btn"
                        onClick={() => {
                          design(true);
                          setDesignNo(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="add-btn"
                        onClick={() => designRevise(true)}
                      >
                        Revise
                      </button>
                      {submitValue.Design === "No" && (
                        <button
                          className="add-btn"
                          onClick={() => setDesignSave(true)}
                          style={{
                            backgroundColor:
                              submitValue.Design === "Yes" ? "Grey" : "#6200ee",
                          }}
                          disabled={submitValue.Design === "Yes"}
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </th>
              </tr>
              <tr>
                <th>Description</th>
                <th>Total Planned Hours</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {designTasks.length > 0 ? (
                <>
                  <tr className="group-header">
                    <td
                      colSpan="4"
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Member Design Tasks
                    </td>
                  </tr>
                  {designTasks
                    .filter((task) => task.code === "MD")
                    .sort((a, b) =>
                      a.task_category.localeCompare(b.task_category)
                    )
                    .map((task, index) => (
                      <tr key={index}>
                        <td style={{ whiteSpace: "wrap" }}>
                          {task.task_category}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {task.total_planned_hours === "00:00"
                            ? ""
                            : task.total_planned_hours || ""}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                  <tr>
                    <td>Total</td>
                    <td style={{ textAlign: "center" }}>
                      {totalMDHours !== "00:00" && totalMDHours}
                    </td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr className="group-header">
                    <td
                      colSpan="4"
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Connection Design Tasks
                    </td>
                  </tr>
                  {designTasks
                    .filter((task) => task.code === "CD")
                    .sort((a, b) =>
                      a.task_category.localeCompare(b.task_category)
                    )
                    .map((task, index) => (
                      <tr key={index}>
                        <td style={{ whiteSpace: "wrap" }}>
                          {task.task_category}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {task.total_planned_hours === "00:00"
                            ? ""
                            : task.total_planned_hours || ""}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                  <tr>
                    <td>Total</td>
                    <td style={{ textAlign: "center" }}>
                      {totalCDHours !== "00:00" && totalCDHours}
                    </td>
                  </tr>
                  <tr>
                    <td>Grant Total</td>
                    <td style={{ textAlign: "center" }}>
                      {designTotalHours === "00:00"
                        ? ""
                        : `${designTotalHours} `}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>

                  <tr style={{ border: "none" }}>
                    <td style={{ border: "none" }}></td>
                  </tr>

                  <tr style={{ border: "none" }}>
                    <td
                      colSpan={"2"}
                      style={{
                        textAlign: "center",
                        backgroundColor: "#f0f0f0",
                        fontWeight: "bold",
                      }}
                    >
                      Duration in Days
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ whiteSpace: "normal" }}>Connection Design</td>
                    <td style={{ textAlign: "center" }}>
                      {connectionDesignManDays === 0
                        ? ""
                        : connectionDesignManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ whiteSpace: "normal" }}>Member Design</td>
                    <td style={{ textAlign: "center" }}>
                      {memberDesignManDays === 0 ? "" : memberDesignManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Total </td>
                    <td style={{ textAlign: "center" }}>
                      {designManDays === 0 ? "" : designManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr>
                    <td>Exclusion</td>
                    <td
                      style={{
                        textAlign: "center",
                        width: "650px",
                        padding: "0px",
                      }}
                    >
                      <textarea
                        onChange={(e) => setDesignExclusion(e.target.value)}
                        onBlur={(e) => handleChangeExlusion("Design", e)}
                        value={designExclusion}
                        className={
                          designTasks.length > 0 && detailingTasks.length === 0
                            ? "est-exclusion"
                            : "est-exclusion-close"
                        }
                        maxLength={250}
                      />
                    </td>
                    <td colSpan="2" style={{ border: "0.5px solid white" }}>
                      {revision.tasks && revision.tasks.Design?.length > 0 && (
                        <Link
                          className="revision-para"
                          to={`/estimate/revision/design/${estimateId}/${phaseId}`}
                        >
                          {revision.tasks.Design.length} Revision available
                        </Link>
                      )}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {detailingTasks.length > 0 && (
          <table
            className={"estimate-details-table"}
            style={{ left: getLeftValue() }}
          >
            <thead>
              <tr>
                <th colSpan={4}>
                  Detailing Tasks
                  {detailingTasks.length > 0 && (
                    <>
                      <button
                        className="add-btn"
                        onClick={() => {
                          detailing(true);
                          setDetailingNo(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="add-btn"
                        onClick={() => detailingRevise(true)}
                      >
                        Revise
                      </button>
                      {submitValue.Detailing === "No" && (
                        <button
                          className="add-btn"
                          style={{
                            backgroundColor:
                              submitValue.Detailing === "Yes"
                                ? "Grey"
                                : "#6200ee",
                          }}
                          disabled={submitValue.Detailing === "Yes"}
                          onClick={() => setDetailingSave(true)}
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </th>
              </tr>
              <tr>
                <th>Description</th>
                <th>Total Planned Hours</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {detailingTasks.length > 0 ? (
                <>
                  <tr className="group-header">
                    <td
                      colSpan="4"
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Steel Detailing Tasks
                    </td>
                  </tr>
                  {detailingTasks
                    .sort((a, b) =>
                      a.task_category.localeCompare(b.task_category)
                    )
                    .map((task, index) => (
                      <tr key={index}>
                        <td style={{ whiteSpace: "wrap" }}>
                          {task.task_category}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {task.total_planned_hours === "00:00"
                            ? ""
                            : task.total_planned_hours || ""}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                  {/* Add the total rows at the end */}
                  <tr>
                    <td>Total</td>
                    <td style={{ textAlign: "center" }}>
                      {detailingTotalHours === "00:00"
                        ? ""
                        : `${detailingTotalHours} `}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td
                      colSpan={"2"}
                      style={{
                        textAlign: "center",
                        backgroundColor: "#f0f0f0",
                        fontWeight: "bold",
                      }}
                    >
                      Duration in Days
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ whiteSpace: "normal" }}>
                      GA drawing for approval
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {gaManDays === 0 ? "" : gaManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Fabrication drawing</td>
                    <td style={{ textAlign: "center" }}>
                      {fabricationManDays === 0 ? "" : fabricationManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Erection drawing</td>
                    <td style={{ textAlign: "center" }}>
                      {erectionManDays === 0 ? "" : erectionManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Total Man Days</td>
                    <td style={{ textAlign: "center" }}>
                      {detailingManDays === 0 ? "" : detailingManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr>
                    <td>Exclusion</td>
                    <td
                      style={{
                        textAlign: "center",
                        width: "650px",
                        padding: "0px",
                        height: "38px",
                      }}
                    >
                      <textarea
                        onChange={(e) => setDetailingExclusion(e.target.value)}
                        onBlur={(e) => handleChangeExlusion("Detailing", e)}
                        value={detailingExclusion}
                        className={
                          designTasks.length === 0 && detailingTasks.length > 0
                            ? "est-exclusion"
                            : "est-exclusion-close"
                        }
                        maxLength={250}
                      />
                    </td>
                    <td colSpan="2" style={{ border: "0.5px solid white" }}>
                      {revision.tasks &&
                        revision.tasks.Detailing?.length > 0 && (
                          <Link
                            style={{ marginTop: "30px" }}
                            className="revision-para"
                            to={`/estimate/revision/detailing/${estimateId}/${phaseId}`}
                          >
                            {revision.tasks.Detailing.length} Revision available
                          </Link>
                        )}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default EstimateTaskTable;
