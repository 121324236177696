// import { useState } from "react";
// import "./Report.css";

// const Report = () => {
//   const [activeLink, setActiveLink] = useState(() => {
//     return localStorage.getItem("activeLink") || "";
//   });

//   const resetProjectSelection = (link) => {
//     console.log("link", link);
//     setActiveLink(link);
//     localStorage.setItem("activeLink", link);
//   };

//   return (
//     <div className="report-main-container">
//       <div>
//         <h3
//           className={`select-project ${
//             activeLink === "timesheet" ? "active" : ""
//           }`}
//           onClick={() => resetProjectSelection("timesheet")}
//         >
//           Timesheet Report
//         </h3>
//         <h3
//           className={`select-project ${
//             activeLink === "project" ? "active" : ""
//           }`}
//           onClick={() => resetProjectSelection("project")}
//         >
//           Project Report
//         </h3>
//         <h3
//           className={`select-project ${
//             activeLink === "estimate" ? "active" : ""
//           }`}
//           onClick={() => resetProjectSelection("estimate")}
//         >
//           Estimate Report
//         </h3>
//       </div>
//       <div>
//         <h3>Report create by</h3>
//         <select>
//           <option>Select Option</option>
//           <option>Month</option>
//           <option>Year</option>
//         </select>
//       </div>
//     </div>
//   );
// };

// export default Report;

// import React, { useState } from "react";
// import axios from "axios";

// function GrammarChecker() {
//   const [text, setText] = useState("");
//   const [correctedText, setCorrectedText] = useState("");
//   const [suggestions, setSuggestions] = useState([]);

//   const checkGrammar = async () => {
//     try {
//       const response = await axios.post(
//         "https://api.languagetool.org/v2/check",
//         new URLSearchParams({ text, language: "en-US" })
//       );
//       const matches = response.data.matches;
//       if (matches.length > 0) {
//         let updatedText = text;
//         matches.reverse().forEach((item) => {
//           if (item.replacements.length > 0) {
//             const correction = item.replacements[0].value; // Use the first suggested correction
//             updatedText =
//               updatedText.substring(0, item.offset) +
//               correction +
//               updatedText.substring(item.offset + item.length);
//           }
//         });
//         setCorrectedText(updatedText);
//         setSuggestions(matches);
//       } else {
//         setCorrectedText(text); // No errors, keep text the same
//       }
//     } catch (error) {
//       console.error("Error checking grammar:", error);
//     }
//   };

//   return (
//     <div style={{ position: "relative", top: "70px", left: "180px" }}>
//       <h2>Grammar & Sentence Rewrite Tool</h2>
//       <textarea
//         value={text}
//         onChange={(e) => setText(e.target.value)}
//         placeholder="Enter your text..."
//         rows="5"
//         cols="50"
//       />
//       <button onClick={checkGrammar}>Check & Rewrite</button>

//       {correctedText && (
//         <div>
//           <h3>Corrected Text:</h3>
//           <textarea value={correctedText} rows="5" cols="50" readOnly />
//         </div>
//       )}

//       <div>
//         {suggestions.length > 0 ? (
//           <ul>
//             {suggestions.map((item, index) => (
//               <li key={index}>
//                 <strong>Error:</strong> {item.message} <br />
//                 <strong>Suggestion:</strong>{" "}
//                 {item.replacements.length > 0
//                   ? item.replacements.map((r) => r.value).join(", ")
//                   : "No suggestion"}
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <p>No errors found!</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default GrammarChecker;

import React, { useState, useEffect } from "react";
import api from "../../api/axiosConfig";
import { MultiSelect } from "react-multi-select-component";

function ToDoList() {
  const [createToDoDial, setCreateToDoDial] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectUsers, setProjectUsers] = useState([]);
  const [projectCustomers, setProjectCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assignedTo, setAssignedTo] = useState([]);
  const [reportedTo, setReportedTo] = useState([]);

  // Fetch Active Projects
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/api/projects1`);
        const activeProjects = res.data.filter(
          (project) => project.activestatus === 1
        );
        setProjects(activeProjects);
      } catch (error) {
        setError(error);
        console.error("Error fetching project data:", error.message || error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch Users when a Project is Selected
  useEffect(() => {
    const fetchUsers = async () => {
      if (selectedProject) {
        try {
          setLoading(true);
          const userRes = await api.get(
            `/api/projects/${selectedProject.id}/users`
          );
          const customerRes = await api.get(
            `/api/customers/${selectedProject.customer_id}/contacts`
          );
          setProjectUsers(userRes.data); // Ensure correct data extraction
          setProjectCustomers(customerRes.data.contacts);
          console.log("customerRes.data", customerRes.data.contacts);
        } catch (error) {
          setError(error);
          console.error("Error fetching users:", error.message || error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUsers();
  }, [selectedProject]);

  // Handle Project Selection
  const handleProjectChange = (e) => {
    const projectId = parseInt(e.target.value, 10);
    const project = projects.find((p) => p.id === projectId) || null;
    setSelectedProject(project);
    console.log("selected project", project);
  };

  return (
    <div style={{ position: "relative", left: "90px", top: "41px" }}>
      <h3>Todo List</h3>
      <button onClick={() => setCreateToDoDial(true)}>Add Todo List</button>
      {createToDoDial && (
        <div>
          <h3>Project</h3>
          <select onChange={handleProjectChange}>
            <option value="">Select Project</option>
            {projects.map((proj) => (
              <option key={proj.id} value={proj.id}>
                {`${proj.projectno} - ${proj.name}`}
              </option>
            ))}
          </select>

          <h3>Assigned to</h3>
          <MultiSelect
            options={
              Array.isArray(projectUsers)
                ? projectUsers.map((user) => ({
                    label: user.name || "Unnamed", // Handle missing names
                    value: user.id,
                  }))
                : []
            }
            value={assignedTo
              .map((id) => {
                // Map IDs in `assignedTo` back to the format MultiSelect expects
                const user = projectUsers.find((u) => u.id === id);
                return user ? { label: user.name, value: user.id } : null;
              })
              .filter(Boolean)} // Remove any `null` values
            onChange={
              (selected) => setAssignedTo(selected.map((item) => item.value)) // Update `assignedTo` with selected IDs
            }
            labelledBy="Select Users"
            className="task-assigned-user"
          />
          <h3>Report to</h3>
          <MultiSelect
            options={
              Array.isArray(projectCustomers)
                ? projectCustomers.map((user) => ({
                    label: user.contact_name || "Unnamed", // Handle missing names
                    value: user.contact_id,
                  }))
                : []
            }
            value={reportedTo
              .map((contact_id) => {
                // Map IDs in `assignedTo` back to the format MultiSelect expects
                const user = projectCustomers.find(
                  (u) => u.contact_id === contact_id
                );
                return user
                  ? { label: user.contact_name, value: user.contact_id }
                  : null;
              })
              .filter(Boolean)} // Remove any `null` values
            onChange={
              (selected) => setReportedTo(selected.map((item) => item.value)) // Update `assignedTo` with selected IDs
            }
            labelledBy="Select Users"
            className="task-assigned-user"
          />
          <h3>Add Comment</h3>
          <textarea />
          <button>Add </button>
          <button
            onClick={() => {
              setCreateToDoDial(false);
              setAssignedTo([]);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default ToDoList;
